import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "../../../widgets/CalendarWidget/CalendarWidget.css";
import BookForm from "../../form/BookForm";
import MultiSelectDropdown from "../../../components/MultiSelect";
import { useParams } from "react-router-dom";
import { hours, token } from "../../../utils/utilityFunctions";
import { db } from "../../../widgets/firebase";
import { addDoc, collection } from "firebase/firestore";
import { Tooltip, colors, styled, tooltipClasses } from "@mui/material";
import { toast } from "react-toastify";
import Asterisk from "../../../utils/Asterisk";
import SingleSelectDropdown from "../../../components/SingleSelect";
import LoaderSpinner from "../../../utils/shimmer/LoaderSpinner";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CoachCalendarWidget = ({
  selectedMonth,
  setSelectedMonth,
  calendarView,
  setCalendarView,
  coachName,
  setSelectedBooking,
  getAllEvents,
  eventss,
  endPoint,
  setEndPoint,
  unavailableDates,
  dateCellWrapper,
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = collection(db, "userrequest");
  const newRef = collection(db, "coachuserreq");

  const { id } = useParams();

  // const isDateUnavailable = (date) => {
  //   console.log("inside",unavailableDates)
  //   return unavailableDates.some((unavailable) => {
  //     const start = moment(unavailable.startDateTime);
  //     const end = moment(unavailable.endDateTime);
  //     return date.isBetween(start, end, null, "[]"); // Check if date is in the range
  //   });
  // };

  // const dateCellWrapper = ({ date, children }) => {
  //   console.log("entered");
  //   const isUnavailable = isDateUnavailable(moment(date));
  //   console.log("isUnavailable", isUnavailable);
  //   return (
  //     <div
  //       style={{
  //         backgroundColor: isUnavailable ? "gray" : "",
  //         cursor: isUnavailable ? "not-allowed" : "pointer",
  //       }}
  //     >
  //       {/* {children} */}
  //       <p>Hello</p>
  //     </div>
  //   );
  // };

  const updateSkills = (e) => {
    // setSelectedSkills(e.value);
    const {
      target: { value },
    } = e;
    setSelectedSkills(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleViewChange = (view) => {
    if (view === Views.MONTH || view === "month") {
      const startDate = moment().startOf("month").format("YYYY-MM-DD");
      const endDate = moment()
        .startOf("month")
        .add(1, "month")
        .format("YYYY-MM-DD");
      setSelectedMonth({ startDate, endDate });
      //iso
      const startISODate = moment(startDate).startOf("day").utc().toISOString();
      const endISODate = moment(endDate).endOf("day").utc().toISOString();
      const endPoint = `startDateTime=${startISODate}&endDateTime=${endISODate}`;
      setEndPoint(endPoint);
      setCalendarView("Monthly");
    } else if (view === Views.WEEK || view === "week") {
      const startDate = moment().startOf("week").format("YYYY-MM-DD"); // Start of the current week (Sunday)
      const endDate = moment().endOf("week").format("YYYY-MM-DD"); // End of the current week (Saturday)
      setSelectedMonth({ startDate, endDate });
      //iso
      const startISODate = moment(startDate).startOf("day").utc().toISOString();
      const endISODate = moment(endDate).endOf("day").utc().toISOString();
      const endPoint = `startDateTime=${startISODate}&endDateTime=${endISODate}`;
      setEndPoint(endPoint);
      setCalendarView("Weekly");
    } else if (view === Views.DAY || view === "day") {
      const startDate = moment().startOf("day").format("YYYY-MM-DD"); // Start of today
      const endDate = moment().startOf("day").format("YYYY-MM-DD"); // Start of today (same as start)
      setSelectedMonth({ startDate, endDate });
      //iso
      const startISODate = moment(startDate).startOf("day").utc().toISOString();
      const endISODate = moment(endDate).endOf("day").utc().toISOString();
      const endPoint = `startDateTime=${startISODate}&endDateTime=${endISODate}`;
      setEndPoint(endPoint);
      setCalendarView("Day");
    }
  };

  const handleNavigate = (date, view) => {
    if (view === Views.MONTH || view === "month") {
      const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
      const endDate = moment(date)
        .startOf("month")
        .add(1, "month")
        .format("YYYY-MM-DD");
      setSelectedMonth({ startDate, endDate });
      const startISODate = moment(startDate).startOf("day").utc().toISOString();
      const endISODate = moment(endDate).endOf("day").utc().toISOString();
      const endPoint = `startDateTime=${startISODate}&endDateTime=${endISODate}`;
      setEndPoint(endPoint);
    } else if (view === Views.WEEK || view === "week") {
      const startDate = moment(date).startOf("week").format("YYYY-MM-DD"); // Start of the week (Sunday)
      const endDate = moment(date).endOf("week").format("YYYY-MM-DD"); // End of the week (Saturday)
      setSelectedMonth({ startDate, endDate });
      const startISODate = moment(startDate).startOf("day").utc().toISOString();
      const endISODate = moment(endDate).endOf("day").utc().toISOString();
      const endPoint = `startDateTime=${startISODate}&endDateTime=${endISODate}`;
      setEndPoint(endPoint);
    } else if (view === Views.DAY || view === "day") {
      const startDate = moment(date).startOf("day").format("YYYY-MM-DD"); // Start of the provided date
      const endDate = moment(date).startOf("day").format("YYYY-MM-DD"); // Start of the provided date (same as start)
      setSelectedMonth({ startDate, endDate });
      const startISODate = moment(startDate).startOf("day").utc().toISOString();
      const endISODate = moment(endDate).endOf("day").utc().toISOString();
      const endPoint = `startDateTime=${startISODate}&endDateTime=${endISODate}`;
      setEndPoint(endPoint);
    }
  };

  console.log("selectedMonth", selectedMonth);
  console.log("selectedMonthEndPoint", endPoint);

  const getFileUrl = `${window.env_url}/v1/files/`;

  const profileImage = sessionStorage.getItem("profPicture");
  let imgPath;
  if (profileImage?.includes("avataaars.io")) {
    imgPath = profileImage;
  } else {
    imgPath = getFileUrl + profileImage;
  }

  console.log(selectedMonth);

  useEffect(() => {
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    setSelectedMonth({ startDate, endDate });
    //ISO
    const startISODate = moment(startDate).startOf("day").utc().toISOString();
    const endISODate = moment(endDate).endOf("day").utc().toISOString();
    const endPoint = `startDateTime=${startISODate}&endDateTime=${endISODate}`;
    setEndPoint(endPoint);
  }, []);

  const currentUserId = sessionStorage.getItem("userId");
  const numUserId = Number(currentUserId);
  console.log("eventsss", eventss);

  const formattedEvents = eventss
    ?.filter(
      (event) =>
        event?.visibility === "accepted" || event?.visibility === "pending"
    )
    .map((event) => {
      console.log("event", event);
      const startDateTime = moment(
        `${event.checkInDate} ${event.checkInTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      const endDateTime = moment(
        `${event.checkOutDate} ${event.checkOutTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      return {
        id: event.id,
        title: "", // You can customize this if you have specific titles
        start: startDateTime.toDate(), // Format the start datetime
        end: endDateTime.toDate(), // Format the end datetime
        skills: event.skills, // Assuming event.facilities is an array of facilities
        notes: event.notes,
        visibility: event?.visibility,
        isRescheduled: event?.isRescheduled,
        userId: event?.userId,
      };
    });
  console.log("formattedEvents", formattedEvents); //{}

  console.log("unavailabilites", unavailableDates);
  const formattedUnavailableDates = unavailableDates.map((dateRange) => ({
    title: "Unavailable",
    start: moment(dateRange.startDateTime).toDate(),
    end: moment(dateRange.endDateTime).toDate(),
    isUnavailable: true, // Add a flag to differentiate unavailable dates
  }));
  console.log("formattedEvents", formattedEvents);
  console.log("formattedUnavailableDates", formattedUnavailableDates);

  const combinedEvents = [...formattedEvents, ...formattedUnavailableDates];
  // console.log

  useEffect(() => {
    fetchSkills();
    getAllEvents();
  }, []);

  const fetchSkills = async () => {
    const apiUrl = `${window.env_url}/v1/filterskills`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setSkillsData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  const skills = skillsData.map((data, index) => {
    return {
      name: data.title,
      code: data.id,
    };
  });

  const [events, setEvents] = useState([
    {
      title: "Event 1",
      start: new Date(),
      allDay: true,
      end: new Date(2024, 2, 1),
    },
  ]);

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const onEventResize = (data) => {
    const { start, end } = data;
    setEvents([{ ...events[0], start, end }]);
  };
  const onEventDrop = (data) => {
    //console.log(data);
  };

  const today = new Date().toISOString().split("T")[0];

  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    skills: "",
    duration: "",
    notes: "",
  });

  const [allEvents, setAllEvents] = useState(events);

  const handleAddEvent = () => {
    const startTimeMoment = moment(newEvent.startTime, "HH:mm:ss");
    const endTimeMoment = startTimeMoment
      .clone()
      .add(newEvent.duration, "hours");

    const startDate = moment(newEvent.start, "YYYY-MM-DD");
    const checkOutDateTime = moment(
      `${newEvent.start} ${newEvent.startTime}`,
      "YYYY-MM-DD HH:mm:ss"
    ).add(newEvent.duration, "hours");

    const coachEvent = {
      checkInDate: startDate.format("YYYY-MM-DD"),
      checkOutDate: checkOutDateTime.format("YYYY-MM-DD"),
      checkInTime: startTimeMoment.format("HH:mm:ss"),
      checkOutTime: endTimeMoment.format("HH:mm:ss"),
      skills: selectedSkills,
      duration: newEvent.duration,
      notes: newEvent.notes,
    };

    setAllEvents((prevEvents) => [...prevEvents, coachEvent]);

    const postCoachBooking = async () => {
      const token = localStorage.getItem("accessToken");

      try {
        setIsLoading(true);
        const response = await fetch(
          `${window.env_url}/v1/coachbookings/book/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(coachEvent),
          }
        );

        const data = await response.json();

        if (response.ok) {
          toast.success("Coach Booking request made");
        } else if (data.status === 500) {
          toast.error("Invalid details provided");
        } else {
          toast.error(data?.message || "An error occurred");
        }

        // Prepare and send data to Firebase
        const dataToSend = {
          username: sessionStorage.getItem("fullName"),
          message: "Booked a Coach",
          duration: newEvent.duration,
          profileImage: imgPath,
          createdAt: Date.now(),
        };
        const dataToSendForCoach = {
          username: sessionStorage.getItem("fullName"),
          id: id,
          message: "has requested to book you",
          duration: newEvent.duration,
          profileImage: imgPath,
          createdAt: Date.now(),
        };

        try {
          await addDoc(ref, dataToSend);
          await addDoc(newRef, dataToSendForCoach);
          console.log("Data sent to Firebase successfully:", dataToSend);
          console.log(
            "Coach Data sent to Firebase successfully:",
            dataToSendForCoach
          );
        } catch (firebaseError) {
          console.error("Error adding data to Firebase:", firebaseError);
        }
        getAllEvents();
      } catch (error) {
        console.error("Error posting coach booking:", error.message);
        toast.error("Failed to book coach. Please try again.");
      } finally {
        setIsLoading(false);
        // Reset form fields
        setNewEvent({
          title: "",
          start: "",
          end: "",
          startTime: "",
          endTime: "",
          skills: "",
          duration: "",
          notes: "",
        });
        setSelectedSkills([]);
      }
    };

    postCoachBooking();
  };

  const EventComponent = ({ event }) => {
    let backgroundColor;
    let textColor = "white";

    if (event?.isUnavailable) {
      backgroundColor = "gray";
      textColor = "white";
    } else {
      switch (event?.visibility) {
        case "accepted":
          backgroundColor = "#265985";
          textColor = "white";
          break;
        case "pending":
          backgroundColor = "yellow";
          textColor = "black";
          break;
        case "rejected":
          backgroundColor = "red";
          textColor = "white";
          break;
        default:
          backgroundColor = "black"; // Default color if visibility is not specified or unknown
          break;
      }
    }

    const eventStyle = {
      backgroundColor: backgroundColor,
      color: textColor,
      textAlign: "center",
      borderRadius: "4px",
      padding: "2px",
    };

    //tool tip from material ui
    const tooltipContent = event?.isUnavailable
      ? "This date is unavailable."
      : `event
       Skills: ${event?.skills?.join(", ") || "No skills available"}, 
       Notes: ${event?.notes || "No notes"}
      `;

    return (
      <div style={eventStyle}>
        <Tooltip title={tooltipContent}>
          <div>{coachName}</div>
          {event?.visibility && (
            <div style={{ textTransform: "capitalize" }}>
              Status : {event?.visibility}
            </div>
          )}
          <div style={eventStyle}>
            {event?.isUnavailable ? "Unavailable" : event?.title}
          </div>
        </Tooltip>
      </div>
    );
  };
  const calculateDuration = () => {
    if (newEvent.startTime && newEvent.endTime) {
      const start = moment(`2024-01-01T${newEvent.startTime}`);
      const end = moment(`2024-01-01T${newEvent.endTime}`);
      const durationMinutes = end.diff(start, "minutes");
      const durationUnits = durationMinutes / 15; // 15 minutes is 1 unit
      setNewEvent({ ...newEvent, duration: durationUnits });
    }
  };

  return (
    <>
      <>
        <div className="tl-container">
          <div className="cal-wrapper" style={{ height: "auto" }}>
            <Calendar
              defaultDate={moment().toDate()}
              defaultView="month"
              events={combinedEvents}
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              onEventDrop={onEventDrop}
              onEventResize={onEventResize}
              onView={(view) => handleViewChange(view)}
              onNavigate={(date, view) => handleNavigate(date, view)}
              resizable
              onSelectEvent={(event) => {
                if (event?.isUnavailable) {
                  return;
                }
                setSelectedBooking(event);
              }}
              showMultiDayTimes
              views={["month", "week", "day"]}
              style={{ height: "100vh" }}
              dateCellWrapper={dateCellWrapper}
              components={{
                event: EventComponent,
                agenda: { event: EventComponent },
                week: { event: EventComponent },
                day: { event: EventComponent },
              }}
            />
          </div>
        </div>

        {width >= 1024 && (
          <div className="bmrf" style={{ fontFamily: "Nunito" }}>
            {isLoading && (
              <div className="spinner-overlay">
                <LoaderSpinner />
              </div>
            )}
            <div className="bmrf-heading">
              <p>MAKE A COACH BOOKING</p>
              <span
                style={{
                  fontFamily: "Nunito",
                  fontStyle: "italic",
                  display: "block",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "1%",
                }}
              >
                Fields with asterisk (*) are required
              </span>
            </div>
            <div className="bmrf-form">
              <div className="row1">
                <div>
                  <label className="makebold" htmlFor="">
                    Date
                    <Asterisk />
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    value={newEvent.start}
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, start: event.target.value })
                    }
                    min={today}
                  />
                </div>
              </div>
              <div className="row2">
                <div>
                  <label className="makebold" htmlFor="">
                    Time
                    <Asterisk />
                  </label>
                  <input
                    type="time"
                    name="startTime"
                    value={newEvent.startTime}
                    onChange={(event) => {
                      setNewEvent({
                        ...newEvent,
                        startTime: event.target.value,
                      });
                      calculateDuration();
                    }}
                  />
                </div>
              </div>
              <div className="row3" style={{ border: "none" }}>
                <div>
                  <label className="makebold" htmlFor="">
                    Duration
                    <Asterisk />
                  </label>
                  {/* <input
                    type="number"
                    name="duration"
                    value={newEvent.duration}
                    placeholder="Duration"
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, duration: event.target.value })
                    }
                  /> */}
                  <SingleSelectDropdown
                    options={hours}
                    value={newEvent.duration}
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, duration: event.target.value })
                    }
                    label="Duration"
                    isMandatory={true}
                  />
                </div>
              </div>
              <div className="row4">
                <MultiSelectDropdown
                  value={selectedSkills}
                  onChangeEvent={updateSkills}
                  options={skills}
                  isMandatory={true}
                  placeholder="Select Skills"
                />
              </div>
              <div className="row5">
                <div className="row5" style={{ display: "block" }}>
                  <label className="makebold" htmlFor="">
                    Notes
                    <Asterisk />
                  </label>
                  <textarea
                    type="text"
                    name="notes"
                    style={{ marginTop: "6px" }}
                    value={newEvent.notes}
                    placeholder="Description"
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, notes: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-sbt-btn" onClick={handleAddEvent}>
                <button>Submit</button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default CoachCalendarWidget;

//events prop must be supplied an object containing start and end params so that it can be mapped to the particular
//day, time and then the title for whatever you want to display there in the calendar

//CONTROLLING THE CALENDAR
// calendar can be controlled by passing necessary props to it (eg. views,defaultView,max, min )
