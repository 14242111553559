import { Box, Modal } from "@mui/material";
import StarIconn from "../widgets/StarIconn";
import { useEffect, useRef, useState } from "react";
import "./EditReviewDialog.css";

const EditReviewDialog = ({
  open,
  close,
  review,
  onReviewUpdated,
  activeReview,
  isBookingRow,
}) => {
  const handleClose = () => {
    open = false; // Replace 'setOpen' with your state setter function
    setRating(null); // Reset the rating when the dialog is closed
    setHover(null);
  };

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  console.log(activeReview);

  console.log("Rev d", review);

  const getFileUrl = `${window.env_url}/v1/files`;

  const dialogRef = useRef(null);

  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [description, setDescription] = useState("");
  const [reviewRatings, setReviewRatings] = useState({
    prevRating: 0,
    currentRating: 0,
  });
  const [reviewData, setReviewData] = useState({
    rating: "",
    description: "",
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        close(); // Call handleClose to close the dialog
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);


  console.log("editReview", review)

  useEffect(() => {
    if (review) {
      setReviewData({
        rating: review.rating,
        description: review.description,
      });
      setReviewRatings({
        currentRating: review?.rating,
        prevRating: review?.rating,
      });
    }
  }, [review]);

  //console.log(review);
  const desktopStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    width: "48rem",
    height: "max-content",
    padding: "2rem 0rem",
    borderStyle: "none",
  };

  const mobileStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "0px",
    width: "23rem",
    height: "max-content",
    borderStyle: "none",
  };

  const tabletStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "0px",
    width: "34rem",
    height: "37rem",
    borderStyle: "none",
  };

  let appliedStyle;
  if (width <= 768) {
    appliedStyle = mobileStyle;
  } else if (width > 768 && width < 1024) {
    appliedStyle = tabletStyle;
  } else {
    appliedStyle = desktopStyle;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReviewData({ ...reviewData, [name]: value });
  };

  //console.log(reviewRatings);

  const hoverHandler = (index) => {
    setReviewRatings({
      prevRating: reviewRatings?.currentRating,
      currentRating: index + 1,
    });
  };

  console.log(activeReview);

  const updateReview = async (reviewId) => {
    let apiUrl = `${window.env_url}/v1/coachreviews/${reviewId}`;
    const token = localStorage.getItem("accessToken");
    const reqBody = {
      ...reviewData,
      rating: reviewRatings.currentRating,
    };
    try {
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });
      if (response.ok) {
        //console.log(response);
        onReviewUpdated();
        close();
      }
    } catch (error) {
      console.error(error.message);
    }
  };


  return (
    <>
      <div>
        <Modal
          open={true}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="edit_review_dialog"
        >
          <Box sx={appliedStyle} ref={dialogRef}>
            <div id="review-container">
              <div className="edit-dialog review-header">
                <div className="login-dialog-heading">
                  {isBookingRow
                    ? "Customer's coach Review"
                    : "Write a coach review"}
                </div>
              </div>
              <span className="rating-text">Rating</span>
              <span className="rating-value">
                {reviewRatings?.currentRating}.0
              </span>
              <div className="star-container">
                {[...Array(5)].map((star, index) => {
                  return (
                    <label>
                      <input
                        type="radio"
                        name="rating"
                        value={reviewData?.rating}
                        //onChange={handleInputChange}
                        onClick={() => {
                          setReviewRatings({
                            prevRating: reviewRatings?.currentRating,
                            currentRating: index + 1,
                          });
                        }}
                      />

                      <StarIconn
                        className="star"
                        color={
                          index + 1 <= (reviewRatings?.currentRating || 0)
                            ? "#ffc107"
                            : "#99999"
                        }
                        onMouseEnter={
                          !isBookingRow ? () => hoverHandler(index) : undefined
                        }
                        onMouseLeave={
                          !isBookingRow
                            ? () =>
                                setReviewRatings({
                                  currentRating: reviewRatings?.prevRating,
                                })
                            : undefined
                        }
                      />
                    </label>
                  );
                })}
              </div>
              <div className="textarea-container">
                <textarea
                  value={reviewData?.description}
                  placeholder="Share details of your experience...."
                  name="description"
                  onChange={handleInputChange}
                  readOnly={isBookingRow}
                />
              </div>
              {!isBookingRow && (
                <div
                  className="submit-btn-container"
                  onClick={() => updateReview(review?.id)}
                >
                  <button className="submit-review-btn">Update</button>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default EditReviewDialog;
