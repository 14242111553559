import React, { useEffect, useRef, useState } from "react";
import "./ProfileWidget.css";
import "./RespReviewDialog.css";
import ProfileForm from "./ProfileForm";
import { ReactComponent as CredentialsIcons } from "../../assets/svg/CredIcon.svg";
import { ReactComponent as UserIcon } from "../../../src/assets/svg/UserIcon.svg";
import { ReactComponent as WalletIcon } from "../../../src/assets/svg/WalletSvg.svg";
import { ReactComponent as ReviewIcon } from "../../../src/assets/svg/ReviewsDialog.svg";
import CredentialsForm from "./CredentialsForm";
import WalletWidget from "./WalletWidget";
import ReviewSection from "./ReviewSection";
import StarIconn from "../../widgets/StarIconn";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function RespReviewDialog({ open, close, fetchCustomerData }) {
  const [width, setWidth] = useState(window.innerWidth);

  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  const [reviewData, setReviewData] = useState({
    rating: "",
    description: "",
  });

  const { roomId, coachId } = useParams();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReviewData({ ...reviewData, [name]: value, roomId: roomId });
  };

  const submitRoomReview = async () => {
    const apiUrl = `${window.env_url}/v1/rooms/review/${roomId}`;
    const token = localStorage.getItem("accessToken");
    if (!reviewData.description || !reviewData.rating) {
      toast.warn("Please fill all the fields");
    }
    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(reviewData),
      });
      //console.log("Respose", response);
      if (!response.ok) {
        throw new Error(`HTTP Status Error! ${response.status}`);
      }
      const data = await response.json();
      //console.log("Review has been posted", data);
      toast.success("Review has been posted");
      close();
      fetchCustomerData();
    } catch (error) {
      console.error("Error posting the review", error);
    }
  };

  const submitCoachReview = async () => {
    const apiUrl = `${window.env_url}/v1/coaches/review/${coachId}`;
    const token = localStorage.getItem("accessToken");
    //console.log(token)
    if (!reviewData.description || !reviewData.rating) {
      toast.warn("Please fill all the fields");
    }
    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(reviewData),
      });
      //console.log("Respose", response);
      if (!response.ok) {
        throw new Error(`HTTP Status Error! ${response.status}`);
      }
      const data = await response.json();
      //console.log("Review has been posted", data);
      toast.success("Coach Review has been posted");
      close();
    } catch (error) {
      console.error("Error posting the review", error);
    }
  };

  const handleClose = () => {
    open = false; // Replace 'setOpen' with your state setter function
    setRating(null); // Reset the rating when the dialog is closed
    setHover(null);
  };

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const dialogRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        close(); // Call handleClose to close the dialog
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const callApi = () => {
    if (window.location.href.includes("meeting-room-details")) {
      submitRoomReview();
      //console.log("Review Room API has been called")
    } else {
      submitCoachReview();
    }
  };

  return (
    <>
      {width <= 768 && (
        <>
          <div className="profileFormWrapper">
            <section className="reviewSection">
              <div className="reviewFormHeaderWrapper" ref={dialogRef}>
                <div id="resp-review-container">
                  <div className="resp-review-header">
                    <div className="review-dialog-heading">write a review</div>
                  </div>
                  <div className="rating-container">
                    <span className="rating-text">Rating</span>
                    <span className="rating-value">{rating ? rating : 0}</span>
                  </div>
                  <div className="star-container">
                    {[...Array(5)].map((star, index) => {
                      const currentRating = index + 1;
                      return (
                        <label>
                          <input
                            type="radio"
                            name="rating"
                            value={currentRating}
                            onChange={handleInputChange}
                            onClick={() => setRating(currentRating)}
                          />

                          <StarIconn
                            className="star"
                            color={currentRating <= (hover || rating) ? "#ffc107" : "#99999"}
                            width="1vw"
                            onMouseEnter={() => setHover(currentRating)}
                            onMouseLeave={() => setHover(null)}
                          />
                        </label>
                      );
                    })}
                  </div>
                  <div className="textarea-container">
                    <textarea
                      placeholder="Share details of your experience...."
                      name="description"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="submit-btn-container" onClick={callApi}>
                    <button className="submit-review-btn">Send</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default RespReviewDialog;
