import TextField from "@mui/material/TextField";
import "./inputField.css";

export default function InputField(props) {
  const {
    fieldLabel,
    placeholder,
    className,
    isMultiline,
    rows,
    style,
    width,
    name,
    setFormState,
    formState,
    InputProps,
  } = props;
  const handleOnchange = (e) => {
    const { name, value } = e.target;

    if (name === "notes") {
      setFormState((prevState) => {
        return {
          ...prevState,
          setupDetails: { ...prevState.setupDetails, [name]: value },
        };
      });
      return;
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  let multiLineClassName;
  if (isMultiline) {
    multiLineClassName = "multilinefield";
  } else {
    multiLineClassName = "";
  }

  return (
    <div className={`inputContainer registerinput ${multiLineClassName}`}>
      <label className="inputFieldLabel">{fieldLabel}</label>
      <TextField
        // autoCapitalize="none"
        {...props}
        id="outlined-basic"
        label={formState[name] ? "" : placeholder}
        InputLabelProps={{
          shrink: false,
        }}
        name={name}
        variant="outlined"
        multiline={isMultiline}
        rows={rows}
        style={{ ...style }}
        onChange={handleOnchange}
        className={className}
        value={formState[name]}
        InputProps={InputProps}
      />
    </div>
  );
}
