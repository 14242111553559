import { Box, Modal } from "@mui/material";
import { useRef, useState } from "react";
import "./ReviewDialog.css";
import { ReactComponent as UserIcon } from "../assets/svg/UserIcon.svg";
import { ReactComponent as EyeIcon } from "../assets/svg/Eyeicon.svg";
import { ReactComponent as StarIcon } from "../assets/svg/Star.svg";
import { Padding, Star } from "@mui/icons-material";
import StarIconn from "../widgets/StarIconn";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomCloseIcon from "../assets/svg/CustomCloseIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "47%",
  height: "max-content",
  borderStyle: "none",
  padding: "2rem 0rem",
};

const tabletStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: " translate(-50%, -50%)",
  width: "63vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  height: "72.7vw",
  borderStyle: " none",
};

const CoachReviewDialog = ({ open, close, fetchAllReviews, bookingId }) => {
  const handleClose = () => {
    open = false; // Replace 'setOpen' with your state setter function
    setRating(null); // Reset the rating when the dialog is closed
    setHover(null);
  };
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  const { coachId } = useParams();

  const getFileUrl = `${window.env_url}/v1/files`;

  const dialogRef = useRef(null);

  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        close(); // Call handleClose to close the dialog
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const [reviewData, setReviewData] = useState({
    rating: "",
    description: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReviewData({ ...reviewData, [name]: value, coachId: coachId });
  };

  let appliedStyle = {};

  if (width >= 1024 && width <= 1440) {
    appliedStyle = style;
  } else if (width > 768 && width <= 1023) {
    appliedStyle = tabletStyle;
  } else {
    appliedStyle = style;
  }

  const submitCoachReview = async () => {
    const apiUrl = `${window.env_url}/v1/coachbookings/review/${bookingId}`;
    const token = localStorage.getItem("accessToken");
    //console.log(token);
    if (!reviewData.description || !reviewData.rating) {
      toast.error("Please fill all the fields");
    }
    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify(reviewData),
      });
      //console.log("Respose", response);
      if (!response.ok) {
        throw new Error(`HTTP Status Error! ${response.status}`);
      }
      const data = await response.json();
      //console.log("Review has been posted", data);
      toast.success("Review has been posted");
      close();
      fetchAllReviews();
    } catch (error) {
      console.error("Error posting the review", error);
    }
  };

  return (
    <>
      <div>
        <Modal
          open={true}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={appliedStyle} ref={dialogRef}>
            <div id="review-container">
              <div className="review-header">
                <div className="login-dialog-heading">write a review</div>
                <CustomCloseIcon fill={"white"} onClick={close} />
              </div>
              <span className="rating-text">Rating</span>
              <span className="rating-value">{rating ? rating : 0}</span>
              <div className="star-container">
                {[...Array(5)].map((star, index) => {
                  const currentRating = index + 1;
                  return (
                    <label>
                      <input
                        type="radio"
                        name="rating"
                        value={currentRating}
                        onChange={handleInputChange}
                        onClick={() => setRating(currentRating)}
                      />

                      <StarIconn
                        className="star"
                        color={
                          currentRating <= (hover || rating)
                            ? "#ffc107"
                            : "#99999"
                        }
                        onMouseEnter={() => setHover(currentRating)}
                        onMouseLeave={() => setHover(null)}
                      />
                    </label>
                  );
                })}
              </div>
              <div className="textarea-container">
                <textarea
                  placeholder="Share details of your experience...."
                  name="description"
                  onChange={handleInputChange}
                />
              </div>
              <div className="submit-btn-container" onClick={submitCoachReview}>
                <button className="submit-review-btn">Send</button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default CoachReviewDialog;
