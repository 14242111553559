import React, { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./CheckoutForm.css";
import { token } from "../../utils/utilityFunctions";
import { jwtDecode } from "jwt-decode";

export const CheckoutForm = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const jwtTokenDecoded = jwtDecode(token);
  const location = useLocation();
  const walletBalance = location.state.walletBalance;
  //console.log(walletBalance);

  const backendUrl = `${window.env_url}/v1/payments`;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null || stripe == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      setErrorMessage(submitError.message);
      return;
    }

    const reqBody = {
      amount: amount,
      currency: "cad",
      receipt_email:jwtTokenDecoded["email"]
    };
    const resp = await axios.post(backendUrl, reqBody, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });


    console.log("Rsponse", resp)
    console.log("Data",resp?.data.body[0].client_secret)
    
    const paymentIntent = resp?.data.body[0].client_secret
    const clientSecret = paymentIntent;
    console.log("cs", clientSecret)
    const returnPath = "/home";
    const response = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}${returnPath}`,
      },
    });
    
    console.log("Stripe res",response)
    const { error } = response;
    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="payment-container">
      <div>
        <h1>Recharge Details</h1>
        <span>Amount :</span>
        <span>${walletBalance}CAD</span>
      </div>
      <form
        
        //  className="w-[30%] flex flex-col gap-4"
        style={{ backgroundColor: "#f6f9fc" }}
        id="checkOutForm"
      >
        <PaymentElement />
        <button
          className="flex bg-slate-200 w-full justify-center items-center h-[50px] rounded checkout-pay-button"
          type="submit"
          disabled={!stripe || !elements}
          id="checkOutBtn"
          onClick={handleSubmit}
        >
          Pay ${walletBalance}
        </button>
        {/* Show error message to your customers */}
        {errorMessage && <div className="text-center text-slate-200">{errorMessage}</div>}
      </form>
    </div>
  );
};
