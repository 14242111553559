import React from "react";
import PropTypes from "prop-types";
import "./DummyComponent.css";
import { truncateDescription, truncateIntegerWithEllipsis } from "../../utils/utilityFunctions";

const DummyComponent = ({ figure, description }) => {
  return (
    <div className="stats-container">
      <div className="circle">
        {description == "Credits" ? truncateIntegerWithEllipsis(figure, 3) : figure}
      </div>
      <div className="text" style={{fontWeight: "700"}}>{description}</div>
    </div>
  );
};

DummyComponent.propTypes = {
  figure: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DummyComponent;
