import "./NewsCard.css";

const NewsCard = ({ imgSrc, newsTitle,newsDescription, description,onClick }) => {
  return (
    <div className="news" onClick={()=>{onClick({imgSrc,newsTitle,newsDescription})}} style={{cursor:'pointer'}}>
      <img src={imgSrc} className="news-image" />
      <div className="news-details">
        <p>{newsTitle}</p>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default NewsCard;
