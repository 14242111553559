import { createPortal } from "react-dom";
import NotificationWidget from "../../../widgets/NotificationWidget";
import MSideBar from "../../sidebar/mobile/MSideBar";
import { ReactComponent as ToggleSideBar } from "../../../assets/svg/ToggleSidebar.svg";
import { useEffect, useState } from "react";
import ProfileWidget from "../../../dialogs/profile-ui/ProfileWidget";
import "./MNavbar.css";
import SearchNavIcon from "./SearchNavIcon";
import ProfileOptions from "../../../dialogs/profile-options/ProfileOptions";

const MNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const [navBarData, setNavBarData] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleProfile = () => {
    setIsProfileClicked(!isProfileClicked);
  };

  const closeMobProf = () => {
    setIsProfileClicked(false);
  };

  const getFileUrl = `${window.env_url}/v1/files/`;

  const getUser = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${window.env_url}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setNavBarData(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  //console.log("Navbar da", navBarData)

  const profImage = navBarData?.image;

  useEffect(() => {
    getUser();
  }, []);

  const appLogo = getFileUrl + localStorage.getItem("adminImage");
  const appName = localStorage.getItem("appname");

  let imgPath;
  if (profImage?.includes("avataaars.io")) {
    imgPath = profImage;
  } else {
    imgPath = getFileUrl + profImage;
  }

  const MSideBarPortal = ({ isMenuOpen, toggleMenu }) => {
    return createPortal(
      <MSideBar isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />,
      document.body // This is the container element outside your current hierarchy
    );
  };

  return (
    <>
      <MSideBarPortal isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <nav>
        <ToggleSideBar id="toggleSideBar" onClick={toggleMenu} />
        <div className="nav-searchBar">
          <input type="text" placeholder="Search Here" />
          <SearchNavIcon />
        </div>
        <div className="notif-prof-img">
          <NotificationWidget />
          <div className="profImgWrapper" onClick={handleProfile}>
            <img src={imgPath} className="mobile-nav-img" />
          </div>
        </div>
      </nav>
      {isProfileClicked && <ProfileOptions onClose={handleProfile} closeMobProf={closeMobProf} />}
    </>
  );
};

export default MNavbar;
