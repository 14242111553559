import React from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";

const StarRating = ({ rating, totalStars, fontSize }) => {
  const starArray = [];
  const mobileMediaQuery = window.matchMedia('(max-width: 768px)').matches;

  

  for (let i = 1; i <= totalStars; i++) {
    if (i <= rating) {
      // Full star
      starArray.push(<StarIcon sx={{ fontSize: fontSize,  }} />);
    } else {
      // Empty star
      starArray.push(
        <StarBorderPurple500OutlinedIcon sx={{ fontSize: fontSize, color: "rgb(0", }} />
      );
    }
  }

  return (
    <div className="star-rating reviewCard" style={{ color:  "#ff931b" }}>
      {starArray}
    </div>
  );
};

export default StarRating;
