import { Box, Modal } from "@mui/material";
import { useState } from "react";
import "./UserLoginDialog.css";
import { ReactComponent as UserIcon } from "../assets/svg/UserIcon.svg";
import Authentication from "../components/auth/Authentication";
import { useAuth } from "../context/AuthContext";
import { token } from "../utils/utilityFunctions";
import CustomCloseIcon from "../assets/svg/CustomCloseIcon.jsx"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ProgressSpinner } from "primereact/progressspinner";

const apiURL = process.env.SERVER_API_URL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  width: "509px",
  height: "389px",
  padding: "0",
  height: "max-content",
  width: "36vw",
  paddingBottom: "1.5rem",
};

const responsiveStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "91vw",
  height: "max-content",
  paddingBottom: "1.5rem",
};

const tabletStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "83%",
  height: "35%",
};

const laptopStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "36vw",
  height: "max-content",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
};

export default function UserLoginDialog({ handleClose }) {
  const [showPassword, setShowPassword] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [openOtp, setOpenOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let appliedStyle;

  if (width <= 768) {
    appliedStyle = responsiveStyle;
  } else if (width >= 1024 && width <= 1440) {
    appliedStyle = laptopStyle;
  } else if (width > 768 && width <= 1023) {
    appliedStyle = tabletStyle;
  } else {
    appliedStyle = style;
  }

  const handleOtpDialog = () => {
    setOpenOtp(!openOtp);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value.toLowerCase(),
    });
  };

  const handleFormSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsLoading(true);
    //console.log(formData);
    const apiUrl = process.env.REACT_APP_API_URL;
    //console.log("Api url", apiUrl)
    try {
      const response = await fetch(`${window.env_url}/v1/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...formData, email: formData.email.toLowerCase() }),
      });
      if (response.ok) {
        const data = await response.json();
        const signUpToken = data.body[0].token;
        sessionStorage.setItem("signUpToken", signUpToken);
        if (!openOtp) {
          setOpenOtp(true);
        }
      } else {
        toast.error("Enter valid email Id", {
          theme: "colored",
        });
        console.error("Error adding user.");
      }
    } catch (error) {
      console.error("Error:", error);
      if (error instanceof Response) {
        console.error("Response status:", error.status);
        const errorMessage = await error.text();
        console.error("Error message:", errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={appliedStyle} id="userLoginDialog">
          <div className="login-header login">
            <div className="login-dialog-credentials-heading">Login</div>
            <CustomCloseIcon fill="white" onClick={handleClose} />
          </div>
          <form className="ul-form">
            <label className="ul-label">Email/Username</label>
            <div className="user-login input-container">
              <input
                type="text"
                id="email"
                name="email"
                className="login-email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <div className="ul-icon-container">
                <UserIcon />
              </div>
            </div>
          </form>
          <div className="btn-container" onClick={handleFormSubmit}>
            <button
              className="login-submit-btn email-verify-button"
              disabled={isLoading}
            >
              <span>Verify</span>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading && (
                  <ProgressSpinner
                    style={{ width: "20px", height: "20px" }}
                    strokeWidth="8"
                    animationDuration=".5s"
                  />
                )}
              </div>
            </button>
          </div>
        </Box>
      </Modal>
      {openOtp && (
        <Authentication
          open={openOtp}
          onClose={handleClose}
          handleResendOtp={handleFormSubmit}
        // handleFormSubmit={handleFormSubmit}
        />
      )}
    </div>
  );
}
