import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./MeetingRooms.css";
import moment from "moment";
import { ReactComponent as Arrow } from "../../assets/svg/Arrow.svg";
import { ReactComponent as FilterWatch } from "../../assets/svg/FilterWatch.svg";
import { ReactComponent as Calendar } from "../../assets/svg/Calendar2.svg";
import { ReactComponent as Timer } from "../../assets/svg/Hourglass.svg";
import { ReactComponent as Slider } from "../../assets/svg/Slider.svg";
import { ReactComponent as Downarrow } from "../../assets/svg/Downarrow.svg";
import { ReactComponent as ToggleSideBar } from "../../assets/svg/ToggleSidebar.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/RespFilterSearch.svg";
import { ReactComponent as RespFilterCalendar } from "../../assets/svg/RespFilterCalendar.svg";
import { ReactComponent as RespFilterClock } from "../../assets/svg/RespFilterClock.svg";
import { ReactComponent as RespFilterTimer } from "../../assets/svg/RespFilterTimer.svg";
import { ReactComponent as RespFilterSlider } from "../../assets/svg/RespFilterSlider.svg";
import { ReactComponent as RespFilterDropdown } from "../../assets/svg/RespFilterDropdown.svg";
import TuneIcon from "@mui/icons-material/Tune";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import FilterWidget from "../../dialogs/profile-ui/filter-widget/FilterWidget";
import { useMeetingFilterContext } from "../../context/context";
import StarRating from "../../widgets/StarRating";
import MSideBar from "../../components/sidebar/mobile/MSideBar";
import NotificationWidget from "../../widgets/NotificationWidget";
import MNavbar from "../../components/navbar/mobile/MNavbar";
import RoomCard from "../../cards/RoomCard";
import DurationModal from "../../cards/DurationModal";
import MRoomCard from "../../cards/mobile/MRoomCard";
import {
  extractFirstValue,
  groupArray,
  truncateDescription,
} from "../../utils/utilityFunctions";
import TRoomCards from "../../components/TRoomCards";
import { toast } from "react-toastify";
import InputWithIcon from "../InputWithIcon";
import "../InputWithIcon.css";
import { Search } from "@mui/icons-material";
import ShimmerCard from "../../utils/shimmer/ShimmerCard";
import { Button } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import RoomBookingHistory from "../BookingHistory/room/RoomBookingHistory";

const MeetingRooms = () => {
  const homeActive = true;
  const meetingActive = false;
  const coachesActive = false;
  const inputRef = useRef(null);
  const roomCardsContainerRef = useRef();
  const getFileUrl = `${window.env_url}/v1/files/`;
  const { credits, ratings, facility } = useMeetingFilterContext();

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click(); // Focus the input field when the icon is clicked
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  const [durationDisplayedInText, setDurationDisplayedInText] =
    useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [hasOpenedModal, setHasOpenedModal] = useState(false);
  const onCloseModal = (duration) => {
    setDurationDisplayedInText(duration);
    setNewFilterData({ ...newFilterData, duration: duration });
  };
  const onOpenModal = () => {
    setIsOpen(true);
    setHasOpenedModal(true);
  };
  const { hours, minutes, seconds } = durationDisplayedInText || {};

  const [availableRoomCardsData, setAvailableRoomCardsData] = useState([]);

  const handleViewClick = (roomId) => {
    window.location.href = `/meeting-room-details/${roomId}`;
  };
  const [historyTable, setHistoryTable] = useState(false);

  //RESPONSIVE STATE

  const [width, setWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [facilityData, setFacilityData] = useState(null);

  // facility
  const [showFacilityOptions, setShowFacilityOptions] = useState(false);
  const [selectedFacilityOption, setSelectedFacilityOption] = useState("");
  // credits
  const [showCreditsOptions, setShowCreditsOptions] = useState(false);
  const [selectedCreditsOption, setSelectedCreditsOption] = useState("");
  //RATINGS
  const [showRatingsOptions, setShowRatingsOptions] = useState(false);
  const [selectedRatingsOption, setSelectedRatingsOption] = useState("");

  //FILTERS DIALOG
  const [openFilters, setOpenFilters] = useState(false);
  const openFilterDialog = () => {
    setOpenFilters(true);
  };

  const handleFiltersClick = () => {
    setOpenFilters(!openFilters);
  };

  const closeAllFilters = () => {
    if (showFacilityOptions || showCreditsOptions || showRatingsOptions) {
      setShowFacilityOptions(false);
      setShowCreditsOptions(false);
      setShowRatingsOptions(false);
    }
  };

  //DROPDOWN OPTIONS
  const fetchFacilities = async () => {
    const apiUrl = `${window.env_url}/v1/filterfacilities`;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFacilityData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  const LAPTOP_SCREEN_SIZE = 6;
  const LARGE_SCREEN_SIZE = 8;

  const SCREEN_SIZE = window.innerWidth;

  const PAGE_SIZE =
    SCREEN_SIZE >= 1024 && SCREEN_SIZE <= 1440
      ? LAPTOP_SCREEN_SIZE
      : LARGE_SCREEN_SIZE;

  const facilityOptions = facilityData?.map((data, index) => data.title);
  const creditsOptions = [
    { max: 200, min: 100 },
    { max: 300, min: 200 },
    { max: 400, min: 300 },
    { max: 500, min: 400 },
  ];
  const ratingsOptions = [
    { max: 1, min: 0 },
    { max: 2, min: 1 },
    { max: 3, min: 2 },
    { max: 4, min: 3 },
    { max: 5, min: 4 },
  ];

  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  //TOGGLE
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const togglefacilityOptions = () => {
    setShowFacilityOptions(!showFacilityOptions);
  };

  const toggleCreditsOptions = () => {
    setShowCreditsOptions(!showCreditsOptions);
  };

  const toggleRatingsOptions = () => {
    setShowRatingsOptions(!showRatingsOptions);
  };

  //SELECT OPTION
  const selectOption = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  const selectfacilityOption = (option) => {
    setSelectedFacilityOption(option);
    setShowFacilityOptions(false);
  };

  const selectcreditsOption = (option) => {
    setSelectedCreditsOption(option);
    setShowCreditsOptions(false);
  };

  const selectRatingsOption = (option) => {
    setSelectedRatingsOption(option);
    setShowRatingsOptions(false);
  };

  const [placeholder, setPlaceholder] = useState("Date");

  useEffect(() => {
    fetchAllMeetingRooms();
    fetchFacilities();
  }, []);

  console.log("duration", durationDisplayedInText);

  const convertToUnits = (duration) => {
    if (duration) {
      const { hours, minutes, seconds } = duration;
      const totalHours = hours + minutes / 60 + seconds / 3600;
      const totalMinutes = hours * 60 + minutes + seconds / 60;
      const units = (totalMinutes / 15) * 0.25;
      return units;
    }
  };

  // Log the duration in units
  console.log("duration", convertToUnits(durationDisplayedInText));

  const fetchAllMeetingRooms = async () => {
    const token = localStorage.getItem("accessToken");
    const apiUrl = `${window.env_url}/v1/rooms`;
    try {
      setIsLoading(true);
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAvailableRoomCardsData(data.body[0]);
    } catch (error) {
      console.error("Error fetching rooms", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  const handleMouseWheel = (e) => {
    if (roomCardsContainerRef.current) {
      roomCardsContainerRef.current.scrollLeft += e.deltaY;
      e.preventDefault();
    }
  };

  // Attach the event listener
  useEffect(() => {
    const container = roomCardsContainerRef.current;
    if (container) {
      container.addEventListener("wheel", handleMouseWheel, { passive: false });

      // Clean up the event listener when the component unmounts
      return () => {
        container.removeEventListener("wheel", handleMouseWheel);
      };
    }
  }, [roomCardsContainerRef]);

  let availableRoomsTabletView = groupArray(availableRoomCardsData, 4);

  const [newFilterData, setNewFilterData] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    facilities: "",
    duration: "",
  });

  const units = convertToUnits(newFilterData.duration);

  const startTime = moment(newFilterData.startTime, "HH:mm:ss");
  const startTimeMoment = moment(startTime, "HH:mm");
  const endTimeMoment = startTimeMoment.clone().add(units, "hours");
  let startDate = moment(newFilterData.start);
  let endDate = moment(newFilterData.end);
  const filterData = {
    checkInDate: moment(startDate).format("YYYY-MM-DD"),
    checkOutDate: moment(startDate).format("YYYY-MM-DD"),
    checkInTime: startTimeMoment.format("HH:mm:ss"),
    checkOutTime: endTimeMoment.format("HH:mm:ss"),
    duration: convertToUnits(durationDisplayedInText),
  };

  console.log(filterData);

  const calculateDuration = () => {
    if (newFilterData.startTime && newFilterData.endTime) {
      const start = moment(`2024-01-01T${newFilterData.startTime}`);
      const end = moment(`2024-01-01T${newFilterData.endTime}`);
      const durationMinutes = end.diff(start, "minutes");
      const durationUnits = durationMinutes / 15; // 15 minutes is 1 unit
      setNewFilterData({ ...newFilterData, duration: durationUnits });
    }
  };

  //console.log(availableRoomsTabletView);

  const filterRooms = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${window.env_url}/v1/meetingbookings/availability`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(filterData),
        }
      );
      toast.success("Rooms Filtered");
      console.log("filterres", response);
      const data = await response.json();
      console.log("filterdata", data);
      setAvailableRoomCardsData(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  console.log("Av", availableRoomCardsData);

  console.log(selectedCreditsOption);

  let filteredCreditsData = availableRoomCardsData;

  if (selectedCreditsOption) {
    filteredCreditsData = filteredCreditsData.filter((item) => {
      if (
        item.credits >= selectedCreditsOption.min &&
        item.credits <= selectedCreditsOption.max
      ) {
        return true;
      }
      return false;
    });
  }

  if (selectedRatingsOption) {
    filteredCreditsData = filteredCreditsData.filter((item) => {
      if (
        item.avgratings >= selectedRatingsOption.min &&
        item.avgratings <= selectedRatingsOption.max
      ) {
        return true;
      }
      return false;
    });
  }

  if (selectedFacilityOption) {
    filteredCreditsData = filteredCreditsData.filter((item) => {
      if (item.facilities?.includes(selectedFacilityOption)) {
        return true;
      }
      return false;
    });
  }

  console.log("filteredCreditsData", filteredCreditsData);

  return (
    <>
      {width <= 768 ? (
        <div className="mr-mv-root" onClick={closeAllFilters}>
          <div className="nav-filterSection">
            <MNavbar />
            <div className="mr-filter-container mr-Resp">
              <div className="mr-filter-mobileDiv">
                <div className="filter filterSection">
                  <div className="mr-filter-top mr-Resp">
                    <div className="mr-filter1 mr-Resp">
                      <input
                        type="date"
                        name="date"
                        id="filterByDate"
                        className="mv"
                        ref={inputRef}
                        onChange={(event) =>
                          setNewFilterData({
                            ...newFilterData,
                            start: event.target.value,
                          })
                        }
                        placeholder="Date"
                      />
                      <RespFilterCalendar id="filterCalendarSvg" />
                    </div>
                    <div className="mr-filter1 mr-Resp time">
                      <input
                        type="time"
                        name="time"
                        id="filterByTime"
                        placeholder="Time"
                        onChange={(event) => {
                          setNewFilterData({
                            ...newFilterData,
                            startTime: event.target.value,
                          });
                          calculateDuration();
                        }}
                      />
                      <RespFilterClock id="filterClockSvg" />
                    </div>
                    <div className="mr-filter1 mr-Resp final">
                      <RespFilterTimer onClick={onOpenModal} />
                      <input
                        type="text"
                        value={
                          hasOpenedModal && durationDisplayedInText
                            ? ` ${hours} hr${
                                hours !== 1 ? "s" : ""
                              }, ${minutes} min${minutes !== 1 ? "s" : ""}`
                            : "Duration"
                        }
                        readOnly
                        id="filterByDuration"
                        onClick={onOpenModal}
                        placeholder="m"
                      />
                    </div>
                  </div>
                  <div className="mr-filter-bottom mr-Resp">
                    <div className="sliderIcon container">
                      <RespFilterSlider onClick={openFilterDialog} />
                    </div>
                    {credits && (
                      <div className="mr-filter-credits mr-dropdown">
                        <div className="ip-svg-section">
                          <input
                            type="text"
                            name="price"
                            readOnly
                            id="filterByPrice"
                            value={
                              selectedCreditsOption
                                ? `${selectedCreditsOption.min} - ${selectedCreditsOption.max}`
                                : ""
                            }
                            onClick={toggleCreditsOptions}
                            placeholder="credits"
                          />
                          <Downarrow className="price-filter-svg" />
                        </div>
                        {showCreditsOptions && (
                          <div className="dropdown-options price-filter">
                            {creditsOptions.map((option, index) => (
                              <span
                                key={index}
                                onClick={() => selectcreditsOption(option)}
                              >
                                {option.min} - {option.max}
                                <br />
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    {ratings && (
                      <div className="mr-filter-ratings mr-dropdown">
                        <div className="ip-svg-section">
                          <input
                            type="text"
                            name="rating"
                            readOnly
                            id="filterByPrice"
                            placeholder="Ratings"
                            value={
                              selectedRatingsOption
                                ? `${selectedRatingsOption.min} - ${selectedRatingsOption.max}`
                                : ""
                            }
                            onClick={toggleRatingsOptions}
                          />
                          <Downarrow className="ratings-filter-svg" />
                        </div>
                        {showRatingsOptions && (
                          <div className="dropdown-options price-filter">
                            {ratingsOptions.map((option, index) => (
                              <span
                                key={index}
                                onClick={() => selectRatingsOption(option)}
                              >
                                {option.min} - {option.max}
                                <br />
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    {facility && (
                      <div className="mr-filter-facility mr-dropdown">
                        <input
                          type="text"
                          value={selectedFacilityOption}
                          readOnly
                          id="filterByfacility"
                          onClick={togglefacilityOptions}
                          placeholder="Facility"
                        />
                        {showFacilityOptions && (
                          <div className="dropdown-options cap-filter">
                            {facilityOptions.map((option, index) => (
                              <li
                                key={index}
                                onClick={() => selectfacilityOption(option)}
                              >
                                {option}
                              </li>
                            ))}
                          </div>
                        )}
                        <RespFilterDropdown id="facilityFilter" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="searchBarDiv" onClick={filterRooms}>
                  <SearchIcon />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="meetingRoomSection mr">
            {filteredCreditsData.map((cardData, index) => {
              const ImageElement = cardData.files;
              return (
                // <MRoomCard
                //   key={cardData?.id}
                //   imgSrc={
                //     cardData?.files
                //       ? getFileUrl + extractFirstValue(cardData?.files, "|")
                //       : "https://picsum.photos/200/300"
                //   }
                //   roomTitle={extractFirstValue(cardData?.title, "|")}
                //   description={truncateDescription(cardData.description, 115)}
                //   onViewClick={() => handleViewClick(cardData.id)}
                //   roomId={cardData?.id}
                // />

                <div key={index} style={{height:"100px", border:"1px solid black"}}>
                  <div>
                    <p>{extractFirstValue(cardData?.title, "|")}</p>
                  </div>
                </div>
              );
            })}
          </div> */}

          <div className="meetingRoomSection mr" onClick={closeAllFilters}>
            {filteredCreditsData?.map((cardData, index) => {
              const ImageElement = cardData.files;
              return (
                <MRoomCard
                  key={cardData?.id}
                  imgSrc={
                    cardData?.files
                      ? getFileUrl + extractFirstValue(cardData?.files, "|")
                      : "https://picsum.photos/200/300"
                  }
                  roomTitle={extractFirstValue(cardData?.title, "|")}
                  description={truncateDescription(cardData.description, 115)}
                  onViewClick={() => handleViewClick(cardData.id)}
                  roomId={cardData?.id}
                />
              );
            })}
          </div>
          {openFilters && <FilterWidget onClose={handleFiltersClick} />}
          <DurationModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onCloseModal={onCloseModal}
            initialDuration={durationDisplayedInText || undefined}
          />
        </div>
      ) : width <= 1023 ? (
        <div className="meeetingrooms-root tablet" onClick={closeAllFilters}>
          <Navbar />
          <div className="nav-home-rightsection">
            <Sidebar
              homeActive={homeActive}
              meetingActive={meetingActive}
              coachesActive={coachesActive}
            />
            <div className="home-container">
              <div className="mr-right-section home">
                <div className="mr filter-container">
                  <div className="mr-filter-div tablet">
                    <div className="mr-filter-top">
                      <div className="mr-filter1 meetingrooms">
                        <input
                          type="date"
                          name="date"
                          id="filterByDate"
                          ref={inputRef}
                          onChange={(event) =>
                            setNewFilterData({
                              ...newFilterData,
                              start: event.target.value,
                            })
                          }
                          placeholder={placeholder}
                          onFocus={() => setPlaceholder("")}
                          onBlur={() => setPlaceholder("Date")}
                        />
                        <Calendar onClick={handleIconClick} />
                      </div>
                      <div
                        className="mr-filter1 meetingrooms"
                        style={{ borderRight: "none", borderLeft: "none" }}
                      >
                        <input
                          type="time"
                          name="time"
                          onChange={(event) => {
                            setNewFilterData({
                              ...newFilterData,
                              startTime: event.target.value,
                            });
                            calculateDuration();
                          }}
                          id="filterByTime"
                          placeholder="Time"
                        />
                        <FilterWatch />
                      </div>
                      <div
                        className="mr-filter1 meetingrooms"
                        style={{ borderRight: "none" }}
                      >
                        <input
                          type="text"
                          value={
                            hasOpenedModal && durationDisplayedInText
                              ? ` ${hours} hr${
                                  hours !== 1 ? "s" : ""
                                }, ${minutes} min${minutes !== 1 ? "s" : ""}`
                              : "Duration"
                          }
                          readOnly
                          id="filterByDuration"
                          onClick={onOpenModal}
                          placeholder="Duration"
                        />
                        <Timer onClick={toggleOptions} />
                      </div>
                    </div>
                    <div className="mr-filter-bottom">
                      <div className="mr-filter-icon meetingrooms">
                        <Slider onClick={openFilterDialog} />
                      </div>
                      {credits && (
                        <div className="mr-filter-credits mr-dropdown">
                          <div className="ip-svg-section">
                            <input
                              type="text"
                              name="price"
                              readOnly
                              id="filterByPrice"
                              placeholder="Credits"
                              value={
                                selectedCreditsOption
                                  ? `${selectedCreditsOption.min} - ${selectedCreditsOption.max}`
                                  : ""
                              }
                              onClick={toggleCreditsOptions}
                            />
                            <Downarrow className="price-filter-svg" />
                          </div>
                          {showCreditsOptions && (
                            <div className="dropdown-options price-filter">
                              {creditsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => {
                                    selectcreditsOption(option);
                                    // setPageNumber(1);
                                  }}
                                >
                                  {option.min} - {option.max}
                                  <br />
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      )}

                      {ratings && (
                        <div className="mr-filter-ratings mr-dropdown">
                          <div className="ip-svg-section">
                            <input
                              type="text"
                              name="rating"
                              readOnly
                              placeholder="Rating"
                              id="filterByPrice"
                              value={
                                selectedRatingsOption
                                  ? `${selectedRatingsOption.min} - ${selectedRatingsOption.max}`
                                  : ""
                              }
                              onClick={toggleRatingsOptions}
                            />
                            <Downarrow className="ratings-filter-svg" />
                          </div>
                          {showRatingsOptions && (
                            <div className="dropdown-options price-filter">
                              {ratingsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => selectRatingsOption(option)}
                                >
                                  {option.min} - {option.max}
                                  <br />
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      )}

                      {facility && (
                        <div className="mr-filter-facility mr-dropdown">
                          <input
                            type="text"
                            value={selectedFacilityOption}
                            readOnly
                            placeholder="Facility"
                            id="filterByfacility"
                            onClick={togglefacilityOptions}
                          />
                          {showFacilityOptions && (
                            <div className="dropdown-options cap-filter">
                              {facilityOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => selectfacilityOption(option)}
                                >
                                  {option}
                                </span>
                              ))}
                            </div>
                          )}
                          <Downarrow />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mr-filter-btn" onClick={filterRooms}>
                    Find Rooms
                  </div>
                </div>
                <div className="mr-allroms">
                  <div className="mr-available">
                    <div className="mr-available-container">
                      <div id="tabletRoomContainer">
                        {filteredCreditsData
                          ?.slice((pageNumber - 1) * 8, pageNumber * 8)
                          .map((cardData, index) => (
                            <RoomCard
                              key={cardData?.id}
                              roomTitle={extractFirstValue(cardData.title, "|")}
                              imgSrc={
                                getFileUrl +
                                extractFirstValue(cardData?.files, "|")
                              }
                              roomId={cardData?.id}
                              totalStars={5}
                              description={truncateDescription(
                                cardData.description,
                                100
                              )}
                              onViewClick={() => handleViewClick(cardData.id)}
                            />
                          ))}
                      </div>
                      {/* <div className="paginationNumbers">
                        {Array(Math.ceil(filteredCreditsData.length / 8))
                          .fill("")
                          .map((_, page) => (
                            <button
                              key={page}
                              onClick={() => handlePageClick(page + 1)}
                              className={
                                Number(page) === pageNumber ? "activePage" : ""
                              }
                            >
                              {page + 1}
                            </button>
                          ))}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openFilters && <FilterWidget onClose={handleFiltersClick} />}
          <DurationModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onCloseModal={onCloseModal}
            initialDuration={durationDisplayedInText || undefined}
          />
        </div>
      ) : (
        <div className="mr mr-root" onClick={closeAllFilters}>
          <Navbar />
          <div className="mr-container">
            <Sidebar
              homeActive={homeActive}
              meetingActive={meetingActive}
              coachesActive={coachesActive}
            />
            <div className="mr-right-section">
              <div className="mr filter-container">
                <div className="mr-filter-div">
                  <div className="topFilterContainer">
                    <div className="filterField">
                      <div className="input-with-icon">
                        <input
                          type="date"
                          name="date"
                          id="filterByDate"
                          ref={inputRef}
                          onChange={(event) =>
                            setNewFilterData({
                              ...newFilterData,
                              start: event.target.value,
                            })
                          }
                          placeholder={placeholder || "mm/dd/yyyy"}
                          onFocus={() => setPlaceholder("")}
                          onBlur={() => setPlaceholder("Date")}
                        />
                        <div
                          className="icon-container"
                          onClick={handleIconClick}
                        >
                          <Calendar />
                        </div>
                      </div>
                    </div>
                    <div
                      className="filterField"
                      style={{ borderRight: "none", borderLeft: "none" }}
                    >
                      <div className="input-with-icon">
                        <input
                          type="time"
                          name="time"
                          id="filterByTime"
                          placeholder="Time"
                          onChange={(event) => {
                            setNewFilterData({
                              ...newFilterData,
                              startTime: event.target.value,
                            });
                            calculateDuration();
                          }}
                        />
                        <div
                          className="icon-container"
                          onClick={() => console.log("FilterWatch clicked")}
                        >
                          <FilterWatch />
                        </div>
                      </div>
                    </div>
                    <div
                      className="filterField"
                      style={{ borderRight: "none" }}
                    >
                      <div className="input-with-icon">
                        <input
                          type="text"
                          value={
                            hasOpenedModal && durationDisplayedInText
                              ? ` ${hours} hr${
                                  hours !== 1 ? "s" : ""
                                }, ${minutes} min${minutes !== 1 ? "s" : ""}`
                              : "Duration"
                          }
                          readOnly
                          id="filterByDuration"
                          onClick={onOpenModal}
                          placeholder="Duration"
                        />
                        <div className="icon-container" onClick={toggleOptions}>
                          <Timer />
                        </div>
                      </div>
                    </div>
                    {credits && (
                      <div className="filterField">
                        <div className="input-with-icon">
                          <input
                            type="text"
                            name="price"
                            readOnly
                            id="filterByPrice"
                            value={
                              selectedCreditsOption
                                ? `${selectedCreditsOption.min} - ${selectedCreditsOption.max}`
                                : ""
                            }
                            onClick={toggleCreditsOptions}
                            placeholder="Credits"
                          />
                          <div className="icon-container">
                            <Downarrow className="price-filter-svg" />
                          </div>
                          {showCreditsOptions && (
                            <div className="dropdown-options">
                              {creditsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => {
                                    selectcreditsOption(option);
                                    setPageNumber(1);
                                  }}
                                >
                                  {option.min} - {option.max}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {ratings && (
                      <div className="filterField">
                        <div className="input-with-icon">
                          <input
                            type="text"
                            name="rating"
                            readOnly
                            id="filterByPrice"
                            value={
                              selectedRatingsOption
                                ? `${selectedRatingsOption.min} - ${selectedRatingsOption.max}`
                                : ""
                            }
                            onClick={toggleRatingsOptions}
                            placeholder="Ratings"
                          />
                          <div className="icon-container">
                            <Downarrow className="price-filter-svg" />
                          </div>
                          {showRatingsOptions && (
                            <div className="dropdown-options">
                              {ratingsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => selectRatingsOption(option)}
                                >
                                  {option.min} - {option.max}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {facility && (
                      <div className="filterField">
                        <div className="input-with-icon filterInputDiv">
                          <input
                            type="text"
                            value={selectedFacilityOption}
                            readOnly
                            id="filterByfacility"
                            onClick={togglefacilityOptions}
                            placeholder="Facility"
                          />
                          <div className="icon-container filterInputDiv">
                            <Downarrow className="price-filter-svg" />
                          </div>
                          {showFacilityOptions && (
                            <div className="dropdown-options">
                              {facilityOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => selectfacilityOption(option)}
                                >
                                  {option}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-icon-container">
                  <div className="searchIcon">
                    <TuneIcon
                      sx={{ fill: "#ffffff" }}
                      onClick={openFilterDialog}
                    />
                  </div>
                  <div className="searchIcon" onClick={filterRooms}>
                    <Search
                      sx={{ fill: "white", fontSize: "2rem" }}
                      onClick={openFilterDialog}
                    />
                  </div>
                  <div className="searchIcon history">
                    <Button
                      sx={{
                        fontFamily: "Nunito",
                        color: "white",
                        textTransform: "Capitalize",
                      }}
                      onClick={
                        historyTable
                          ? () => setHistoryTable(false)
                          : () => setHistoryTable(true)
                      }
                    >
                      {historyTable ? "Rooms" : "History"}
                      <HistoryIcon />
                    </Button>
                  </div>
                </div>
              </div>

              {historyTable ? (
                <RoomBookingHistory />
              ) : (
                <div className="mr-allroms">
                  <div className="mr-available">
                    <div className="mr-available-container">
                      <div className="room-cards">
                        {isLoading ? (
                          Array.from({ length: 8 }).map((_, index) => (
                            <ShimmerCard key={index} />
                          ))
                        ) : filteredCreditsData.length === 0 ? (
                          <p className="noData">
                            There are currently no meeting rooms to display.
                          </p>
                        ) : (
                          filteredCreditsData
                            ?.slice(
                              (pageNumber - 1) * PAGE_SIZE,
                              pageNumber * PAGE_SIZE
                            )
                            .map((cardData, index) => (
                              <RoomCard
                                key={cardData?.id}
                                roomId={cardData?.id}
                                imgSrc={
                                  getFileUrl +
                                  extractFirstValue(cardData?.files, "|")
                                }
                                totalStars={5}
                                roomTitle={extractFirstValue(
                                  cardData?.title,
                                  "|"
                                )}
                                description={truncateDescription(
                                  cardData.description,
                                  115
                                )}
                                onViewClick={() => handleViewClick(cardData.id)}
                              />
                            ))
                        )}
                      </div>

                      <div className="paginationNumbers">
                        {Array(
                          Math.ceil(filteredCreditsData.length / PAGE_SIZE)
                        )
                          .fill("")
                          .map((_, page) => (
                            <button
                              key={page}
                              onClick={() => handlePageClick(page + 1)}
                              className={
                                page + 1 === pageNumber ? "activePage" : ""
                              }
                            >
                              {page + 1}
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {openFilters && <FilterWidget onClose={handleFiltersClick} />}
          <DurationModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onCloseModal={onCloseModal}
            initialDuration={durationDisplayedInText || undefined}
          />
        </div>
      )}
    </>
  );
};

export default MeetingRooms;
