import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const PhoneNumberInput = ({ value, onChange }) => {

  return (
    <div>
      <PhoneInput international defaultCountry="US" value={value} onChange={onChange} disabled />
    </div>
  );
};

export default PhoneNumberInput;
