import "./FilterWidget.css";
import { ReactComponent as FilterWatch } from "../../../assets/svg/FilterWatch.svg";
import { ReactComponent as Calendar } from "../../../assets/svg/Calendar2.svg";
import { ReactComponent as Timer } from "../../../assets/svg/Hourglass.svg";
import CustomCloseIcon from "../../../assets/svg/CustomCloseIcon.jsx";
import { ReactComponent as Slider } from "../../../assets/svg/Slider.svg";
import { ReactComponent as Downarrow } from "../../../assets/svg/Downarrow.svg";

import { useState } from "react";
import { useMeetingFilterContext } from "../../../context/context";

const FilterWidget = ({ onClose }) => {
  const {
    credits,
    ratings,
    facility,
    skills,
    updateCreditsFilter,
    updateRatingsFilter,
    updateFacilityFilter,
    updateSkillsFilter,
  } = useMeetingFilterContext();

  const handleCreditsChecked = (event) => {
    updateCreditsFilter(event.target.checked);
  };

  const handleRatingsChecked = (event) => {
    //console.log(ratings);
    updateRatingsFilter(event.target.checked);
  };

  const handleFacilityChecked = (event) => {
    //console.log(event.target.checked);
    //console.log("facility", facility);
    updateFacilityFilter(event.target.checked);
  };

  const handleSkillsChecked = (event) => {
    //console.log(event.target.checked);
    //console.log("Skills", skills);
    updateSkillsFilter(event.target.checked);
  };

  //console.log(window.location.pathname);

  const pathName = window.location.pathname;

  let displayFacilities;
  if (pathName.includes("/meeting-rooms")) {
    displayFacilities = true;
  }

  let displaySkills = false;
  if (pathName.includes("/coaches")) {
    displaySkills = true;
  }

  return (
    <>
      <div className="filterDialogWrapper">
        <div className="formContent">
          <div className="filterHeaderWrapper">
            <div className="fw-form">
              <h1 className="fd-heading"> Filter by</h1>
              <div className="checkBoxFields">
                <label>
                  <input type="checkbox" name="credits" checked={credits} onChange={handleCreditsChecked} />
                  <span className="fieldName">Credits</span>
                </label>
                <label>
                  <input type="checkbox" name="ratings" checked={ratings} onChange={handleRatingsChecked} />
                  <span className="fieldName">Ratings</span>
                </label>
                {displayFacilities && (
                  <label>
                    <input type="checkbox" name="facility" checked={facility} onChange={handleFacilityChecked} />
                    <span className="fieldName">Facility</span>
                  </label>
                )}
                {displaySkills && (
                  <label>
                    <input type="checkbox" name="skills" checked={skills} onChange={handleSkillsChecked} />
                    <span className="fieldName">Skills</span>
                  </label>
                )}
              </div>
            </div>
            <span className="closeDialogIcon">
            <CustomCloseIcon fill="#FF0000" onClick={onClose} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterWidget;
