import { useEffect, useState } from "react";
import ReviewCard from "./review-card/ReviewCard";
import "./ReviewSection.css";
import EditReviewDialog from "../EditReviewDialog";
import { ReactComponent as ReviewIcon } from "../../../src/assets/svg/ReviewsDialog.svg";
import CoachReviewSection from "./CoachReviewSection";
import RoomReviewSection from "./RoomReviewSection";

const ReviewSection = ({ onClose }) => {
  const [activeReview, setActiveReview] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const switchRoomReviews = () => {
    setActiveReview(1);
  };

  const switchCoachReviews = () => {
    setActiveReview(2);
  };

  return (
    <>
      <div className="rc-review-container">
        <div className="mv reviewFormHeader">
          <div className={`profileTag ${activeReview === 1 ? "active" : ""} review`} onClick={switchRoomReviews}>
            <h2>Room Reviews</h2>
          </div>
          <div className={`profileTag ${activeReview === 2 ? "active" : ""} review`} onClick={switchCoachReviews}>
            <h2>Coach Reviews</h2>
          </div>
        </div>
      </div>
      {activeReview === 1 && <RoomReviewSection onClose={onClose} activeReview={activeReview}/>}
      {activeReview === 2 && <CoachReviewSection onClose={onClose} activeReview={activeReview} />}
    </>
  );
};

export default ReviewSection;
