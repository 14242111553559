import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function PrivateRoutes() {
  const userData = JSON.parse(localStorage.getItem("userData"));
   const userType = userData?.usertype;
   console.log("For protecting routes",userData)
  let auth = {'token':true}
  return (
    userType === 'user' || 'coach' ? ( <Outlet />) : <><Navigate to="/"/> </>
  )
}

export default PrivateRoutes