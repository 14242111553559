import "./ReviewCard.css";
import { ReactComponent as EditReview } from "../../../assets/svg/EditReview.svg";
import { ReactComponent as DeleteReview } from "../../../assets/svg/DeleteReview.svg";
import { ReactComponent as FiveStar } from "../../../assets/svg/5star.svg";
import StarRating from "../../../widgets/StarRating";
import { truncateDescription } from "../../../utils/utilityFunctions";
import { useMediaQuery } from "@mui/material";

const ReviewCard = ({
  reviewerName,
  reviewerDate,
  reviewerImage,
  rating,
  reviewText,
  onEdit,
  onDelete,
  name,
  type,
}) => {
  const stringifiedDate = reviewerDate
    ? new Date(reviewerDate).toLocaleDateString()
    : "";

  const isMobile = useMediaQuery("(max-width: 768px)");



  console.log("reviewerImage", reviewerImage)

  return (
    <>
      <div className="reviewcontainer">
        <div className="review-image-description">
          <div className="review-image">
            <img src={reviewerImage} alt="Reviewer DP" className="review-dp" />
          </div>

          <div className="review-description">
            <StarRating rating={rating} totalStars={5} fontSize={isMobile ? "4vw" : "20px"} />
            <p className="review-description-name">
              <span className="descNameField">{type} Name</span>
              <span className="descColonField">:</span>
              <span className="descValueField">{name}</span>
            </p>
            <p className="review-description-date">
              <span className="descNameField">Date</span>
              <span className="descColonField">:</span>
              <span className="descValueField"> {stringifiedDate}</span>
            </p>
            <p className="review-description-text">
              <span className="descNameField">Comment</span>
              <span className="descColonField">:</span>
              <span className="descValueField">
                {truncateDescription(reviewText, 30)}
              </span>
            </p>
          </div>
        </div>
        <div className="revieweditdelete">
          {onEdit && (
            <div className="edit" onClick={onEdit}>
              <EditReview />
              <span className="icon-text edit">Edit</span>
            </div>
          )}
          {onDelete && (
            <div className="rev-icon deleteReview" onClick={onDelete}>
              <DeleteReview />
              <span className="icon-text undo">Undo</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewCard;
