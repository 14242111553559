import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

const SingleSelectDropdown = ({
  options,
  value,
  onChange,
  label,
  isMandatory,
}) => {
  const screenWidth = window.innerWidth;
  const islaptop = useMediaQuery("(min-width:1024px) and (max-width:1440px)");

  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{
        width: screenWidth < 769 ? "66vw" : islaptop ? "100%" : "100%",
        marginBottom: "16px", // Added spacing below the component
      }}
    >
      {!value && (
        <InputLabel
          id="select-label"
          shrink={false}
          sx={{
            position: "absolute",
            backgroundColor: "white",
            padding: "0 4px",
            transform: "translate(14px, 10px)",
          }}
        >
          {label} {isMandatory && " *"}
        </InputLabel>
      )}
      <Select
        value={value}
        onChange={onChange}
        required={isMandatory}
        displayEmpty
        MenuProps={{
          MenuListProps: {
            sx: {
              top: "550px",
              maxHeight: "200px",
            },
          },
        }}
        sx={{
          border: "1px solid #ccc", // Softened border color
          height: "40px", // Keeps the select box consistent in height
          "& .MuiSelect-select": {
            padding: "8px 14px", // Adjusted padding for the dropdown
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.code} value={option.code}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleSelectDropdown;
