import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { useLocation } from "react-router-dom";
// import Profile from "../../components/main/profile/Profile.Component";

const Checkout = () => {

  const location = useLocation();
  const rechargeAmount = location.state.walletBalance;
  //console.log("rec",rechargeAmount);

  const options = {
    mode: "payment",
    amount: rechargeAmount,
    currency: "cad",
    appearance: {},
  };

  const publishKey = process.env.REACT_APP_STRIPE_API_PUBLISH_KEY
  //console.log(publishKey)
  const stripePromise = loadStripe(`${publishKey}`);

  return (
    <div className="w-screen h-screen">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm amount={options?.amount} />
      </Elements>
      {/* <Profile /> */}
    </div>
  );
};

export default Checkout;
