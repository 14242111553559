import { useEffect, useState } from "react";
import Loginpic from "../../assets/png/Loginpic.png";
import Logo from "../../assets/png/Logo.png";
import "./LoginPage.css";
import UserLoginDialog from "../../dialogs/UserLoginDialog";
import QbookingDialog from "../../dialogs/QbookingDialog";
import Register from "../../dialogs/register";
import QuickBooking from "../../dialogs/QuickBooking";
import HomePic from "../../assets/png/home img.png";
import AnimatedText from "../../utils/AnimatedText";
import spaceShip from "../../assets/svg/spaceship.svg";
import note from "../../assets/svg/Note.svg";
import quickbook from "../../assets/svg/quickbook.svg";
import mobilehome from "../../assets/svg/mobilehome.svg";
import IncuHubLogo from "../../assets/png/IncuHubLogo.png"

// import { clearInterval } from "timers";

const LoginPage = () => {
  const [open, setOpen] = useState(false);
  const [openqb, setOpenqb] = useState(false);
  const [openReg, setOpenReg] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenqb = () => setOpenqb(true);
  const handleOpenReg = () => setOpenReg(true);
  const handleCloseqb = () => setOpenqb(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [adminDetails, setAdminDetails] = useState(null);
  const word = "Welcome to IncuHub!";
  const [displayedLetters, setDisplayedLetters] = useState([]);



  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  //console.log(window.env_url)

  const getSettings = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await fetch(`${window.env_url}/v1/settings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setAdminDetails(data.body[0][0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  //console.log("Admin Details", adminDetails?.image)
  localStorage.setItem("adminImage", adminDetails?.image);
  localStorage.setItem("appname", adminDetails?.appname);
  const getFileUrl = `${window.env_url}/v1/files/`;

  useEffect(() => {
    getSettings();
  }, []);

  let rootDivClass;
  let leftContainer;
  if (width > 1024 && width <= 1920) {
    rootDivClass = "desktop-container";
    leftContainer = "desktop-left-section";
  } else {
    rootDivClass = "tab-container";
    leftContainer = "tablet-left-section";
  }

  //console.log(adminDetails)

  return (
    <div className="login-container overlay">
      {width <= 768 ? (
        <div className="left-section">
          <div className="right-contents">
            <img
              src={getFileUrl + adminDetails?.image}
              className="login-logo"
            />
            <h1 className="login-heading">{adminDetails?.appname}</h1>
            <span className="login-desc">{adminDetails?.description}</span>
            <div className="btn-section">
              <button
                className="loginbtns"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleOpenqb}
              >
                <img
                  src={quickbook}
                  alt="quickbook"
                  style={{ width: "unset", height: "unset" }}
                />
                <span>Quick Book</span>
              </button>
              <button
                className="loginbtns"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                <img
                  src={spaceShip}
                  alt="spaceship"
                  style={{ width: "unset", height: "unset" }}
                />
                <span>Get started</span>
              </button>
              <button
                className="loginbtns getStarted"
                style={{
                  cursor: "pointer",
                }}
                onClick={handleOpenReg}
              >
                <img
                  src={note}
                  alt="note"
                  style={{ width: "unset", height: "unset" }}
                />
                <span>Register</span>
              </button>
            </div>
            <div className="mobile-img-container">
              <img src={mobilehome} className="home-image" />
            </div>
          </div>
        </div>
      ) : (
        <div className={rootDivClass}>
          <div className="desktop-left-section">
            {/* <img src={Loginpic} className="login-picture" /> */}
            <div className="desktop-left-contents">
              <img
                src={adminDetails?.image ? getFileUrl + adminDetails?.image : IncuHubLogo}
                className="login-logo"
              />
              <h1 className="login-heading">{adminDetails?.appname || "IncuHub"}</h1>
              <span className="login-desc">{adminDetails?.description || "A comprehensive incubation management system designed to streamline and enhance the processes of incubation centers."}</span>
              <div className="btn-section">
                {/* <button
                  className="loginbtns"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenqb}
                  disabled
                >
                  Quick Book
                </button> */}
                <button
                  className="loginbtns getStarted"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpen}
                >
                  Get started
                </button>
                <button
                  className="loginbtns getStarted"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpenReg}
                >
                  Register
                </button>
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="desktop-right-contents">
              <AnimatedText
                text="Welcome to IncuHub!"
                delay={100}
                className="home-heading"
              />
              <span className="home-desc">
                A comprehensive incubation management system designed to
                streamline and enhance the processes of incubation centers.
              </span>
              <div className="img-container">
                <img src={HomePic} className="home-image" />
              </div>
            </div>
          </div>
        </div>
      )}
      {open && <UserLoginDialog open={open} handleClose={handleClose} />}
      {/* {openqb && <QbookingDialog open={openqb} handleClose={handleCloseqb} />} */}
      {openqb && <QuickBooking open={openqb} handleClose={handleCloseqb} />}
      {openReg && (
        <Register
          open={openReg}
          setOpen={setOpenReg}
          handleClose={handleCloseqb}
        />
      )}
    </div>
  );
};

export default LoginPage;
