import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./policy.css";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { ReactComponent as RightArrow } from "../../assets/svg/right_arrow.svg";
import { useMediaQuery } from "@mui/material";
import MNavbar from "../../components/navbar/mobile/MNavbar";
import CustomCloseIcon from "../../assets/svg/CustomCloseIcon";

// CustomDialog component definition
function CustomDialog({ visible, onHide, filePath, fileName }) {
  if (!visible) return null;

  const handleClose = () => {
    onHide(); // Call onHide function to close the dialog
  };

  return (
    <div className="custom-dialog-overlay">
      <div className="custom-dialog">
        <CustomCloseIcon fill={"#FF0000"} onClick={handleClose} />
        <div className="dialog-content">
          <DialogContent filePath={filePath} fileName={fileName} />{" "}
          {/* Pass filePath to DialogContent */}
        </div>
      </div>
    </div>
  );
}

const DialogContent = ({ filePath, fileName }) => {
  return (
    <div className="file_dialog_container">
      <span className="policy_name">{fileName}</span>
      {filePath.includes(".pdf") ? (
        <iframe id="fileFrame" src={filePath} />
      ) : (
        <img src={filePath} />
      )}
    </div>
  );
};

const Policy = () => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [fileName, setFileName] = useState("");
  const [filePath, setFilePath] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1023px)");
  const isLaptop = useMediaQuery("(min-width: 1024px) and (max-width: 1440px)");
  const isDesktop = useMediaQuery("(min-width: 1441px)");

  const PAGE_SIZE = isDesktop ? 8 : isLaptop ? 6 : 1;

  useEffect(() => {
    const getPolicies = async () => {
      try {
        const policiesRes = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/policy`
        );
        const availablePolicies = policiesRes?.data?.body?.filter(
          (policy) => policy.visibility
        );
        setPolicies(availablePolicies || []);
      } catch (error) {
        console.error("Error fetching policies:", error);
      }
    };
    getPolicies();
  }, []);

  const viewFile = (path) => {
    setFilePath(path);
    setConfirmVisible(true);
  };

  const hideDialog = () => {
    setConfirmVisible(false);
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  const clickCardHandler = (file) => {
    setFileName(file);
    viewFile(`${process.env.REACT_APP_API_URL}/v1/files/${file}`);
  };

  const isMobile = useMediaQuery("(max-width: 769px)");

  const ConditionalNavbar = () => (isMobile ? <MNavbar /> : <Navbar />);
  const ConditionalCardContent = () => {
    return isMobile ? (
      <>
        {policies.map((policy) => {
          const { id, policyName, policyVersion, updatedAt, policyFile } =
            policy;
          return (
            <div className="policy_card">
              <p className="policy_name">{policyName}</p>
              <div className="policy_details">
                <span className="version">Version</span>
                <span className="colon">:</span>
                <span className="value">{policyVersion}</span>
              </div>
              <div className="policy_details">
                <span className="updated">Updated On</span>
                <span className="colon">:</span>
                <span className="value">{formatDate(updatedAt)}</span>
              </div>
              <div className="policy_details">
                <span className="updated">Valid Until</span>
                <span className="colon">:</span>
                <span className="value">{formatDate(updatedAt)}</span>
              </div>
              <div
                className="viewFile"
                onClick={() => clickCardHandler(policyFile)}
              >
                <span>View</span>
                <RightArrow />
              </div>
            </div>
          );
        })}
      </>
    ) : (
      <div className="policyContainer">
        {policies.length > 0 ? (
          <>
            <div className={`policies ${isTablet ? "scrollable" : ""}`}>
              {policies
                ?.slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE)
                .map((policy) => {
                  const {
                    id,
                    policyName,
                    policyVersion,
                    updatedAt,
                    policyFile,
                    policyValidityDate,
                  } = policy;
                  return (
                    <div className="policy_card" key={id}>
                      <p className="policy_name">{policyName}</p>
                      <div className="policy_details">
                        <span className="version">Version</span>
                        <span className="colon">:</span>
                        <span className="value">{policyVersion}</span>
                      </div>
                      <div className="policy_details">
                        <span className="updated">Updated On</span>
                        <span className="colon">:</span>
                        <span className="value">{formatDate(updatedAt)}</span>
                      </div>
                      <div className="policy_details">
                        <span className="updated">Valid Until</span>
                        <span className="colon">:</span>
                        <span className="value">{formatDate(policyValidityDate)}</span>
                      </div>
                      <div
                        className="viewFile"
                        onClick={() => clickCardHandler(policyFile)}
                      >
                        <span>View</span>
                        <RightArrow />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="paginationNumbers paginationNumbers_policy">
              {Array(Math.ceil(policies.length / PAGE_SIZE))
                .fill("")
                .map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageClick(index + 1)}
                    className={pageNumber === index + 1 ? "active-btn" : ""}
                  >
                    {index + 1}
                  </button>
                ))}
            </div>
          </>
        ) : (
          <div className="no_policies">No policies available</div>
        )}
      </div>
    );
  };

  return (
    <div className="coaches-root">
      <ConditionalNavbar />
      <div className="mr-container">
        {!isMobile && <Sidebar />}
        <ConditionalCardContent />

        {/* Render CustomDialog component */}
        <CustomDialog
          visible={confirmVisible}
          onHide={hideDialog}
          filePath={filePath}
          fileName={fileName}
        />
      </div>
    </div>
  );
};

export default Policy;
