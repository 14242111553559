import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import axios from "axios";
import "./CoachRequests.css";
import { Paginator } from "primereact/paginator";

const CoachRequests = () => {
  const [requestsData, setRequestsData] = useState([]);

  const fetchSingleCoachBookings = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/coachbookings/byCoach`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRequestsData(response.data.body[0] || []);
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    fetchSingleCoachBookings();
  }, []);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const paginatedData = requestsData?.slice(first, first + rows);

  function getStatusClassName(visibility) {
    switch (visibility) {
      case "accepted":
        return "acceptedStatus";
      case "rejected":
        return "rejectedStatus";
      default:
        return "pendingStatus";
    }
  }

  return (
    <div className="mr mr-root">
      <Navbar />
      <div className="mr-container">
        <Sidebar />
        <div className="mr-right-section coach-requests">
          <h2 className="coachReq header">Coach Requests</h2>
          {requestsData?.length > 0 ? (
            <>
            <div className="coachReqTableContainer">
              <table
                className="bookingRequestTable coachRequests"
                id="bookingDetailsId"
              >
                <thead>
                  <tr>
                    <th>Customer Name</th>
                    <th>Date</th>
                    <th>Email ID</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>More Information</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((request) => {
                    return (
                      <tr key={request?.id}>
                        <td>
                          {request?.user
                            ? `${request?.user.firstname} ${request?.user.lastname}`
                            : "Loading..."}
                        </td>
                        <td>{request?.checkInDate}</td>
                        <td>{request?.coach?.email}</td>
                        <td>{request?.checkInTime}</td>
                        <td>
                          <button
                            className={getStatusClassName(request?.visibility)}
                          >
                            {" "}
                            {request?.visibility === "accepted"
                              ? "Accepted"
                              : request?.visibility === "rejected"
                              ? "Rejected"
                              : "Pending"}
                          </button>
                        </td>
                        <td>{request?.notes || "N/A"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              
            </div>
            <Paginator
            first={first}
            rows={rows}
            totalRecords={requestsData?.length}
            // rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
          </>
          ) : (
            <div className="coachReq noShow">No Bookings requests found!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoachRequests;
