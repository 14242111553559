import React, { useEffect, useState } from "react";
import "./styles.css";

const AnimatedText = ({ text, delay, className }) => {
  const [animatedText, setAnimatedText] = useState([]);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const letters = text.split("");
    const animations = letters.map((char, index) => {
      return (
        <span
          key={index}
          className="letter"
          style={{ animationDelay: `${index * delay}ms` }}
        >
          {char === " " ? "\u00A0" : char}
        </span>
      );
    });
    setAnimatedText(animations);

    // Set interval to reset the animation
    const totalAnimationTime = letters.length * delay + 500; // Adding extra delay to ensure the last animation completes
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1);
    }, totalAnimationTime);

    return () => clearInterval(interval);
  }, [text, delay]);

  return <div className={className} key={key}>{animatedText}</div>;
};

export default AnimatedText;
