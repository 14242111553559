import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import "./QbookingDialog3.css";
import { ReactComponent as UserIcon } from "../assets/svg/UserIcon.svg";
import { ReactComponent as Dropdown } from "../assets/svg/Dropdown.svg";
import { ReactComponent as QbLevel3 } from "../assets/svg/QbLevel3.svg";
import { ReactComponent as RespDropdown } from "../assets/svg/RespDropdown.svg";
import TStatusBar3 from "../assets/png/TabStatus3.png";

import Logo from "../assets/png/Logo.png";
import QbStatus1 from "../assets/png/QbStatus1.png";
import QbStatus2 from "../assets/png/QbStatus2.png";
import QbStatus3 from "../assets/png/QbStatus3.png";
import QbImage1 from "../assets/png/QbImage1.png";
import QbImage2 from "../assets/png/QbImage2.png";
import QbImage3 from "../assets/png/QbImage3.png";
import Underline from "../assets/png/Line.png";
import { fetchAllData } from "../utils/fetchData";
import { useQuery } from "react-query";
import DurationPicker from 'react-duration-picker'
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay-ts';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ToastContainer from "rsuite/esm/toaster/ToastContainer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomCloseIcon from "../assets/svg/CustomCloseIcon";

const apiURL = process.env.REACT_APP_API_URL;

const desktopStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "1321px",
  height: "727px",
};

const laptopStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "89vw",
  height: "50.5vw",
};

const tabletStyle = {
  width: "87%",
  height: "90%",
  bgcolor: "background.paper",
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "20px",
  transform: "translate(-50%, -50%)",
  position: "relative",
  overflow:"scroll"
};
function shouldBeShown(){
  return true
}

const styles = {
  option: (base, value) => {
      return (shouldBeShown(value) ? { ...base } : { display: 'none'});
  }
};

function QbookingDialog3({ open, handleClose, goBack,dialog2FormData,formData,setFormData }) {
  const [passOpen, setPassOpen] = useState(false);
  const handlePassOpen = () => setPassOpen(true);
  const closePassOpen = () => setPassOpen(false);
  const [passwordType, setPasswordType] = useState("password");
  const [errors, setErrors] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const filterFacilitiesEndPoint = '/v1/filterfacilities'
  const { data, isLoading } = useQuery(['filterfacilities',filterFacilitiesEndPoint],()=>fetchAllData(filterFacilitiesEndPoint))
  const format = 'HH:mm';
  const [duration, setDuration] = useState([15,"15 minutes"]);
  const [loaderAnimation, setLoaderAnimation] = useState(false);
  const animatedComponents = makeAnimated()
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]


  // const [formData, setFormData] = useState({
  //   seats: "No.of seats",
  //   facilities: "Select Facility",
  //   notes: "",
  // });
  console.log(formData)
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown visibility
  };

  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2); // Toggle the dropdown visibility
  };

  const seatCount = formData.meetingRoomDetails.seats; // Dropdown options


  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  let appliedStyle;

  if (width >= 1024 && width <= 1440) {
    appliedStyle = laptopStyle;
  } else if (width > 768 && width <= 1023) {
    appliedStyle = tabletStyle;
  } else {
    appliedStyle = desktopStyle;
  }

  const handleSeatCountChange = (selectedSeatCount) => {
    setFormData({...formData, setUpDetails:{...formData.setUpDetails,["seats"]: selectedSeatCount}});
    setShowDropdown(false); // Close the dropdown when an option is selected
  };

  const handleFacilitiesChange = (selectedFacilities) => {
    console.log("facilities",selectedFacilities)
    const facilityArray = selectedFacilities.map((facility)=>facility.value)
    setFormData({...formData, setUpDetails:{...formData.setUpDetails,["facilities"]: facilityArray.join("|")}});
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTrigger(value)
    console.log(value)
    setFormData({...formData, setUpDetails:{...formData.setUpDetails,[name]: value}});
    setErrors({ ...errors, [name]: "" });
    // if (name === "date") {
    //   const pattern = /^\d{2}\/\d{2}\/\d{4}$/;
    //   console.log(pattern.test(formData.setUpDetails.date));
    //   if (pattern.test(formData.meetingRoomDetails.date)) {
    //     console.log("Valid date format");
    //   } else {
    //     alert("Invalid date format");
    //   }
    // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform your validation here
    const newErrors = {};
    if (formData.setUpDetails.seats === "Select Room Type") {
      newErrors.seats = "Room Type is required";
    }
    if (formData.setUpDetails.time.trim() === "") {
      newErrors.time = "Time is required";
    }

    setErrors(newErrors);

    // If there are no errors, submit the form
    if (Object.keys(newErrors).length === 0) {
      setFormData(formData);
      window.location.href = "/l3";
    }
  };

  function limitCharacter(element, maxLength) {
    
    if (String(element.target.value.length)> maxLength) {
      element.target.value = Number(String(element.target.value).slice(0, maxLength));
    }

    if(element.target.value>23 && element.target.name === "hours") {
      element.target.value = 23;
    }

    if(element.target.value>59 && element.target.name === "minutes") {
      element.target.value = 59;
    }
    console.log(element.target.value)
  }

  async function handleBook(){

    const temp = formData.setUpDetails;

    if(!(temp.date) || !(temp.description) || !(temp.facilities) || !(temp.duration) || !(temp.time) || !(temp.facilities)){
          toast.error("Please fill all the fields");
          return;
        }
        window.scrollTo(0, 0);
        setLoaderAnimation(true)
        console.log(!(temp.facilities))

        const setUpDetails = formData.setUpDetails;
        const requestFormData = new FormData();
    
        requestFormData.append("checkInDate", setUpDetails.date);
        requestFormData.append("checkInTime", setUpDetails.time);
        requestFormData.append("checkOutDate", setUpDetails.date);   
        requestFormData.append("checkOutTime", setUpDetails.checkoutTime);
        requestFormData.append("facilities", setUpDetails.facilities);
        requestFormData.append("duration",duration[0]);
        requestFormData.append("notes",setUpDetails.description);

    
        try{
          const token = localStorage.getItem("quickBookToken");
          await axios.post(`${apiURL}/v1/meetingbookings/book/${formData.meetingRoomDetails.id}`,requestFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            }
          }).then((response)=>{
            setLoaderAnimation(false)
            toast.success("booking created");
            console.log(response)
            window.location.reload();
          })
        }catch(error){
          setLoaderAnimation(false)
          console.log(error.response.data.message)
          toast.error(error.response.data.message)
          return
        }
    }


  function handleDurationCal(){
    if(duration<59){
      setDuration(duration+" minutes")
    }
  }

  function durationIncrement(event){

    event.preventDefault();
    if((duration[0]+15)>1440){
      toast.error("cannot book beyond 24 hours")
      return
    }

    if(duration[0]>59){

      if(!(((duration[0]+15)/60).toString().includes("."))){
        setDuration((prevState)=>[prevState[0]+15,`${(prevState[0]+15)/60} hours`])
      }

      else{
        const  [hours, minutes] = ((duration[0]+15)/60).toString().split(".");
        // const [hour, minutes] = hours.toString().split(".");

        const minArray = {25:15, 5:30, 75:45}
        setDuration((prevState)=>[prevState[0]+15,`${hours} hours ${minArray[minutes]} minutes`])
      }
    }

    else{
      
      if(duration[0]+15 > 59){

        setDuration((prevState)=>[prevState[0]+15,`1 hour`])
      }

      else{
        setDuration((prevState)=>[prevState[0]+15,`${prevState[0]+15} minutes`])
      }
    }

    
  }


  function durationDecrement(event){

    event.preventDefault();
    if((duration[0]-15)<15){
      toast.error("cannot book below 15 minutes")
      return
    }

    if(duration[0]>59){
      console.log(((duration[0]/60).toString().includes(".")))
      if(!(((duration[0]-15)/60).toString().includes("."))){
        setDuration((prevState)=>[prevState[0]-15,`${(prevState[0]-15)/60} hours`])
      }

      else{
        const  [hours, minutes] = ((duration[0]-15)/60).toString().split(".");
        // const [hour, minutes] = hours.toString().split(".");
        console.log(((duration[0]-15)/60).toString().split("."))
        const minArray = {25:15, 5:30, 75:45}
        setDuration((prevState)=>[prevState[0]-15,hours==0 ? `${minArray[minutes]} minutes`: hours+" hours "+`${minArray[minutes]} minutes`])
      }
    }

    else{
      
      if(duration[0]-15 > 59){
        console.log("it comes in here")
        setDuration((prevState)=>[prevState[0]-15,`1 hour`])
      }

      else{
        setDuration((prevState)=>[prevState[0]-15,`${prevState[0]-15} minutes`])
      }
    }
  }

  useEffect(()=>{

    if(formData.setUpDetails.time){
      const [hours, minutes] = formData.setUpDetails.time.split(":");
      const [dHours, dMinutes] = duration[1].split(" ").length === 2 ? [0,duration[1].split(" ")] : [duration[1].split(" ")[0], duration[1].split(" ")[2]]
      const [combinedHours, combinedMinutes] = [Number(hours)+Number(dHours), Number(minutes)+Number(dMinutes)];
      console.log("combinedHours" ,duration,combinedHours);
      if(combinedMinutes){
        
        const [extraHours, extraMinutes] = [Math.floor(combinedHours/60), combinedMinutes%60]
        console.log("extraHours",extraHours, extraMinutes)
        
        if(combinedHours>23){
          
          setFormData({...formData,setUpDetails:{...formData.setUpDetails, 
                      checkoutTime:`${(combinedHours-24)+(Number(extraHours))}:${extraMinutes}:00`,duration:duration[1]}})
        }
  
        else{
          setFormData({...formData,setUpDetails:{...formData.setUpDetails, 
            checkoutTime:`${(combinedHours)}:${extraMinutes}:00`,duration:duration[1]}})
        }
      }

      else{

        const [extraHours, extraMinutes] = [Math.floor(combinedHours/60), (Number(dMinutes[0])+Number(minutes))%60]
        console.log("combined minutes",Number(dMinutes[0]),Number(minutes))
        console.log("dHours == 0",dHours)

        if(dHours == 0 ){
          console.log("it comes here 1")
          setFormData({...formData,setUpDetails:{...formData.setUpDetails, 
            checkoutTime:`${(combinedHours)+(Number(extraHours))}:${extraMinutes}:00`,duration:duration[1]}})
            return
        }

        if(combinedHours>23){
          
          setFormData({...formData,setUpDetails:{...formData.setUpDetails, 
                      checkoutTime:`${(combinedHours-24)+(Number(extraHours))}:${minutes}:00`,duration:duration[1]}})
        }
  
        else{
          setFormData({...formData,setUpDetails:{...formData.setUpDetails, 
            checkoutTime:`${(combinedHours)+(Number(extraHours))}:${minutes}:00`,duration:duration[1]}})
        }
      }
    }

  },[duration,trigger])

  return (
    <>
      {width <= 768 ? (
        <div className="qbReContainer">
            <LoadingOverlay
              active={loaderAnimation}
              spinner
              text='Loading...'
            ></LoadingOverlay>
          <div className="qb3-top-section">
            <img src={Logo} className="imgReLogo" />
            <p className="qb-heading1">Quick Booking</p>
            <span className="qb-desc">Follow the below steps and get quick response</span>
            <div className="svgicon">
              <QbLevel3 />
            </div>
            <div className="status">
              <p>Basic Details</p>
              <p>Meeting Room Details</p>
              <p>Set up details</p>
            </div>
          </div>
          <div className="qb-bottom-section">
            <h1>Set Up Details</h1>
            <div className="qb-underline">
              <img src={Underline} />
            </div>
            <div className="form">
              <div className="fields-container">
                
              <div className="label-input-container">
                <div className="qb-field field2">
                  <label className="qb3-label">Date</label>
                    <div className="input-container">
                      <input
                        type="date"
                        id="hidden-date-input"
                        name="date"
                        value={formData.setUpDetails?.date}
                        onChange={handleInputChange}
                        className="login-date"
                        requiredmeetingRoomDetails
                      />
                  </div>
                
                </div>
              </div>

                <div className="label-input-container">
                  <label className="qb3-label">Time</label>
                      <div className="input-container">
                        <input
                          type="time"
                          id="time"
                          value={formData.setUpDetails.time}
                          name="time"
                          className="login-time"
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                  {console.log("facilities here",formData.setUpDetails.facilities)}
                  {showDropdown && (
                    <div className="dropdown-options">
                      {seatCount.map((number) => (
                        <div key={number} onClick={() => handleSeatCountChange(number)}>
                          {number}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                <div className="label-input-container extra">
                  <label className="qb3-label">Facilities</label>
                  <div className="facilities-dropdown">
                      <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={formData.meetingRoomDetails.facilities.split('|').map((facility)=>{return {value: facility, label: facility}})}
                      onChange={handleFacilitiesChange}
                      name="roomFacilities"
                      // menuPortalTarget={document.body} 
                      // styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      classNames={{
                        container: (state) => 'react-select-container'
                      }}
                      required
                      /> 
                    </div>
                </div>
                
              </div>

              <div className="label-input-container">
              <label className="qb3-label">Duration</label>
                {/* <div className="selectHoursMinutes">
                    <input
                      className="input-container"
                      type="number" 
                      name="hours" 
                      placeholder="Enter Hours" 
                      // pattern="^(?:[0-1]?[0-9]|2[0-3])$"
                      onChange={handleInputChange}
                      onInput={(event)=>limitCharacter(event,2)}
                    />
                    <input
                      className="input-container" 
                      type="number" 
                      name="minutes" 
                      placeholder="Enter minutes" 
                      pattern="^(?:[0-5]?[0-9])$" 
                      onChange={handleInputChange}
                      maxlength='2'
                      onInput={(event)=>limitCharacter(event, 2)}
                    />
                </div> */}
                <input  className="input-container" id="ticketNum" type="text" name="ticketNum" list="defaultNumbers"  value={duration[1]}/>
                <div className="durationBtnWrapper">
                  <button type="button" onClick={durationIncrement}>∧</button><button type="button" onClick={durationDecrement}>∨</button>
                </div>
              </div>


              <div className="text-area-container">
                <label className="qb3-label">description</label>
                <textarea
                  className="qb2 textarea"
                  value={formData.setUpDetails.details}
                  name="description"
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="btn-container">
              <button className="back-btn" onClick={() => goBack()}>
                Back
              </button>
              <button
                className="next-btn"
                //  onClick={handleRespSubmit}
                onClick={handleBook}
              >
                Book
              </button>
            </div>
          </div>
        </div>
      ) : width <= 1024 ? (
        <Modal
          open={true}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
           <>
           <LoadingOverlay
              active={loaderAnimation}
              spinner
              text='Loading...'
            ></LoadingOverlay>
          <Box sx={appliedStyle}>
            <div className="tab-container">
              <CustomCloseIcon fill="#FF0000" onClick={() => (window.location.href = "/")} />
              <div className="qb2-top-section">
                {/* <div className="blackfilter"></div> */}
                <img src={Logo} className="imgReLogo" />
                <p className="qb-heading1">Quick Booking</p>
                <span className="qb-desc">Follow the below steps and get quick response</span>
                <div className="svgicon">
                  <img src={TStatusBar3} />
                </div>
                <div className="status">
                  <p>Basic Details</p>
                  <p>Meeting Room Details</p>
                  <p>Set up details</p>
                </div>
              </div>
              <div className="qb-bottom-section">
                <h1>Set Up Details</h1>
                <div className="qb-underline">
                  <img src={Underline} />
                </div>
                <div className="qb3 form">
                  <div className="qb3 fields-container">

                    <div className="label-input-container">

                    <div className="qb-field field4">
                          <label className="qb-label">Date</label>
                          <div className="input-container">
                            <input
                              type="date"
                              id="hidden-date-input"
                              name="date"
                              value={formData.setUpDetails?.date}
                              onChange={handleInputChange}
                              className="login-date"
                              requiredmeetingRoomDetails
                            />
                        </div>
                        
                    </div>

                    <div className="qb-field field4">
                      <label className="qb3-label">Duration</label>
                      <div className="qb2 input-container">
                        <div className="durationFieldWrapper">
                          <input id="ticketNum" type="text" name="duration" list="defaultNumbers"  value={duration[1]}/>
                          <div className="durationBtnWrapper">
                            <button onClick={durationIncrement}>∧</button><button onClick={durationDecrement}>∨</button>
                          </div>
                        </div>
                    </div>
                      
                        {/* <RespDropdown id="rtDropDown" /> */}
                        {/* <div className="qb-field field4">
                          <label className="qb-label">Time</label>
                          <div className="input-container">
                            <input
                              type="time"
                              id="time"
                              value={formData.setUpDetails.time}
                              name="time"
                              className="login-time"
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div> */}
                      </div>
                      
                      {console.log("facilities here",formData.setUpDetails.facilities)}
                      {showDropdown && (
                        <div className="dropdown-options">
                          {seatCount.map((number) => (
                            <div key={number} onClick={() => handleSeatCountChange(number)}>
                              {number}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    
                    <div className="label-input-container">
                    <div className="qb-field field2">
                          <label className="qb-label">Time</label>
                          <div className="input-container">
                            <input
                              type="time"
                              id="time"
                              value={formData.setUpDetails.time}
                              name="time"
                              className="login-time"
                              onChange={handleInputChange}
                              required
                            />
                          </div>

                        
                    </div>
                      <div className="qb-field field2 extra">
                      <label className="qb3-label">Facilities</label>
                      {/* <div className="qb2 input-container">
                        <input
                          type="text"
                          id="facilities"
                          readOnly
                          className="facilities input-field"
                          value={formData.setUpDetails.facilities}
                          onClick={toggleDropdown2}
                        />
                        <RespDropdown id="rtDropDown" />
                      </div>
                      {console.log("facilities here",formData.setUpDetails.facilities)} */}
                      {/* {showDropdown2 && (
                        
                        <div className="dropdown-options">
                          {formData.meetingRoomDetails.facilities.split('|').map((facility) => (
                            <div key={facility} onClick={() => handleFacilitiesChange(facility)}>
                              {facility}
                            </div>
                          ))}
                        </div>
                      )} */}
                          <div className="facilities-dropdown">
                              <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={formData.meetingRoomDetails.facilities.split('|').map((facility)=>{return {value: facility, label: facility}})}
                              onChange={handleFacilitiesChange}
                              name="facilities"
                              // menuPortalTarget={document.body} 
                              // styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              classNames={{
                                container: (state) => 'react-select-container'
                              }}
                              required
                              />                              
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="text-area-container">
                    <label className="qb3-label">description</label>
                    <textarea
                      className="qb3 textarea"
                      value={formData.setUpDetails.details}
                      name="description"
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
                <div className="qb3 btn-container">
                  <button className="back-btn" onClick={() => goBack()}>
                    Back
                  </button>
                  <button
                    className="next-btn"
                    //  onClick={handleRespSubmit}
                    onClick={handleBook}
                  >
                    Book
                  </button>
                </div>
              </div>
            </div>
          </Box>
         </>
        </Modal>
      ) : (
        <Modal
          open={true}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
              <LoadingOverlay
              active={loaderAnimation}
              spinner
              text='Loading...'
            ></LoadingOverlay>
          <Box sx={width >= 1440 ? desktopStyle : laptopStyle}>

            <div className={width >= 1441 ? "qb-container" : "qb-laptop-container"}>
            <CustomCloseIcon fill="#FF0000" onClick={() => (window.location.href = "/")} />
              <div className="qb3-left-section">
                <img src={Logo} className="qb3-logo" />
                <h1 className="qb3-heading1">Quick Booking</h1>
                <span>
                  Follow the below steps and get <br /> quick response
                </span>
                <div className="status-details">
                  <img src={QbStatus3} />
                  <div className="details">
                    <span>Basic details</span>
                    <span>Meeting Room Details</span>
                    <span>Set Up Details</span>
                  </div>
                </div>
                <img src={QbImage3} className="qb3-image2" />
              </div>
              <div className="qb3-right-section">
                <div className="qb3 form-section">
                  <h1 className="qb3-heading2">Set Up Details</h1>
                  <img style={width >= 1441 ? { width: "311px" } : { width: "19vw" }} src={Underline} />
                  <form>
                    <div className="qb3-form">
                      <div className="fieldRow">
                        <div className="qb-column1">
                          <div className="field1">
                          <label className="qb-label">Date</label>
                          <div className="input-container">
                          <input
                              type="date"
                              id="hidden-date-input"
                              name="date"
                              value={formData.setUpDetails?.date}
                              onChange={handleInputChange}
                              className="login-date"
                              requiredmeetingRoomDetails
                            />
                        </div>
                                

                                {/* <datalist id="defaultNumbers">
                                  <option value="15 minutes"></option>
                                  <option value="1 hour 15 minutes"></option>
                                  <option value="2 hours"></option>
                                  <option value="3 hours"></option>
                                  <option value="4 hours"></option>
                                </datalist>  */}
                              


                            <span className="error">{errors.seats}</span>
                          </div>
                          <div className="qb-field field4">
                          {/* <label className="qb-label">Time</label>
                          <div className="input-container">
                            <input
                              type="time"
                              id="time"
                              value={formData.time}
                              name="time"
                              className="login-time"
                              onChange={handleInputChange}
                              required
                            />
                          </div> */}
                          
                          <label className="qb-label">Duration</label>
                          <div className="durationFieldWrapper">
                            <input id="ticketNum" type="text" name="ticketNum" list="defaultNumbers"  value={duration[1]}/>
                            <div className="durationBtnWrapper">
                              <button onClick={(event)=>durationIncrement(event)}>∧</button><button onClick={(event)=>durationDecrement(event)}>∨</button>
                            </div>
                          </div>
                        </div>
                        </div>
                    <div className="qb-column2">
                      <div className="qb-field field2">
                          {/* <label className="qb-label">Date</label>
                          <div className="input-container">
                            <input
                              type="date"
                              id="hidden-date-input"
                              name="date"
                              value={formData.setUpDetails?.date}
                              onChange={handleInputChange}
                              className="login-date"
                              requiredmeetingRoomDetails
                            />
                        </div> */}

                        <label className="qb-label">Time</label>
                          <div className="input-container">
                            <input
                              type="time"
                              id="time"
                              value={formData.setUpDetails.time}
                              name="time"
                              className="login-time"
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        
                        </div>
                          <div className="field2">
                            <label className="qb-label">Facilities</label>
                            {/* <div className="dropdown-container2">
                              <div className="selected-room-type" onClick={toggleDropdown2}>
                                {formData.setUpDetails.facilities}
                              </div>
                              <div className="qb3 dropdown-icon-container2" onClick={toggleDropdown2}>
                                <Dropdown />
                              </div>
                              {showDropdown2 && (
                                <div className="dropdown-options">
                                  {console.log("facilities",formData.setUpDetails.facilities)}
                                  {formData.meetingRoomDetails.facilities.split('|').map((facility) => (
                                    <div key={facility} onClick={() => handleFacilitiesChange(facility)}>
                                      {facility}
                                    </div>
                                  ))}
                                </div>
                              )}
                                  </div> */}
                            <div className="facilities-dropdown">
                              <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={formData.meetingRoomDetails.facilities.split('|').map((facility)=>{return {value: facility, label: facility}})}
                              onChange={handleFacilitiesChange}
                              name="roomFacilities"
                              // menuPortalTarget={document.body} 
                              // styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              classNames={{
                                container: (state) => 'react-select-container'
                              }}
                              styles={styles}
                              required
                            />                              
                          </div>
                            <span className="error">{errors.facilities}</span>
                          </div>
                        </div>
                      </div>
                      <div className="detailsField">
                        <label className="qb-label">Description</label>
                        <textarea className="qb3-textarea" name="description" onChange={handleInputChange}></textarea>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="qb3-btn-root">
                  <div className="qb3-btn-container">
                    <button className="qb3-action-btn" onClick={() => goBack()}>
                      Back
                    </button>
                  </div>
                  <div className="qb3-btn-container">
                    <button className="qb3-action-btn next" onClick={handleBook}>Book</button>
                  </div>
                </div>
              </div>
            </div>
          </Box>
          </>
        </Modal>
      )}
    </>
  );
}

export default QbookingDialog3;
