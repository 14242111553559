import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

const ChatApp = ({ onClose }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1023px)");
  const isLaptop = useMediaQuery("(max-width: 1440px)");
  const isDesktop = useMediaQuery("(max-width: 1920px)");

  const mobileStyle = {
    width: "100%",
    height: "98%",
  };

  const desktopStyle = {
    width: "80%",
    height: "86%",
  };

  const [style, setStyle] = useState(desktopStyle);

  useEffect(() => {
    if (isMobile) {
      setStyle(mobileStyle);
    } else {
      setStyle(desktopStyle);
    }
  }, [isMobile]);

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: "1000",
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "#fff",
          color: "#66a3a6",
          border: "none",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <iframe
        src={process.env.REACT_APP_CHAT_URL}
        // src="http://localhost:3001/"
        title="Chat App"
        style={{
          ...style,
          margin: "auto",
          display: "block",
          backgroundColor: "#fff",
          borderRadius: "10px",
        }}
      />
    </div>
  );
};

export default ChatApp;
