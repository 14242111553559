import React, { createContext, useState, useContext } from "react";

export const MeetingFilterContext = createContext({
  credits: false,
  ratings: false,
  facility: false,
  skills: false, 
  updateCreditsFilter: () => {},
  updateRatingsFilter: () => {},
  updateFacilityFilter: () => {},
  updateSkillsFilter : () => {},
});

const MeetingFilterContextProvider = ({ children }) => {
  const [meetingFilter, setMeetingFilter] = useState({
    credits: false,
    ratings: false,
    facility: false,
    skills: false,
  });

  const updateCreditsFilter = (value) => {
    setMeetingFilter((prevValue) => {
      return { ...prevValue, credits: value };
    });
  };


  const updateSkillsFilter = (value) => {
    setMeetingFilter((prevValue) => {
      return{...prevValue, skills : value}
    })
  }

  const updateRatingsFilter = (value) => {
    setMeetingFilter((prevValue) => {
      return { ...prevValue, ratings: value };
    });
  };

  const updateFacilityFilter = (value) => {
    setMeetingFilter((prevValue) => {
      return { ...prevValue, facility: value };
    });
  };

  return (
    <MeetingFilterContext.Provider
      value={{
        ...meetingFilter,
        updateCreditsFilter,
        updateFacilityFilter,
        updateRatingsFilter,
        updateSkillsFilter,
      }}
    >
      {children}
    </MeetingFilterContext.Provider>
  );
};

export const useMeetingFilterContext = () => {
  return useContext(MeetingFilterContext);
};

export default MeetingFilterContextProvider;
