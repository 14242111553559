import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./events.css";
import { Dialog } from "primereact/dialog";
import { token } from "../../utils/utilityFunctions";
import axios from "axios";
import EventDialog from "./EventDialog";
import { ReactComponent as RightArrow } from "../../assets/svg/right_arrow.svg";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import MNavbar from "../../components/navbar/mobile/MNavbar";
import CustomCloseIcon from "../../assets/svg/CustomCloseIcon";

const Events = () => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventImage, setEventImage] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const userNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const guestsRef = useRef(null);
  const [error, setError] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(-1);
  const [eventBookings, setEventBookings] = useState([]);
  const [booking, setBooking] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState();

  const LAPTOP_SCREEN_SIZE = 4;
  const LARGE_SCREEN_SIZE = 8;

  const SCREEN_SIZE = window.innerWidth;

  const PAGE_SIZE =
    SCREEN_SIZE >= 1024 && SCREEN_SIZE <= 1440
      ? LAPTOP_SCREEN_SIZE
      : LARGE_SCREEN_SIZE;

  const handlePageClick = (page) => {
    setPageNumber(page);
  };
  const getEvents = async () => {
    try {
      const eventsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/events`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      //(earliest to latest)
      const sortedEvents = eventsResponse?.data?.body[0].sort((a, b) => {
        return new Date(a.eventDate) - new Date(b.eventDate);
      });

      setEvents(sortedEvents);

      const eventBookingsResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/eventbookings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEventBookings(eventBookingsResponse.data.body[0]);
    } catch (error) {
      setError("Failed to fetch events. Please try again later.");
    }
  };

  useEffect(() => {
    (async () => {
      await getEvents();
    })();
  }, []);

  useEffect(() => {
    if (selectedEventId !== -1) {
      const currentBooking = eventBookings.find(
        (booking) => booking?.event_id === selectedEventId
      );
      setBooking(currentBooking);
    }
  }, [selectedEventId, eventBookings]);

  const cardClickHandler = (_event) => {
    setConfirmVisible(true);
    setEventImage(_event?.eventImage);
    setSelectedEventId(_event?.id);
    setSelectedEvent(_event);
  };

  const hideDialog = () => {
    setConfirmVisible(false);
    setQrCodeData(null);
  };

  const eventBookingHandler = async (e) => {
    e.preventDefault();

    // Mobile number validation
    const phoneNumber = phoneNumberRef.current.value;
    const phoneRegex = /^[0-9]{10}$/;

    if (!phoneRegex.test(phoneNumber)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    try {
      const formDetails = {
        userName: userNameRef.current.value,
        email: emailRef.current.value,
        phoneNumber,
        guests: guestsRef.current.value,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/eventBookings/${selectedEventId}`,
        formDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        await getEvents();
        setQrCodeData(response.data.bookingDetails);
        hideDialog();
      }
    } catch (error) {
      console.log("response", error?.response?.data?.message);
      alert(error?.response?.data?.message);
    }
  };

  const DialogContent = () => {
    return (
      <div className="dialog_container">
        <div className="events_image_container">
          <img
            src={`${process.env.REACT_APP_API_URL}/v1/files/${eventImage}`}
            alt="events"
          />
          <CustomCloseIcon fill="#FF0000" onClick={hideDialog} />
          <span className="dialog_event_name">{selectedEvent?.eventName}</span>
        </div>
        <form className="form_input_fields" onSubmit={eventBookingHandler}>
          <input
            type="text"
            className="event_form_input_field"
            name="userName"
            placeholder="Enter your Name"
            required
            ref={userNameRef}
          />
          <input
            className="event_form_input_field"
            type="email"
            placeholder="Enter your email"
            required
            ref={emailRef}
          />
          <input
            className="event_form_input_field"
            type="number"
            placeholder="No of Guests"
            required
            ref={guestsRef}
          />
          <input
            className="event_form_input_field"
            type="tel"
            placeholder="Mobile Number"
            ref={phoneNumberRef}
          />
          <div>
            <button className="event_publish_button" type="submit">
              Reserve
            </button>
          </div>
        </form>
      </div>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = days[date.getUTCDay()];
    const dayOfMonth = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}, ${dayOfMonth} ${month} ${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const BookingDetails = () => {
    return (
      <div className="dialog_container">
        <div className="events_image_container">
          <img
            src={`${process.env.REACT_APP_API_URL}/v1/files/${eventImage}`}
            alt="events"
          />
        </div>
        <div className="booked_details_container">
          <div className="booked_details">
            <div className="booking_confirmed">
              <span>Booking Confirmed</span>
              <img src="./confirmed.svg" />
            </div>
            <div className="event_booked_name">
              {booking?.events?.eventName}
            </div>
            <div className="event_booked_date">
              {formatDate(booking?.events?.eventDate)}
            </div>
            <div className="event_booked_time">
              <div>{formatTime(booking?.events?.eventStartTime)}</div>
              <div>To</div>
              <div>{formatTime(booking?.events?.eventEndTime)}</div>
            </div>
          </div>
          <div className="qr_code">
            <img src={booking?.events.eventQRCode} alt="booking_qr_code" />
          </div>
        </div>
      </div>
    );
  };

  const isMobile = useMediaQuery("(max-width: 769px)");

  const ConditionalNavbar = () => (isMobile ? <MNavbar /> : <Navbar />);

  console.log("events", events.length, PAGE_SIZE)
  return (
    <div className="coaches-root">
      <ConditionalNavbar />
      <div className="mr-container">
        {!isMobile && <Sidebar />}
        <div className="events-pagination">
          <div className="events">
            <div className="event_cards_container">
              {events.length > 0 ? (
                events
                  ?.slice((pageNumber - 1) * PAGE_SIZE, pageNumber * PAGE_SIZE)
                  .map((_event) => {
                    const {
                      id,
                      eventName,
                      eventImage,
                      eventDate,
                      eventStartTime,
                      eventEndTime,
                      eventCapacity,
                    } = _event;
                    return (
                      <div className="event_card" key={id}>
                        <div className="event_image_container">
                          <img
                            className="event_image"
                            src={`${process.env.REACT_APP_API_URL}/v1/files/${eventImage}`}
                            alt="event_image"
                          />
                          {/* <div className="image_overlay"></div> */}
                        </div>
                        <div className="event_card_details">
                          <div className="card_event_name">
                            <span>{eventName}</span>
                          </div>
                          <div className="card_event_date">
                            Event On: <span>{formatDate(eventDate)}</span>
                          </div>
                          <div className="card_event_time">
                            Event Timings:{" "}
                            <span>
                              {formatTime(eventStartTime)} To{" "}
                              {formatTime(eventEndTime)}
                            </span>
                          </div>
                          <div className="card_event_capacity">
                            Capacity:<span> {eventCapacity}</span>
                          </div>
                        </div>
                        <div
                          className="card_view"
                          onClick={() => cardClickHandler(_event)}
                        >
                          <span>View</span>
                          <RightArrow />
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="no_events">No Events Available</div>
              )}
            </div>
          </div>

          <div className="paginationNumbers user">
            {Array(Math.ceil(events.length / PAGE_SIZE))
              .fill("")
              .map((_, index) => (
                <button
                  onClick={() => handlePageClick(index + 1)}
                  className={pageNumber === index + 1 ? "active" : ""}
                  key={index}
                >
                  {index + 1}
                </button>
              ))}
          </div>
        </div>
      </div>
      <EventDialog
        isOpen={confirmVisible}
        onClose={hideDialog}
        children={booking ? <BookingDetails /> : <DialogContent />}
      />
    </div>
  );
};

export default Events;
