import React from 'react';
import { ReactComponent as SurveyIcon } from "../../../assets/svg/Survey.svg";
import './SurveyCard.css';

const SurveyCard = ({ formName, createdAt, inputs }) => {
  return (
    <div className="survey-card">
      <div className="survey-card-icon">
        <SurveyIcon />
      </div>
      <div className="survey-card-content">
        <h3 className="survey-card-title">{formName}</h3>
        <p className="survey-card-date">Created on: {new Date(createdAt).toLocaleDateString()}</p>
        <p className="survey-card-fields">Number of Fields: {inputs.length}</p>
      </div>
      <button className="survey-card-button">View Details</button>
    </div>
  );
};

export default SurveyCard;
