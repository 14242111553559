const CustomCloseIcon = ({ fill, onClick }) => {
  return (
    <svg width="24" height="24" id="closeIcon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <path
        d="M11.9979 0C5.37157 0 0 5.37329 0 12.0004C0 18.6267 5.37157 24 11.9979 24C18.6276 24 24 18.6259 24 12.0004C24 5.37329 18.6276 0 11.9979 0ZM17.1574 15.2496L15.2462 17.1583C15.2462 17.1583 12.2227 13.9142 11.997 13.9142C11.7747 13.9142 8.75037 17.1583 8.75037 17.1583L6.83826 15.2496C6.83826 15.2496 10.0857 12.2699 10.0857 12.0047C10.0857 11.7352 6.83826 8.75466 6.83826 8.75466L8.75037 6.8417C8.75037 6.8417 11.7996 10.0875 11.997 10.0875C12.1961 10.0875 15.2462 6.8417 15.2462 6.8417L17.1574 8.75466C17.1574 8.75466 13.9091 11.7782 13.9091 12.0047C13.9091 12.2201 17.1574 15.2496 17.1574 15.2496Z"
        fill={fill}
      />
    </svg>
  );
};

export default CustomCloseIcon;