import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import InputField from "../components/register-login-quickbook/inputField";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grow from "@mui/material/Grow";
import PhoneNumInput from "../components/register-login-quickbook/phoneInput.jsx";
import "./register.css";
import { ReactComponent as AppLogo } from "../assets/svg/IncuHub.svg";
import AnimatedText from "../utils/AnimatedText.jsx";
import HomePic from "../assets/png/home img.png";

const screenWidth = window.innerWidth;

const style = {
  // boxShadow: 24,
  // paddingRight: "10px",
  // minWidth: screenWidth < 1300 ? "80%" : "60%",
  // height: screenWidth < 1300 ? "85%" : "80%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: screenWidth > 900 ? 400 : 693,
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingRight: screenWidth > 900 ? "10px" : "0px",
  minWidth: screenWidth > 900 ? "89%" : "90%",
  height:
    screenWidth > 900
      ? "85%"
      : screenWidth > 1024 && screenWidth < 1440
      ? "85%"
      : "88vh",
  borderRadius: "20px",
  overflowY: "hidden",
  overflowX: "hidden",
};

const apiURL = process.env.REACT_APP_API_URL;

const checkEmailExists = async (email) => {
  try {
    const response = await axios.get(`${apiURL}/v1/users/check-email`, {
      params: { email },
    });
    return response.data.exists;
  } catch (error) {
    console.error("Error checking email existence:", error);
    toast.error("Failed to verify email. Please try again.");
    throw error;
  }
};

const registerUser = async (formData, setOpenSnackBar) => {
  try {
    const response = await axios.post(`${apiURL}/v1/auth/register`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    setOpenSnackBar({ open: true, message: "Registration successful" });
    console.log("register response", response);
    return response.data;
  } catch (err) {
    setOpenSnackBar({
      open: true,
      message: "There was an error while registering",
    });
    console.log("There was an error while registering", err);
  }
};

const createForm = (data) => {
  const formData = new FormData();

  formData.append("firstname", data.firstname);
  formData.append("lastname", data.lastname);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("address", data.address);
  formData.append("membership", data.membership);
  return formData;
};

const validateForm = (data) => {
  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+(?:\d{1,3})(?:\s|-)?(?:\d{9,})$/;

  if (!data.firstname || !data.firstname.trim()) {
    errors.firstname = "First name is required";
  }

  if (!data.lastname) {
    errors.lastname = "Last name is required";
  }

  if (!data.email || !emailRegex.test(data.email)) {
    errors.email = "Enter a valid Email";
  }

  if (!data.phone || !phoneRegex.test(data.phone)) {
    errors.phone = "Enter a valid Phone number";
  }

  if (!data.address || !data.address.trim()) {
    errors.address = "Address is required";
  }

  if (!data.membership || !data.membership.trim()) {
    errors.membership = "Membership is required";
  }

  return errors;
};

export default function Register({ open, setOpen }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    membership: "",
  });

  const [loading, setLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    message: "",
  });

  if (width <= 900) {
    style.minWidth = "80%";
    style.height = "90%";
    style.paddingRight = "0px";
  }

  const close = () => {
    setOpen(false);
  };

  const handleSnackBar = async (openOrClose) => {
    if (openOrClose) {
      setOpenSnackBar(true);
    } else {
      setOpenSnackBar(false);
    }
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  const logo = localStorage.getItem("adminImage");
  const getFileUrl = `${window.env_url}/v1/files/`;

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formValues);
    const errors = validateForm(formValues);

    if (Object.keys(errors).length) {
      const error = Object.keys(errors)[0];
      setOpenSnackBar({ open: true, message: errors[error] });
      setLoading(false);
      console.log("Eroor", error);
      return;
    }

    try {
      const trimmedEmail = formValues.email.trim();
      const emailExists = await checkEmailExists(trimmedEmail);
      if (emailExists) {
        toast.error("Email is already registered under another role.");
        setLoading(false);
        return;
      }
      const formData = createForm(formValues);
      const response = await registerUser(formData, setOpenSnackBar);
      if (response?.status === 200) {
        toast.success("Successfully Registered. Contact admin for further more steps.")
        setFormValues({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          address: "",
          membership: "",
        });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      toast.error("Failed to register. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="white"
        onClick={() => handleSnackBar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="registerModal"
        onClose={() => {
          setOpen(false);
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "#a5ccce", // Custom backdrop color
            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms", // Custom transition
          },
        }}
      >
        <Box component="form" sx={{ ...style }}>
          <div className="leftRightSectionContainer QbLeftRightStyles">
            <div className="leftSection QbleftSection">
              <div className="QbLeftSectionInnerContainer">
                <AnimatedText
                  text="Welcome to IncuHub!"
                  delay={100}
                  className="qb-heading"
                />
                <span className="home-desc">
                  A comprehensive incubation management system designed to
                  streamline and enhance the processes of incubation centers.
                </span>
                <div className="img-container">
                  <img src={HomePic} className="home-image" />
                </div>
              </div>
            </div>
            <div className="registerContainer rightSection">
              <div className="logo-data register-logo">
                <div className="logo">
                  <img src={getFileUrl + logo} className="login-logo" />
                </div>
                <div className="data">
                  <h1>Register</h1>
                  <span>Follow the below steps to get registered with us</span>
                </div>
              </div>
              {loading ? (
                <div className="loader">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="registerForm">
                    <div className="register-input-fields">
                      <div className="register-input-field">
                        <label className="namesInputLabel">
                          <span>First Name</span>{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          onChange={handleOnchange}
                          value={formValues.firstName}
                          name="firstname"
                          className="nameInputFieldStyle"
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="register-input-field">
                        <label className="namesInputLabel">
                          <span>Last Name</span>
                          <span style={{ color: "red", paddingLeft: "2px" }}>
                            *
                          </span>
                        </label>
                        <TextField
                          onChange={handleOnchange}
                          value={formValues.lastName}
                          name="lastname"
                          className="nameInputFieldStyle"
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="emailMobileInputs">
                      <InputField
                        fieldLabel={
                          <>
                            <span>Email</span>
                            <span style={{ color: "red", paddingLeft: "2px" }}>
                              *
                            </span>
                          </>
                        }
                        placeholder={"Email"}
                        name={"email"}
                        className={"emailregister"}
                        setFormState={setFormValues}
                        formState={formValues}
                        style={{
                          width: "100%",
                        }}
                      />
                      <div className="phoneInput">
                        <label className="phoneInputlabel defaultLabelStyle">
                          <span>Phone Number</span>
                          <span style={{ color: "red", paddingLeft: "2px" }}>
                            *
                          </span>
                        </label>
                        <PhoneNumInput setFormState={setFormValues} />
                      </div>
                    </div>
                    <InputField
                      fieldLabel={
                        <>
                          <span>Full mailing address</span>
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      placeholder={"Address"}
                      name={"address"}
                      isMultiline={true}
                      autoCapitalize="none"
                      style={{
                        width: "100%",
                      }}
                      setFormState={setFormValues}
                      rows={4}
                      formState={formValues}
                    />
                    <FormControl className="radioContainer">
                      <label
                        className="defaultLabelStyle"
                        style={{ fontFamily: "Nunito" }}
                      >
                        <span>Which membership are you interested in?</span>
                        <span style={{ color: "red", paddingLeft: "2px" }}>
                          *
                        </span>
                      </label>
                      <RadioGroup
                        name="radio-buttons-group"
                        onChange={handleOnchange}
                        value={formValues.membership}
                        sx={{ flexDirection: "row", gap: "6%" }}
                      >
                        <FormControlLabel
                          className="radioLabelStyle"
                          name="membership"
                          value="investor_readiness_program"
                          control={<Radio />}
                          label="Investor Readiness Program"
                        />
                        <FormControlLabel
                          sx={{ opacity: 1 }}
                          className="radioLabelStyle"
                          name="membership"
                          value="private_office"
                          control={<Radio />}
                          label="Office Space Private Office"
                        />
                        <FormControlLabel
                          className="radioLabelStyle"
                          name="membership"
                          value="cubicle"
                          control={<Radio />}
                          label="Cubicle"
                        />
                        <FormControlLabel
                          className="radioLabelStyle"
                          name="membership"
                          value="hot_desk"
                          control={<Radio />}
                          label="Hot Desk"
                        />
                        <FormControlLabel
                          className="radioLabelStyle"
                          name="membership"
                          value="virtual_membership"
                          control={<Radio />}
                          label="Virtual Membership"
                        />
                      </RadioGroup>
                    </FormControl>
                    {/* 
                    <InputField
                      fieldLabel={"Is there anything else you want to mention?"}
                      isMultiline={true}
                      setFormState={setFormValues}
                      name={"comments"}
                      rows={4}
                      style={{ width: "100%" }}
                      formState={formValues}
                    /> */}
                  </div>
                  <div className="registerBtn ">
                    <Button
                      style={{
                        backgroundColor: "#ffffff",
                        fontFamily: "Nunito",
                        textTransform: "none",
                        color: "#66a6a6",
                        width: "136px",
                        border: "1px solid #66a6a6",
                        height: "46px",
                        borderRadius: "10px",
                        fontSize: "22px",
                      }}
                      type="submit"
                      onClick={close}
                      variant="contained"
                    >
                      Back
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#66a6a6",
                        fontFamily: "Nunito",
                        textTransform: "none",
                        width: "136px",
                        height: "46px",
                        borderRadius: "10px",
                        fontSize: "22px",
                      }}
                      type="submit"
                      onClick={handleOnSubmit}
                      variant="contained"
                    >
                      Register
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
