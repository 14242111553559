import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../assets/svg/Arrow.svg";
import StarRating from "../widgets/StarRating";
import "./RoomCard.css";
import { Rating } from "@mui/material";
import StarBorderPurple500Outlined from "@mui/icons-material/StarBorderPurple500Outlined";
import { token } from "../utils/utilityFunctions";
import { useQuery } from "react-query";
import axios from "axios";

const RoomCard = ({
  imgSrc,
  roomTitle,
  ratings,
  description,
  linkTo,
  onViewClick,
  roomId,
  setRefreshRating,
  displayReviews,
  coachId,
}) => {
  const placeHolderImg = "https://picsum.photos/200/300";

  const [currentRating, setCurrentRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const [numberOfReviews, setNumberOfReviews] = useState();

  const getReviews = async () => {
    try {
      const response = await axios.get(
        `${window.env_url}/v1/reviews/room/${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Review card ressponse", response)
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const { data, isLoading } = useQuery({
    queryKey: roomTitle,
    queryFn: getReviews,
    onSuccess: (data) => {
      setNumberOfReviews(data?.data?.body[0].length);
      console.log("number of reviews", data?.data?.body[0].length);
    },
  });

  let fontSize;
  if (width > 1440) {
    fontSize = "2rem";
  } else if (width > 1024 && width <= 1440) {
    fontSize = "1.5rem";
  }

  const getRatings = async () => {
    try {
      const response = await fetch(
        `${window.env_url}/v1/rooms/ratings/${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      const data = await response.json();
      setCurrentRating(data.body[0].ratings[0].avgRating);
      setRefreshRating((prev) => prev + 1);
    } catch (error) {
      console.error(error.message);
    }
  };
  const number = Number(currentRating);

  // console.log(roomId, `rating ${number}`)

  useEffect(() => {
    getRatings();
  }, [roomId]);

  let rootClassName;
  const path = window.location.href;
  if (path.includes("/home")) {
    rootClassName = "trmr room extraClass";
  } else {
    rootClassName = "room roomCard-tablet extraClass";
  }

  let starFontSize;
  if (width > 1440) {
    starFontSize = "2rem";
  } else if (width > 1023 && width < 1440) {
    starFontSize = "1.5rem";
  } else if (width > 769 && width < 1023) {
    starFontSize = "1.5rem";
  }


  let imageClass;

  return (
    <div className={rootClassName} style={{ overflow: "scroll !important" }}>
      <div className="hovercolorbubble"></div>
      <img src={imgSrc || placeHolderImg} className="room-card room-img " />
      <div className="room-rating">
        <Rating
          value={number}
          precision={0.5}
          readOnly
          style={{ fontSize: starFontSize }}
          emptyIcon={
            <StarBorderPurple500Outlined
              style={{ color: "#ff931b", fontSize: starFontSize }}
            />
          }
        />
      </div>
      <div className="details-and-button">
        <div className="room-details home mv" id="roomCardContent">
          <div className="numberOfRatings">
            {/* Reviews ({numberOfReviews || 0}) */}
          </div>
          <p>{roomTitle}</p>
          <span>{description}</span>
        </div>
        <Link to={linkTo}>
          <div className="arrow-container">
            <Arrow onClick={onViewClick} />
          </div>
        </Link>
      </div>
    </div>
  );
};

RoomCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  totalStars: PropTypes.number.isRequired,
  roomTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default RoomCard;
