import InputField from "../inputField";
import Underline from "../../../assets/png/Line.png";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as UserIcon } from "../../../assets/svg/UserIcon.svg";
import { ReactComponent as Mail } from "../../../assets/svg/Mail.svg";
import { ReactComponent as Phone } from "../../../assets/svg/Phone.svg";
import "./basicDetails.css";
import { useEffect, useState } from "react";

export default function BasicDetailsSection({ formState, setFormState }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [basicDeatilsForm, setBasicDetailsForm] = useState({
    ...formState.basicDetails,
  });
  console.log(formState);

  const [isEmailValid, setIsEmailValid] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailBlur = (e) => {
    const email = e.target.value;
    if (validateEmail(email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
      alert('Please enter a valid email address.');
    }
  };

  useEffect(() => {
    setFormState((prevState) => {
      return {
        ...prevState,
        basicDetails: (() => {
          const { firstname, lastname, email, contact } = basicDeatilsForm;
          return { firstname, lastname, email, contact };
        })(),
      };
    });
  }, [basicDeatilsForm]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="basicDeatilsForm">
        <div className="fullName">
          <InputField
            fieldLabel={"First Name"}
            placeholder={"First name"}
            name={"firstname"}
            formState={basicDeatilsForm}
            className={"nameInputField"}
            setFormState={setBasicDetailsForm}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <UserIcon />
                </InputAdornment>
              ),
            }}
          />
          <InputField
            fieldLabel={"Last Name"}
            placeholder={"Last name"}
            className={"nameInputField"}
            name={"lastname"}
            formState={basicDeatilsForm}
            setFormState={setBasicDetailsForm}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <UserIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {windowWidth <= 900 && (
          <div className="fullName">
            <InputField
              fieldLabel="Email"
              placeholder="Email"
              className="inputField"
              name="email"
              formState={basicDeatilsForm}
              setFormState={setBasicDetailsForm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
            <InputField
              fieldLabel="Mobile Number"
              placeholder="Mobile Number"
              className="inputField"
              name="contact"
              formState={basicDeatilsForm}
              setFormState={setBasicDetailsForm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        {windowWidth > 900 && (
          <>
            <InputField
              fieldLabel="Email"
              placeholder="Email"
              className="inputField"
              name="email"
              formState={basicDeatilsForm}
              setFormState={setBasicDetailsForm}
              onBlur={handleEmailBlur} 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
            {isEmailValid && (
            <InputField
              fieldLabel="OTP"
              placeholder="OTP"
              className="inputField"
              name="otp"
              formState={basicDeatilsForm}
              setFormState={setBasicDetailsForm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />)}
            <InputField
              fieldLabel="Contact"
              placeholder="Contact"
              className="inputField"
              name="contact"
              formState={basicDeatilsForm}
              setFormState={setBasicDetailsForm}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </div>
    </>
  );
}
