import React, { useEffect, useState } from "react";
import MeetingReviewDialog from "../../../dialogs/MeetingReviewDialog";
import { formatDate, formatTime, token } from "../../../utils/utilityFunctions";
import { toast } from "react-toastify";
import EditReviewDialog from "../../../dialogs/EditReviewDialog";
import axios from "axios";
import RoomEditReviewDialog from "../../../dialogs/RoomEditReviewDialog";
import CoachReviewDialog from "../../../dialogs/CoachReviewDialog";

const CoachBookingRow = ({ item, coachName }) => {
  const [reviewOpen, setReviewOpen] = useState(false);
  const [editReviewOpen, setEditReviewOpen] = useState(false);
  const handleReviewOpen = () => setReviewOpen(true);
  const handleReviewClose = () => setReviewOpen(false);
  const handleEditReviewOpen = () => setEditReviewOpen(true);
  const handleEditReviewClose = () => setEditReviewOpen(false);
  const bookingId = item?.id;
  const [review, setReview] = useState("");
  const handleClick = () => {
    item?.isReviewed ? handleEditReviewOpen() : handleReviewOpen();
  };
  

  useEffect(() => {
    getReviewByBookingId();
  }, []);

  const getReviewByBookingId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/coachbookings/review/${bookingId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const review = response.data.body[0];
      setReview(review);

    } catch (error) {
      console.error(error?.message);
    }
  };

  function getStatusClassName(visibility) {
    switch (visibility) {
      case "accepted":
        return "acceptedStatus";
      case "rejected":
        return "rejectedStatus";
      default:
        return "pendingStatus";
    }
  }

  console.log("isReviewed", item?.isReviewed)

  return (
    <>
      <tr key={item.id}>
        <td>{coachName || "Loading..."}</td>
        <td>{formatDate(item.checkInDate)},{item.checkInTime}</td>
        <td>{formatDate(item.checkOutDate)},{item.checkOutTime}</td>
        <td>{item.duration} hours</td>
        <td>{item.skills.join(", ")}</td>
        <td>
          <button className={getStatusClassName(item?.visibility)}>
            {item.visibility === "accepted"
              ? "Accepted"
              : item.visibility === "rejected"
              ? "Rejected"
              : "Pending"}
          </button>
        </td>

        <td id="viewDetailsId">
          <span style={{ cursor: "pointer", color: "white" }} onClick={handleClick}>
            {item.isReviewed ? "View Review" : "Add review"}
          </span>
        </td>
      </tr>

      {reviewOpen && (
        <CoachReviewDialog
          open={reviewOpen}
          close={handleReviewClose}
          bookingId={bookingId}
        />
      )}
      {editReviewOpen && (
        <EditReviewDialog
          open={editReviewOpen}
          close={handleEditReviewClose}
          review={review}
          isBookingRow={true} //test to not display that update button
        />
      )}
    </>
  );
};

export default CoachBookingRow;
