import { useState, useRef } from "react";
import { Box, Modal, containerClasses } from "@mui/material";
import "./QbookingDialog.css";
import { ReactComponent as UserIcon } from "../assets/svg/UserIcon.svg";
import { ReactComponent as Mail } from "../assets/svg/Mail.svg";
import { ReactComponent as Locationpin } from "../assets/svg/locationpin.svg";
import { ReactComponent as Organization } from "../assets/svg/Organization.svg";
import { ReactComponent as Phone } from "../assets/svg/Phone.svg";
import TStatusBar1 from "../assets/png/TabStatus1.png";
import { ReactComponent as QbLevel1 } from "../assets/svg/QbLevel1.svg";
import { ReactComponent as QbLevel2 } from "../assets/svg/QbLevel2.svg";
import { ReactComponent as QbLevel3 } from "../assets/svg/QbLevel3.svg";

import Logo from "../assets/png/Logo.png";
import QbStatus1 from "../assets/png/QbStatus1.png";
import QbImage1 from "../assets/png/QbImage1.png";
import Underline from "../assets/png/Line.png";
import { Close } from "@mui/icons-material";
import QbookingDialog2 from "./QbookingDialog2";
import QbookingDialog3 from "./QbookingDialog3";
import { createContext } from "react";
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay-ts';
import { toast } from "react-toastify";
import CustomCloseIcon from "../assets/svg/CustomCloseIcon";
const apiURL = process.env.REACT_APP_API_URL;

const desktopStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "1321px",
  height: "727px",
};

const laptopStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  width: "89vw",
  height: "50.5vw",
};

const responsiveStyle = {
  padding: 0,
  width: "100%",
  height: "100%",
};

const tabletStyle = {
  width: "87%",
  height: "90%",
  bgcolor: "background.paper",
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "20px",
  transform: "translate(-50%, -50%)",
  position: "relative",
  overflow:"scroll"
};

export default function QbookingDialog({ open, handleClose }) {
  const [passOpen2, setPassOpen2] = useState(false);
  const handlePassOpen = () => setPassOpen2(true);
  const closePassOpen = () => setPassOpen2(false);
  const [passwordType, setPasswordType] = useState("password");
  const [width, setWidth] = useState(window.innerWidth);
  const [loaderAnimation, setLoaderAnimation] = useState(false);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  let appliedStyle;

  if (width >= 1024 && width <= 1440) {
    appliedStyle = laptopStyle;
  } else if (width > 768 && width <= 1023) {
    appliedStyle = tabletStyle;
  } else {
    appliedStyle = desktopStyle;
  }

  const [activeForm, setActiveForm] = useState(1);
  const  switchfirstSection = () => {
    setActiveForm(1);
  };

  const switchSecondSection = () => {
    setActiveForm(2);
  };

  const switchThirdSection = () => {
    setActiveForm(3);
  };

  const [formData, setFormData] = useState({
    basicDetails:{
    firstname: "",
    lastname: "",
    email: "",
    contact: "",
    organisation: "",
    address: ""
    },
    meetingRoomDetails:{
      roomType: "Select Room Type", // Initial value for dropdown
      guests: "",
      date: "",
      time: "",
      details: "",
    },
    setUpDetails:{
      seats: "No.of seats",
      facilities: "Select Facility",
      notes: "",
      duration:"15 minutes"
      }
  });

  const [errors, setErrors] = useState({});


  console.log(formData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({...formData, basicDetails:{...formData.basicDetails,[name]: value}});
    setErrors({ ...errors, [name]: "" });
  };

  const handleResSubmit = (e) => {
    e.preventDefault();
    //console.log("Clicked");
    switchSecondSection();
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    var phoneRegex = /^\d{10}$/;

    // Perform your validation here
    const newErrors = {};

    if (formData.basicDetails.firstname.trim() === "") {
      newErrors.firstname = "First Name is required";
      toast.warn("Please fill all the fields");
      return
    }

    if (formData.basicDetails.lastname.trim() === "") {
      newErrors.lastname = "Last Name is required";
      toast.warn("Please fill all the fields");
      return
    }

    if (formData.basicDetails.email.trim() === "") {
      newErrors.email = "Email is required";
      toast.error("Please Enter a valid email");
      return
    } else if (!/^\S+@\S+\.\S+$/.test(formData.basicDetails.email)) {
      newErrors.email = "Invalid email format";
      toast.error("Please Enter a valid email");
      return
    }

    if (formData.basicDetails.contact.trim() === "" || !phoneRegex.test(formData.basicDetails.contact)) {
      newErrors.contact = "Contact is required";
      toast.error("Please enter a valid phone number");
      return
    }

    if (formData.basicDetails.location.trim() === "") {
      newErrors.lastname = "Last Name is required";
      toast.warn("Please fill all the fields");
      return
    }

    if (formData.basicDetails.organisation.trim() === "") {
      newErrors.lastname = "Last Name is required";
      toast.warn("Please fill all the fields");
      return
    }

    setErrors(newErrors);

    // If there are no errors, you can submit the form
    if (Object.keys(newErrors).length === 0) {
      // Handle form submission

      // // Create a FormData object to gather form data
      // const formData = new FormData();

      // // Append each field to the FormData object
      // formData.append("firstname", formData.firstname);
      // formData.append("lastname", formData.lastname);
      // formData.append("email", formData.email);
      // formData.append("contact", formData.contact);
      // formData.append("organization", formData.organization);
      // formData.append("address", formData.address);

      // console.log(formData);
      // console.log(FormData);
      setLoaderAnimation(true)
      const userData = formData.basicDetails;
      const requestFormData = new FormData();
  
      requestFormData.append("email", userData.email);
      requestFormData.append("firstname", userData.firstname);
      requestFormData.append("lastname", userData.lastname);   
      requestFormData.append("location", userData.location);
      requestFormData.append("organisation", userData.organisation);
      console.log(userData)
      
      try{
        await axios.post(`${apiURL}/v1/users/quickbook`,requestFormData,
        {
          headers: {
            'Content-Type': "application/json"
          }
        }).then((response)=>{
          setLoaderAnimation(false)
          console.log("token", response.data.body[1]["token"]);
          localStorage.setItem("quickBookToken", response.data.body[1]["token"])
        })
      }catch(error){
        console.log(error)
        setLoaderAnimation(false)
        toast.error("User needs to register")
        return
      }


      // window.location.href = "/l2";
      switchSecondSection();
      // Now, you can send the FormData object to your server or process it as needed
    }
  };

  return (
    <>
      {width <= 768 ? (
        <div className="qbReContainer">
            <LoadingOverlay
              active={loaderAnimation}
              spinner
              text='Loading...'
            ></LoadingOverlay>
          <div className="qb-top-section">
            <img src={Logo} className="imgReLogo" />
            <p className="qb-heading1">Quick Booking</p>
            <span className="qb-desc">Follow the below steps and get quick response</span>
            <div className="svgicon">
              <QbLevel1 />
            </div>
            <div className="status">
              <p>Basic Details</p>
              <p>Meeting Room Details</p>
              <p>Set up details</p>
            </div>
          </div>
          <div className="qb-bottom-section">
            <h1>Basic Details</h1>
            <div className="qb-underline">
              <img src={Underline} />
            </div>
            <div className="form-btn">
              <div className="form quickbook1">
                <div className="label-input-div">
                  {" "}
                  <div className="label-div">
                    <label className="qb-label-mv">First Name</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      value={formData.basicDetails.firstname}
                      onChange={handleInputChange}
                      className="login-firstname"
                      placeholder="First Name"
                      required
                    />
                    <div className="qb1-icon-container">
                      <UserIcon />
                    </div>
                  </div>
                </div>
                <div className="label-input-div">
                  <div className="label-div">
                    <label className="qb-label-mv">Last Name</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      id="lastname"
                      name="lastname"
                      value={formData.basicDetails.lastname}
                      onChange={handleInputChange}
                      className="login-firstname"
                      placeholder="Last Name"
                      required
                    />
                    <div className="qb1-icon-container">
                      <UserIcon />
                    </div>
                  </div>
                </div>
                <div className="label-input-div">
                  {" "}
                  <div className="label-div">
                    <label className="qb-label-mv">Email</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.basicDetails.email}
                      onChange={handleInputChange}
                      className="login-firstname"
                      placeholder="Email"
                      required
                    />
                    <div className="qb1-icon-container">
                      <Mail />
                    </div>
                  </div>
                </div>
                <div className="label-input-div">
                  <div className="label-div">
                    <label className="qb-label-mv">Contact</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="phone"
                      id="contact"
                      name="contact"
                      value={formData.basicDetails.contact}
                      onChange={handleInputChange}
                      className="login-contact"
                      placeholder="Contact"
                      required
                    />
                    <div className="qb1-icon-container">
                      <Phone />
                    </div>
                  </div>
                </div>
                <div className="label-input-div">
                  <div className="label-div">
                    <label className="qb-label-mv">Location</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      id="location"
                      name="location"
                      value={formData.basicDetails.location}
                      onChange={handleInputChange}
                      className="login-contact"
                      placeholder="Location"
                      required
                    />
                    <div className="qb1-icon-container">
                      <Phone />
                    </div>
                  </div>
                </div>
                <div className="label-input-div">
                  <div className="label-div">
                    <label className="qb-label-mv">Organization</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      id="organisation"
                      name="organisation"
                      value={formData.basicDetails.organisation}
                      onChange={handleInputChange}
                      className="login-contact"
                      placeholder="Organization"
                      required
                    />
                    <div className="qb1-icon-container">
                      <Phone />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-container">
                <button className="back-btn" onClick={handleClose}>
                  Back
                </button>
                <button className="next-btn" onClick={handleSubmit}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : width <= 1024 ? (
        <Modal
          open={true}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
          <LoadingOverlay
              active={loaderAnimation}
              spinner
              text='Loading...'
            ></LoadingOverlay>
          <Box sx={appliedStyle}>
            <div className="tab-container">
              <CustomCloseIcon fill="#FF0000" onClick={() => window.location.href("/")} />
              <div className="qb-top-section">
                <img src={Logo} className="imgReLogo" />
                <p className="qb-heading1">Quick Booking</p>
                <span className="qb-desc">Follow the below steps and get quick response</span>
                <div className="svgicon">
                  <img src={TStatusBar1} />
                </div>
                <div className="status">
                  <p>Basic Details</p>
                  <p>Meeting Room Details</p>
                  <p>Set up details</p>
                </div>
              </div>
              <div className="qb-bottom-section">
                <h1>Basic Details</h1>
                <div className="qb-underline">
                  <img src={Underline} />
                </div>
                <div className="form-btn">
                  <div className="form quickbook1">
                    <div className="row1">
                      <div className="label-input-div">
                        {" "}
                        <div className="label-div">
                          <label className="qb-label-mv">First Name</label>
                        </div>
                        <div className="input-container">
                          <input
                            type="text"
                            id="firstname"
                            name="firstname"
                            value={formData.basicDetails.firstname}
                            onChange={handleInputChange}
                            className="login-firstname"
                            placeholder="First Name"
                            required
                          />
                          <div className="qb1-icon-container">
                            <UserIcon />
                          </div>
                        </div>
                      </div>
                      <div className="label-input-div">
                        <div className="label-div">
                          <label className="qb-label-mv">Last Name</label>
                        </div>
                        <div className="input-container">
                          <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            value={formData.basicDetails.lastname}
                            onChange={handleInputChange}
                            className="login-firstname"
                            placeholder="Last Name"
                            required
                          />
                          <div className="qb1-icon-container">
                            <UserIcon />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div className="row2">
                      <div className="label-input-div">
                        {" "}
                        <div className="label-div">
                          <label className="qb-label-mv">Email</label>
                        </div>
                        <div className="input-container">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.basicDetails.email}
                            onChange={handleInputChange}
                            className="login-firstname"
                            placeholder="Email"
                            required
                          />
                          <div className="qb1-icon-container">
                            <Mail />
                          </div>
                        </div>
                      </div>
                      <div className="label-input-div">
                        <div className="label-div">
                          <label className="qb-label-mv">Contact</label>
                        </div>
                        <div className="input-container">
                          <input
                            type="phone"
                            id="contact"
                            name="contact"
                            value={formData.basicDetails.contact}
                            onChange={handleInputChange}
                            className="login-contact"
                            placeholder="Contact"
                            required
                          />
                          <div className="qb1-icon-container">
                            <Phone />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div  className="row2">
                    <div className="label-input-div">
                  <div className="label-div">
                    <label className="qb-label-mv">Location</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      id="location"
                      name="location"
                      value={formData.basicDetails.location}
                      onChange={handleInputChange}
                      className="login-contact"
                      placeholder="Location"
                      required
                    />
                    <div className="qb1-icon-container">
                      <Phone />
                    </div>
                  </div>
                </div>
                    <div className="label-input-div">
                  <div className="label-div">
                    <label className="qb-label-mv">Organization</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      id="organisation"
                      name="organisation"
                      value={formData.basicDetails.organisation}
                      onChange={handleInputChange}
                      className="login-contact"
                      placeholder="Organization"
                      required
                    />
                    <div className="qb1-icon-container">
                      <Phone />
                    </div>
                  </div>
                </div>

                    </div>
                  </div>
                  <div className="btn-container">
                    <button className="next-btn" onClick={handleSubmit}>
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Box>
          </>
        </Modal>
      ) : (
        <Modal
          open={true}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <>
          <LoadingOverlay
              active={loaderAnimation}
              spinner
              text='Loading...'
            ></LoadingOverlay>
          <Box sx={appliedStyle}>
            <div className={width >= 1441 ? "qb-container" : "qb-laptop-container"}>
            <CustomCloseIcon fill="#FF0000" onClick={() => window.location.href("/")} />
              <div className="qb1-left-section">
                <img src={Logo} className="qb-logo" />
                <h1 className="qb-heading1">Quick Booking</h1>
                <span>
                  Follow the below steps and get <br /> quick response
                </span>
                <div className="status-details">
                  <img src={QbStatus1} />
                  <div className="qb1 details">
                    <span>Basic details</span>
                    <span>Meeting Room Details</span>
                    <span>Set Up Details</span>
                  </div>
                </div>
                <img src={QbImage1} className="qb1-img" />
              </div>
              <div className="qb1 qb-right-section">
                <h1 className="qb-heading2">Basic Details</h1>
                <img style={width >= 1441 ? { width: "311px" } : { width: "19vw" }} src={Underline} />
                <form className="qb-form" onSubmit={handleSubmit}>
                  <div className="qb-column1">
                    <div className="qb label-ip-container">
                      <label className="qb-label">First Name</label>
                      <div className="input-container">
                        <input
                          autocomplete="off"
                          type="text"
                          id="firstname"
                          name="firstname"
                          value={formData.basicDetails.firstname}
                          onChange={handleInputChange}
                          className="login-firstname"
                          placeholder="First Name"
                          required
                        />
                        <div className="qb1-icon-container">
                          <UserIcon />
                        </div>
                        <span className="error">{errors.firstname}</span>
                      </div>
                    </div>
                    <div className="qb label-ip-container">
                      <label className="qb-label">Email</label>
                      <div className="input-container">
                        <input
                          autocomplete="off"
                          type="email"
                          id="email"
                          name="email"
                          value={formData.basicDetails.email}
                          onChange={handleInputChange}
                          className="login-email"
                          placeholder="Email"
                          required
                        />
                        <div className="qb1-icon-container">
                          <Mail />
                        </div>
                        <span className="error">{errors.email}</span>
                      </div>
                    </div>
                    <div className="qb label-ip-container">
                      <div className="label-div">
                      <label className="qb-label">Location</label>
                      </div>
                      <div className="input-container">
                        <input
                          type="text"
                          id="location"
                          name="location"
                          value={formData.basicDetails.location}
                          onChange={handleInputChange}
                          className="login-contact"
                          placeholder="Location"
                          required
                        />
                        <div className="qb1-icon-container">
                          <Phone />
                        </div>
                    </div>
                </div>
                  </div>
                  <div className="qb-column2">
                    <div className="qb label-ip-container">
                      <label className="qb-label">Last Name</label>
                      <div className="input-container">
                        <input
                          autocomplete="off"
                          type="text"
                          id="lastname"
                          name="lastname"
                          value={formData.basicDetails.lastname}
                          onChange={handleInputChange}
                          className="login-last-name"
                          placeholder="Last Name"
                          required
                        />
                        <div className="qb1-icon-container">
                          <UserIcon />
                        </div>
                        <span className="error">{errors.lastname}</span>
                      </div>
                    </div>
                    <div className="qb label-ip-container">
                      <label className="qb-label">Contact</label>
                      <div className="input-container">
                        <input
                          autocomplete="off"
                          type="number"
                          id="contact"
                          name="contact"
                          value={formData.basicDetails.contact}
                          onChange={handleInputChange}
                          className="login-contact"
                          placeholder="Contact"
                          required
                        />
                        <div className="qb1-icon-container">
                          <Phone />
                        </div>
                        <span className="error">{errors.contact}</span>
                      </div>
                    </div>
                <div className="qb label-ip-container">
                  <div className="label-div">
                  <label className="qb-label">Organization</label>
                  </div>
                  <div className="input-container">
                    <input
                      type="text"
                      id="organisation"
                      name="organisation"
                      value={formData.basicDetails.organisation}
                      onChange={handleInputChange}
                      className="login-contact"
                      placeholder="Organization"
                      required
                    />
                    <div className="qb1-icon-container">
                      <Phone />
                    </div>
                  </div>
                </div>
                  </div>
                </form>
                <div className="btn-container">
                  <button className="qb submit-btn" type="button" onClick={handleSubmit}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </Box>
          </>
        </Modal>
      )}

      {activeForm === 2 && (
        <QbookingDialog2 formData={formData} setFormData={setFormData} onClose={handleClose} nextDialog={switchThirdSection} goBack={switchfirstSection} />
      )}
      {activeForm === 3 && <QbookingDialog3 formData={formData} setFormData={setFormData} onClose={handleClose} goBack={switchSecondSection} />}
    </>
  );
}
