import React from "react";
import StarRating from "../widgets/StarRating";
import "./CustomerCard.css";

const CustomerCard = ({ id, name, rating, imgurl, description }) => {
  return (
    <div className="custRatings customer-card" key={id}>
      <img
        src={imgurl}
        className="coach-img cust-profilePic"
        alt={`Coach ${name}`}
      />
      <div className="rating-and-details">
        <div className="customer-rating">
          <StarRating rating={rating} totalStars={5} fontSize="35px" width="" />
        </div>
        <div className="customer-details">
          <span className="cust-name">{name}</span>
          <span>{description}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomerCard;
