import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./BookCoach.css";
import { ReactComponent as UserIcon } from "../../assets/svg/UserIcon.svg";
import { ReactComponent as Mail } from "../../assets/svg/Mail.svg";
import { ReactComponent as Zipcode } from "../../assets/svg/Zipcode.svg";
import { ReactComponent as Calendar } from "../../assets/svg/Calendarpaper.svg";
import { ReactComponent as Phone } from "../../assets/svg/Telephone.svg";
import { ReactComponent as Dropdown } from "../../assets/svg/Litedropdown.svg";
import { ReactComponent as ToggleSideBar } from "../../assets/svg/ToggleSidebar.svg";
import { ReactComponent as RespDropdownForm } from "../../assets/svg/DropdownForm.svg";

import { useState } from "react";
import MSideBar from "../../components/sidebar/mobile/MSideBar";
import NotificationWidget from "../../widgets/NotificationWidget";
import MNavbar from "../../components/navbar/mobile/MNavbar";
import { toast } from "react-toastify";

const BookCoach = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contact: "",
    city: "Select your City",
    store: "Select your state",
    zipcode: "",
    checkin: "",
    checkout: "",
  });

  const [showDropdown, setShowDropdown] = useState(false);
  const [showStoreDropdown, setShowStoreDropdown] = useState(false);
  const [errors, setErrors] = useState({});

  //console.log(formData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown visibility
  };

  const handleCityChange = (selectedCity) => {
    setFormData({ ...formData, city: selectedCity });
    setShowDropdown(false); // Close the dropdown when an option is selected
  };

  const toggleStoreDropdown = () => {
    setShowStoreDropdown(!showStoreDropdown); // Toggle the dropdown visibility
  };

  const handleStoreChange = (selectedStore) => {
    setFormData({ ...formData, store: selectedStore });
    setShowStoreDropdown(false); // Close the dropdown when an option is selected
  };

  const cities = ["Select your City", "Hyderabad", "Chennai", "Mumbai", "Bengaluru", "Kochi"]; // Dropdown options

  const stores = [
    "Select your state",
    "Hyderabad",
    "Chennai",
    "Mumbai",
    "Bengaluru",
    "Kochi",
  ]; // Dropdown options

  const handleSubmit = (e) => {
    e.preventDefault();

    // validation here
    var phoneRegex = /^\d{10}$/;
    const newErrors = {};
    if (formData.fullName.trim() === "") {
      newErrors.fullName = "Full Name is required";
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (formData.contact.trim() === "" || !phoneRegex.test(formData.contact)) {
      newErrors.contact = "Contact is required";
      toast.error("Invalid contact no.")
    }

    if (formData.city.trim() === "Select your City") {
      newErrors.city = "city is required";
    }

    if (formData.zipcode.trim() === "") {
      newErrors.zipcode = "zipcode is required";
    }

    if (formData.store.trim() === "Select your state") {
      newErrors.store = "Store is required";
    }

    if (formData.city.trim() === "Select your City") {
      newErrors.city = "City is required";
    }

    if (formData.checkin.trim() === "") {
      newErrors.checkin = "Check In is required";
    }

    if (formData.checkout.trim() === "") {
      newErrors.checkout = "Check Out is required";
    }

    setErrors(newErrors);
    //console.log(formData);
    //console.log(errors);

    // If there are no errors, you can submit the form
    if (Object.keys(newErrors).length === 0) {
      setFormData(formData);
      window.location.href = "/l3";
    }
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  return (
    <div className="mr-root">
      {width <= 768 ? (
        <>
         <MNavbar />
          <div className="mv-form-root">
            <div className="mr-form bmr">
              <div className="mv-mr-form-header">Reservation Form</div>
              <form className="bmr-form">
                <div className="mv-br-input-container">
                  <div className="bc-column1">
                    <div className="label-input-container">
                      <label>Name</label>
                      <div className="bc-input-container">
                        <input
                          type="text"
                          id="fullName"
                          name="fullName"
                          value={formData.fullName}
                          onChange={handleInputChange}
                          className="login-fullName"
                          required
                        />
                        {/* <div className="bc1-icon-container">
                        <UserIcon />
                      </div>
                      <span
                        className={errors.fullName ? "error" : "error-hidden"}
                      >
                        {errors.fullName}
                      </span> */}
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Email ID</label>
                      <div className="bc-input-container">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="login-email"
                          required
                        />
                        {/* <div className="bc3-icon-container">
                        <Mail />
                      </div>
                      <span className={errors.email ? "error" : "error-hidden"}>
                        {errors.email}
                      </span> */}
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>City</label>
                      <div className="dropdown-container">
                        <div className="selectedDropdowntype" onClick={toggleDropdown}>
                          {formData.city}
                        </div>
                        <div className="dropdown-icon-container" onClick={toggleDropdown}>
                          <RespDropdownForm />
                        </div>
                        {/* <span className={errors.city ? "error" : "error-hidden"}>
                        {errors.city}
                      </span> */}
                        {showDropdown && (
                          <div className="dropdown-options">
                            {cities.map((type) => (
                              <div key={type} onClick={() => handleCityChange(type)}>
                                {type}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Check In</label>
                      <div className="bc-input-container">
                        <input
                          type="datetime-local"
                          id="checkinn"
                          name="checkin"
                          value={formData.checkin}
                          onChange={handleInputChange}
                          className="bc-checkinout"
                          required
                        />
                        {/* <div className="bc8-icon-container">
                        <Calendar />
                      </div>
                      <span
                        className={errors.checkin ? "error" : "error-hidden"}
                      >
                        {errors.checkin}
                      </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="bc-column2">
                    <div className="label-input-container">
                      <label>Mobile Number</label>
                      <div className="bc-input-container">
                        <input
                          type="number"
                          id="contact"
                          name="contact"
                          value={formData.contact}
                          onChange={handleInputChange}
                          className="bc-contact"
                          required
                        />
                        {/* <div className="bc1-icon-container">
                        <Phone />
                      </div>
                      <span
                        className={errors.contact ? "error" : "error-hidden"}
                      >
                        {errors.contact}
                      </span> */}
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>State</label>
                      <div className="dropdown-container">
                        <div className="selectedDropdowntype" onClick={toggleStoreDropdown}>
                          {formData.store}
                        </div>
                        <div className="dropdown-icon-container" onClick={toggleStoreDropdown}>
                          <RespDropdownForm />
                        </div>
                        {showStoreDropdown && (
                          <div className="dropdown-options">
                            {stores.map((type) => (
                              <div key={type} onClick={() => handleStoreChange(type)}>
                                {type}
                              </div>
                            ))}
                          </div>
                        )}
                        {/* <span className={errors.city ? "error" : "error-hidden"}>
                        {errors.store}
                      </span> */}
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Zipcode</label>
                      <div className="bc-input-container">
                        <input
                          type="number"
                          id="zipcode"
                          name="zipcode"
                          value={formData.zipcode}
                          onChange={handleInputChange}
                          className="login-zipcode"
                          required
                        />
                        {/* <div className="bc1-icon-container">
                        <Zipcode />
                      </div> */}

                        <span className={errors.zipcode ? "error" : "error-hidden"}>{errors.zipcode}</span>
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Check out</label>
                      <div className="bc-input-container">
                        <input
                          type="datetime-local"
                          id="checkoutt"
                          name="checkout"
                          value={formData.checkout}
                          onChange={handleInputChange}
                          className="bc-checkinout"
                          required
                        />
                        {/* <div className="bc8-icon-container">
                        <Calendar />
                      </div>
                      <span
                        className={errors.checkout ? "error" : "error-hidden"}
                      >
                        {errors.checkout}
                      </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="mv-bmr-btn-container">
                <button className="bmr-submit" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Navbar />
          <div className="mr-container">
            <Sidebar
            // homeActive={homeActive}
            // meetingActive={meetingActive}
            // coachesActive={coachesActive}
            />

            <div className="mr-form">
              <div className="mr-form-header">Reservation Form</div>
              <form className="bc-form">
                <div className="br-input-container">
                  <div className="bc-column1">
                    <div className="label-input-container">
                      <label>Enter your Full Name</label>
                      <div className="bc-input-container">
                        <input
                          type="text"
                          id="fullName"
                          name="fullName"
                          value={formData.fullName}
                          onChange={handleInputChange}
                          className="login-fullName"
                          required
                        />
                        <div className="bc1-icon-container">
                          <UserIcon />
                        </div>
                        <span
                          className={errors.fullName ? "error" : "error-hidden"}
                        >
                          {errors.fullName}
                        </span>
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Enter your Email ID</label>
                      <div className="bc-input-container">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="login-email"
                          required
                        />
                        <div className="bc3-icon-container">
                          <Mail />
                        </div>
                        <span
                          className={errors.email ? "error" : "error-hidden"}
                        >
                          {errors.email}
                        </span>
                      </div>
                    </div>

                    <div className="label-input-container">
                      <label>Enter your city</label>
                      <div className="dropdown-container">
                        <div
                          className="selected-room-type"
                          onClick={toggleDropdown}
                        >
                          {formData.city}
                        </div>
                        <div
                          className="dropdown-icon-container"
                          onClick={toggleDropdown}
                        >
                          <Dropdown />
                        </div>
                        <span
                          className={errors.city ? "error" : "error-hidden"}
                        >
                          {errors.city}
                        </span>
                        {showDropdown && (
                          <div className="dropdown-options">
                            {cities.map((type) => (
                              <div
                                key={type}
                                onClick={() => handleCityChange(type)}
                              >
                                {type}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Enter your check in</label>
                      <div className="bc-input-container">
                        <input
                          type="datetime-local"
                          id="checkinn"
                          name="checkin"
                          value={formData.checkin}
                          onChange={handleInputChange}
                          className="bc-checkinout"
                          required
                        />
                        <div className="bc8-icon-container">
                          <Calendar />
                        </div>
                        <span
                          className={errors.checkin ? "error" : "error-hidden"}
                        >
                          {errors.checkin}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bc-column2">
                    <div className="label-input-container">
                      <label>Enter your Mobile Number</label>
                      <div className="bc-input-container">
                        <input
                          type="tel"
                          id="contact"
                          name="contact"
                          value={formData.contact}
                          onChange={handleInputChange}
                          className="bc-contact"
                          required
                        />
                        <div className="bc1-icon-container">
                          <Phone />
                        </div>
                        <span
                          className={errors.contact ? "error" : "error-hidden"}
                        >
                          {errors.contact}
                        </span>
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Enter your state</label>
                      <div className="dropdown-container">
                        <div
                          className="selected-room-type"
                          onClick={toggleStoreDropdown}
                        >
                          {formData.store}
                        </div>
                        <div
                          className="dropdown-icon-container"
                          onClick={toggleStoreDropdown}
                        >
                          <Dropdown />
                        </div>
                        {showStoreDropdown && (
                          <div className="dropdown-options">
                            {stores.map((type) => (
                              <div
                                key={type}
                                onClick={() => handleStoreChange(type)}
                              >
                                {type}
                              </div>
                            ))}
                          </div>
                        )}
                        <span
                          className={errors.city ? "error" : "error-hidden"}
                        >
                          {errors.store}
                        </span>
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Enter your Zipcode</label>
                      <div className="bc-input-container">
                        <input
                          type="number"
                          id="zipcode"
                          name="zipcode"
                          value={formData.zipcode}
                          onChange={handleInputChange}
                          className="login-zipcode"
                          required
                        />
                        <div className="bc1-icon-container">
                          <Zipcode />
                        </div>

                        <span
                          className={errors.zipcode ? "error" : "error-hidden"}
                        >
                          {errors.zipcode}
                        </span>
                      </div>
                    </div>
                    <div className="label-input-container">
                      <label>Enter your check out</label>
                      <div className="bc-input-container">
                        <input
                          type="datetime-local"
                          id="checkoutt"
                          name="checkout"
                          value={formData.checkout}
                          onChange={handleInputChange}
                          className="bc-checkinout"
                          required
                        />
                        <div className="bc8-icon-container">
                          <Calendar />
                        </div>
                        <span
                          className={errors.checkout ? "error" : "error-hidden"}
                        >
                          {errors.checkout}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <button className="bc-submit" onClick={handleSubmit}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BookCoach;
