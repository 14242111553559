import React, { useState } from "react";
import "./ProfileWidget.css";
import ProfileForm from "./ProfileForm";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { ReactComponent as CredentialsIcons } from "../../assets/svg/CredIcon.svg";
import { ReactComponent as UserIcon } from "../../../src/assets/svg/UserIcon.svg";
import { ReactComponent as WalletIcon } from "../../../src/assets/svg/WalletSvg.svg";
import { ReactComponent as ReviewIcon } from "../../../src/assets/svg/ReviewsDialog.svg";
import CredentialsForm from "./CredentialsForm";
import WalletWidget from "./WalletWidget";
import ReviewSection from "./ReviewSection";
import CoachReviewSection from "./CoachReviewSection";
import CustomCloseIcon from "../../assets/svg/CustomCloseIcon";

function ProfileWidget({ onClose, closeProfile, closeMobProf, refetch }) {
  const [activeForm, setActiveForm] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const switchProfileSection = () => {
    setActiveForm(1);
  };

  const switchWalletSection = () => {
    setActiveForm(2);
  };

  const switchCoachReviewsSection = () => {
    setActiveForm(3);
  };

  const switchRoomReviewsSection = () => {
    setActiveForm(4);
  };

  const closeOptions = () => {
    onClose();
    if (width <= 768) {
      closeMobProf();
    } else {
      closeProfile();
    }
  };

  return (
    <>
      <div className="profileFormWrapper">
        <section className="formSection">
          <div className="profileFormHeaderWrapper">
            <div className="mv profileFormHeader">
              <div className={`profileTag ${activeForm === 1 ? "active" : ""}`} onClick={switchProfileSection}>
                <UserIcon />
                <h2>Profile</h2>
              </div>
              {/* <div className={`profileTag ${activeForm === 2 ? "active" : ""}`} onClick={switchWalletSection}>
                <WalletIcon />
                <h2>Wallet</h2>
              </div> */}
              <div className={`profileTag ${activeForm === 4 ? "active" : ""}`} onClick={switchRoomReviewsSection}>
                <ReviewIcon />
                <h2> Reviews</h2>
              </div>
            </div>
            <span>
              <CustomCloseIcon id="closeManageAccount" fill="#FF0000" onClick={closeOptions}/>
            </span>
          </div>
          {activeForm === 1 && <ProfileForm refetch={refetch} onClose={onClose} closeProfile={closeProfile} />}
          {/* {activeForm === 2 && <WalletWidget onClose={onClose} closeProfile={closeProfile} />} */}
          {activeForm === 4 && <ReviewSection onClose={onClose} closeProfile={closeProfile} activeForm={activeForm} />}
        </section>
      </div>
    </>
  );
}

export default ProfileWidget;
