const AvailabilitySvg = () => {
  return (
    <svg
      width="29"
      height="30"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.0677 55.6921C41.0785 57.8561 36.5598 59 32 59C16.8597 59 5 47.1403 5 32C5 16.8597 16.8597 5 32 5C39.3331 5 46.2356 7.86387 51.4359 13.0641C52.1505 13.7788 53.2249 13.9929 54.1587 13.6067C55.0926 13.2206 55.7022 12.3103 55.7036 11.2998L55.7132 4.219C55.7151 2.83837 54.5974 1.7175 53.2166 1.71563C53.2155 1.71563 53.2144 1.71563 53.2132 1.71563C51.8341 1.71563 50.7152 2.83275 50.7132 4.21225L50.7109 5.93087C45.2689 2.07662 38.7915 0 32 0C14.0561 0 0 14.0561 0 32C0 49.9439 14.0561 64 32 64C37.3899 64 42.733 62.6469 47.4519 60.0871C48.6655 59.4287 49.1156 57.9111 48.4572 56.6975C47.7989 55.4839 46.2812 55.0336 45.0677 55.6921Z"
        fill="#66a3a6"
      />
      <path
        d="M60.9819 23.8986C62.1735 23.2 62.5731 21.6677 61.8744 20.4761C61.1758 19.2846 59.6435 18.885 58.4519 19.5836C57.2604 20.2823 56.8608 21.8146 57.5594 23.0061C58.2581 24.1977 59.7904 24.5973 60.9819 23.8986Z"
        fill="#66a3a6"
      />
      <path
        d="M60.5456 39.9112C59.2481 39.439 57.8136 40.108 57.3414 41.4053C56.8691 42.7028 57.5381 44.1373 58.8355 44.6096C60.133 45.0818 61.5675 44.4128 62.0398 43.1155C62.512 41.818 61.843 40.3835 60.5456 39.9112Z"
        fill="#66a3a6"
      />
      <path
        d="M56.088 49.3681C55.0303 48.4806 53.4534 48.6186 52.5659 49.6763C51.6784 50.7339 51.8164 52.3109 52.874 53.1984C53.9318 54.0859 55.5086 53.9479 56.3961 52.8903C57.2838 51.8325 57.1458 50.2556 56.088 49.3681Z"
        fill="#66a3a6"
      />
      <path
        d="M61.5 34.5C62.8807 34.5 64 33.3807 64 32C64 30.6193 62.8807 29.5 61.5 29.5C60.1193 29.5 59 30.6193 59 32C59 33.3807 60.1193 34.5 61.5 34.5Z"
        fill="#66a3a6"
      />
      <path
        d="M32 19.5C33.3807 19.5 34.5 18.3807 34.5 17V12C34.5 10.6193 33.3807 9.5 32 9.5C19.5935 9.5 9.5 19.5935 9.5 32C9.5 44.4065 19.5935 54.5 32 54.5C44.4065 54.5 54.5 44.4065 54.5 32C54.5 27.9805 53.4265 24.0346 51.3955 20.589C49.4255 17.247 46.6095 14.4538 43.2519 12.5115C42.0566 11.8203 40.5275 12.2285 39.836 13.4236C39.1446 14.6187 39.553 16.1481 40.7481 16.8395C45.4157 19.5396 48.5526 24.2485 49.3169 29.4999H47C45.6193 29.4999 44.5 30.6191 44.5 31.9999C44.5 33.3806 45.6193 34.4999 47 34.4999H49.3206C48.2211 42.1526 42.1526 48.2211 34.5 49.3206V47C34.5 45.6193 33.3807 44.5 32 44.5C30.6193 44.5 29.5 45.6193 29.5 47V49.3206C21.8474 48.2211 15.7789 42.1526 14.6794 34.5H17C18.3807 34.5 19.5 33.3807 19.5 32C19.5 30.6193 18.3807 29.5 17 29.5H14.6794C15.7789 21.8474 21.8474 15.7789 29.5 14.6794V17C29.5 18.3807 30.6193 19.5 32 19.5Z"
        fill="#66a3a6"
      />
      <path
        d="M37 34.5H32C30.6193 34.5 29.5 33.3807 29.5 32V27C29.5 25.6193 30.6193 24.5 32 24.5C33.3807 24.5 34.5 25.6193 34.5 27V29.5H37C38.3807 29.5 39.5 30.6193 39.5 32C39.5 33.3807 38.3807 34.5 37 34.5Z"
        fill="#66a3a6"
      />
    </svg>
  );
};

export default AvailabilitySvg;