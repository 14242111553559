import { MultiSelect } from "primereact/multiselect";
import { useState } from "react";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import "./MultiSelect.css";
import { useMediaQuery } from "@mui/material";
import Asterisk from "../utils/Asterisk";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const screenWidth = window.innerWidth;

const MultiSelectDropdown = ({
  value,
  onChangeEvent,
  options,
  placeholder,
  isMandatory
}) => {
  const [personName, setPersonName] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  console.log(options);

  const islaptop = useMediaQuery('(min-width:1024px) and (max-width:1440px)');

  return (
    <>
      <div className="bookingfacilitiesPicker">
        <label className="makebold">{placeholder} {isMandatory && <Asterisk />}</label>
        
        <FormControl
          sx={{
            width: screenWidth < 769 ? "66vw" : islaptop ? "100%" : "100%",
            marginRight: 0,
            height: "40px",
            ".MuiInputLabel-root": { textOverflow: "clip", width: "100%" },
          }}
        >
          {/* <InputLabel id="demo-multiple-name-label">Facilities</InputLabel> */}
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            multiple
            value={value}
            onChange={onChangeEvent}
            input={
              <OutlinedInput label="Facilities" style={{ color: "black" }} />
            }
            sx={{ margin: "0px", border: "1px solid", height: "100%" }}
            // MenuProps={MenuProps}
          >
            {options.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>


    </>
  );
};

export default MultiSelectDropdown;
