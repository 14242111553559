import { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./BookingHistory.css";
import RoomBookingHistory from "./room/RoomBookingHistory";
import CoachBookingHistory from "./coach/CoachBookingHistory";

const BookingHistory = () => {
  const [roomOpen, setRoomOpen] = useState(true); // Default to Room Booking History
  const [coachOpen, setCoachOpen] = useState(false); // Default Coach to closed

  const handleRoomClick = () => {
    setRoomOpen(true);
    setCoachOpen(false);
  };

  const handleCoachClick = () => {
    setRoomOpen(false);
    setCoachOpen(true);
  };

  return (
    <div className="coaches-root">
      <Navbar />
      <div className="mr-container">
        <Sidebar />
        <div className="booking-right-section">
          <div className="sortButtons">
            <button
              onClick={handleRoomClick}
              className={roomOpen ? "selectedButton" : ""}
            >
              Meeting Room
            </button>
            <button
              onClick={handleCoachClick}
              className={coachOpen ? "selectedButton" : ""}
            >
              Coach
            </button>
          </div>
          {roomOpen ? <RoomBookingHistory /> : <CoachBookingHistory />}
        </div>
      </div>
    </div>
  );
};

export default BookingHistory;
