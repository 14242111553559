import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import "./RoomDetails.css";

const RoomDetails = () => {
  const homeActive = true;
  const meetingActive = false;
  const coachesActive = false;

  return (
    <div className="home-stats-root">
      <Navbar />
      <div className="home-stats-container">
          <Sidebar />
        <div className="home-right-stats-section">
          <div className="home-inner-stats">
            <div className="stats-bookings">
              <p>All Bookings</p>
              <div className="num-stats">
                <div className="numbers">
                  <p>286</p>
                  <span>Successful</span>
                </div>
                <div className="numbers">
                  <p>50</p>
                  <span>Cancelled</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDetails;
