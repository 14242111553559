import { useEffect, useRef, useState } from "react";
import "./events.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/svg/IncuHub.svg";

const EventAttendenceForm = () => {
  const emailRef = useRef(null);
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState({});
  const [information, setInformation] = useState({
    messageType: -1,
    message: "",
  });

  const successIcon = "./../confirmed.svg";
  const errorIcon = "./../error.svg";

  useEffect(() => {
    (async () => {
      try {
        const eventDetailsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/events/${id}`
        );
        setEventDetails(eventDetailsResponse?.data?.body[0]);
      } catch (error) {}
    })();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      let email = emailRef.current.value;
      email = email.trim();
      if (!email.length) {
        setInformation({
          messageType: 2,
          message: "Please Enter Email",
        });
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/eventBookings/${id}?email=${email}`
      );
      if (response?.data.status === 200) {
        setInformation({
          messageType: 0,
          message:
            "Your attendance for the event has been successfully completed.",
        });
      }
    } catch (error) {
      console.log("error",  error?.response?.data?.message)
      setInformation({
        messageType: 1,
        message: error?.response?.data?.message,
      });
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <div className="event_attendence_container">
      <form className="event_attendence_form_wrapper" onSubmit={submitHandler}>
        <div className="event_attendence_header">
          {/* <img src="./../applogo.svg" alt="applogo" /> */}
          <Logo />
          <div className="event_attendence_event_name">
            {eventDetails?.eventName}
          </div>
          <div className="event_attendence_eventdate_and_time">
            {formatDate(eventDetails?.eventDate)}{" "}
            {formatTime(eventDetails?.eventStartTime)} To{" "}
            {formatTime(eventDetails?.eventEndTime)}
          </div>
        </div>
        <div className="event_attendence_form_field">
          <span>Enter Your Email</span>
          <div className="event_attendence_input_container">
            <input type="email" ref={emailRef} />
            <img src="./../email.svg" />
          </div>
          {information?.messageType !== -1 && (
            <div className="event_attendence_information">
              <img
                src={information.messageType === 0 ? successIcon : errorIcon}
                className="message_icon"
              />
              <span
                style={{
                  color: information.messageType === 0 ? "#3DCB19" : "#FF674F",
                }}
              >
                {information?.message}
              </span>
            </div>
          )}
        </div>
        <div>
          <button className="event_attendence_submit">Submit</button>
        </div>
      </form>
    </div>
  );
};
export default EventAttendenceForm;
