import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./NewsModal.css";
import CustomCloseIcon from "../assets/svg/CustomCloseIcon.jsx";
import { useMediaQuery } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 890,
  height: 666,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "0 32px 32px 32px",
  borderRadius: 8,
};

const laptopStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "54%",
  height: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "0 32px 32px 32px",
  borderRadius: 8,
};

const tabletStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "82vw",
  height: "68vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "0 32px 32px 32px",
  borderRadius: 2,
};

const mobileStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 890,
  height: 666,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "0 32px 32px 32px",
  borderRadius: 8,
};

const modalStyles = {
  "& .MuiDialog-paper": {
    outline: "none",
    boxShadow: "none",
  },
};

export default function NewsModal({ handleOpen, handleClose, open, setOpen }) {
  console.log("open.data", open.data);
  const [newsData, setNewsData] = useState({});
  const { imgSrc, newsTitle, newsDescription } = open.data;
  const isDesktop = useMediaQuery("(min-width: 1441px)");
  const is1280x720 = useMediaQuery(
    "(min-width: 720px) and (max-width: 1280px)"
  );
  const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1023px)");
  console.log(is1280x720, "is1280x720");
  console.log(isDesktop, "isDesktop");

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div>
      <Modal
        open={open.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: adjust backdrop styling
            },
          },
          paper: {
            sx: modalStyles,
          },
        }}
      >
        <Box
          sx={
            isDesktop
              ? style
              : is1280x720
              ? laptopStyle
              : isTablet
              ? tabletStyle
              : mobileStyle
          }
        >
          <div className="newsContainer">
            <CustomCloseIcon fill="#FF0000" onClick={handleClose} />
            <div>
              <h1
                style={{
                  fontFamily: "Nunito",
                  fontSize: isTablet ? "3vw" : "",
                }}
              >
                {newsTitle}
              </h1>
            </div>

            <div className="newsImage">
              <img src={imgSrc} alt="" />
            </div>

            <div className="newsDescriptionContainer">
              <p>{newsDescription}</p>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
