import { fetchAllData } from "../../../utils/fetchData";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Underline from "../../../assets/png/Line.png";
import "./meetingRoomDetails.css";
import { OutlinedInput } from "@mui/material";

const screenWidth = window.innerWidth;

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: "-3rem",
  height: screenWidth > 900 ? "45%" : "70%",
  overflowY: "auto",
  maxHeight: screenWidth ? "43%" : "none",
};

export default function MeetingRoomDetailsSection({ formState, setFormState }) {
  const meetingRoomsEndPoint = "/v1/rooms";
  const [rooms, setRooms] = useState({});
  const [selectedRoom, setSelectedRoom] = useState({});
  const { data, isLoading, refetch } = useQuery(
    ["meetingRooms", meetingRoomsEndPoint],
    () => fetchAllData(meetingRoomsEndPoint),
    {
      onSuccess: (data) => {
        // Your onSuccess logic here
        setRooms(data);
        setFormState((prevState) => {
          return { ...prevState, rooms: data };
        });
        console.log("yes it loads here");
      },
    },
    { refetchOnMount: true }
  );
  console.log("meeting Room data", formState);

  const handleChange = (event) => {
    const value = event.target.value;
    console.log(value);
    setSelectedRoom(value);
    setFormState((prevState) => {
      return { ...prevState, roomData: value };
    });
  };
  // console.log("selected",selectedRoom?.room?.title.split("|")[0])
  return (
    <Box sx={{ minWidth: 120, height: "70%", ...style }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="selectAndDetailsContainer">
            <label className="selectRoom">Select Meeting Room</label>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                {formState.roomData ? "" : "Room"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formState.roomData}
                label="Room"
                sx={{ fontFamily: "Nunito", height: "auto" }}
                width="100%"
                onChange={handleChange}
                input={
                  <OutlinedInput
                    label={formState.roomData ? "" : "Facilities"}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    style={{ color: "black" }}
                  />
                }
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      top: "550px",
                      maxHeight: "303px",
                    },
                  },
                }}
              >
                {formState.rooms.map((room) => (
                  <MenuItem
                    style={{
                      width: "100%",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                    }}
                    value={room}
                  >
                    {room.title.split("|")[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedRoom?.title && (
              <div className="detailsContainer">
                <p>
                  <span>Title: </span>{" "}
                  {formState.roomData?.title.split("|")[0] || " "}
                </p>
                <p>
                  <span>Facilities: </span>{" "}
                  {formState.roomData?.facilities.split("|").join(", ") || " "}
                </p>
                <p>
                  <span>Credits: </span> {formState.roomData?.credits || " "}
                </p>
                <p>
                  <span>Description: </span>{" "}
                  {formState.roomData?.description || " "}
                </p>
                <p>
                  <span>Ratings:</span> 3
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </Box>
  );
}
