import LogoutSvg from "./LogoutSvg";
import SettingsSvg from "./SettingsSvg";
import "./ProfileOptions.css";
import { useState } from "react";
import ProfileWidget from "../profile-ui/ProfileWidget";
import { createPortal } from "react-dom";
import Cookies from "js-cookie";
import { usertype } from "../../utils/utilityFunctions";
import AvailabilitySvg from "./AvailabilitySvg";
import CoachAvailability from "./coach-availability/CoachAvailability";

const ProfileOptions = ({ closeProfile, closeMobProf, refetch,coachId }) => {
  const [manageClicked, setManageClicked] = useState(false);
  const [avClicked, setAvClicked] = useState(false);

  const handleAccount = () => {
    setManageClicked(!manageClicked);
  };

  const handleAvailability = () => {
    setAvClicked(!avClicked);
  };

  const handleLogOutClicked = () => {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove("access_token");
    window.location.href = "/";
  };

  return (
    <div className="options-container">
      <div className="option one" onClick={handleAccount}>
        <SettingsSvg />
        <p className="manage-acc">Manage Account</p>
      </div>
      {usertype === "coach" && (
        <div className="option one" onClick={handleAvailability}>
          <AvailabilitySvg />
          <p className="manage-acc">Manage Availability</p>
        </div>
      )}
      <div className="option" onClick={handleLogOutClicked}>
        <LogoutSvg />
        <p id="logOutOption">Logout</p>
      </div>
      {manageClicked &&
        createPortal(
          <ProfileWidget
            refetch={refetch}
            onClose={handleAccount}
            closeProfile={closeProfile}
            closeMobProf={closeMobProf}
          />,
          document.body
        )}
        {
          avClicked &&  <CoachAvailability coachId={coachId} close={() => setAvClicked(false)}/>
        }
    </div>
  );
};

export default ProfileOptions;
