const LogoutSvg = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
      >
        <path
          d="M23.4162 8.60234C22.8532 8.07337 21.94 8.07328 21.3768 8.60225C20.8137 9.13131 20.8136 9.98902 21.3768 10.5181L25.6449 14.5277H7.97538C7.17895 14.5277 6.53326 15.1342 6.53326 15.8824C6.53326 16.6306 7.17895 17.2371 7.97538 17.2371H25.6447L21.3768 21.2463C20.8136 21.7754 20.8136 22.6331 21.3768 23.1622C21.6584 23.4267 22.0275 23.5589 22.3966 23.5589C22.7656 23.5589 23.1347 23.4267 23.4162 23.1622L30.1459 16.8404C30.7091 16.3114 30.7091 15.4536 30.1459 14.9245L23.4162 8.60234Z"
          fill="#66a3a6"
        />
        <path
          d="M14.705 30.7844C15.5014 30.7844 16.1471 30.1779 16.1471 29.4297C16.1471 28.6815 15.5014 28.075 14.705 28.075H3.64876V3.69014H14.705C15.5014 3.69014 16.1471 3.08359 16.1471 2.33543C16.1471 1.58726 15.5014 0.980713 14.705 0.980713H2.20664C1.41021 0.980713 0.764526 1.58726 0.764526 2.33543V29.4297C0.764526 30.1779 1.41021 30.7844 2.20664 30.7844H14.705Z"
          fill="#66a3a6"
        />
      </svg>
    </>
  );
};


export default LogoutSvg;