import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./BookMeetingRoom.css";
import { ReactComponent as UserIcon } from "../../assets/svg/UserIcon.svg";
import { ReactComponent as Mail } from "../../assets/svg/Mail.svg";
import { ReactComponent as Zipcode } from "../../assets/svg/Zipcode.svg";
import { ReactComponent as Calendar } from "../../assets/svg/Calendarpaper.svg";
import { ReactComponent as Phone } from "../../assets/svg/Telephone.svg";
import { ReactComponent as Dropdown } from "../../assets/svg/Litedropdown.svg";
import { ReactComponent as ToggleSideBar } from "../../assets/svg/ToggleSidebar.svg";
import { ReactComponent as RespDropdownForm } from "../../assets/svg/DropdownForm.svg";

import { useState } from "react";
import { DateRangePicker } from "rsuite";
import isAfter from "date-fns/isAfter";
import { rootShouldForwardProp } from "@mui/material/styles/styled";
import MSideBar from "../../components/sidebar/mobile/MSideBar";
import NotificationWidget from "../../widgets/NotificationWidget";
import MNavbar from "../../components/navbar/mobile/MNavbar";
import { toast } from "react-toastify";

const { beforeToday, combine } = DateRangePicker;

const BookMeetingRoom = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    credits: "",
    organization: "",
    location: "Select your City",
    store: "Select your state",
    zipcode: "",
    checkin: "",
    checkout: "",
    facility: "Select a Facility",
    seats: 0,
  });

  const [showDropdown, setShowDropdown] = useState(false);
  const [showStoreDropdown, setShowStoreDropdown] = useState(false);
  const [showFacilityDropdown, setShowFacilityDropdown] = useState(false);
  const [showSeatDropdown, setShowSeatDropdown] = useState(false);
  const [errors, setErrors] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  //console.log(formData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleDateRangeChange = (value) => {
    setFormData({ ...formData, selectedDateRange: value });
  };

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() : "";
  };

  // CITY DROPDOWN

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCityChange = (selectedCity) => {
    setFormData({ ...formData, city: selectedCity });
    setShowDropdown(false);
  };

  // STORE DROPDOWN

  const toggleStoreDropdown = () => {
    setShowStoreDropdown(!showStoreDropdown);
  };

  const handleStoreChange = (selectedStore) => {
    setFormData({ ...formData, store: selectedStore });
    setShowStoreDropdown(false);
  };

  // FACILITIES DROPDOWN

  const handleFacilityChange = (selectedFacility) => {
    setFormData({ ...formData, facility: selectedFacility });
    setShowFacilityDropdown(false);
  };

  const toggleFacilityDropdown = () => {
    setShowFacilityDropdown(!showFacilityDropdown);
  };

  // SEATS DROPDOWN

  const toggleSeatDropdown = () => {
    setShowSeatDropdown(!showSeatDropdown);
  };

  const handleSeatChange = (selectedSeats) => {
    setFormData({ ...formData, seats: selectedSeats });
    setShowSeatDropdown(false);
  };

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const cities = ["Select your City", "Hyderabad", "Chennai", "Mumbai", "Bengaluru", "Kochi"]; // Dropdown options

  const stores = ["Select your state", "Store 1", "Store 2", "Store 3", "Store 4"]; // Dropdown options

  const facilities = ["Select Facility", "F 1", "F 2", "F 3", "F 4"];

  let seats = [];
  for (let i = 1; i <= 30; i++) {
    seats.push(i);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    var phoneRegex = /^\d{10}$/;
    // validation here
    const newErrors = {};
    if (formData.fullName.trim() === "") {
      newErrors.fullName = "Full Name is required";
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (formData.contact.trim() === "" || !phoneRegex.test(formData.contact)) {
      newErrors.contact = "Contact is required";
      toast.error("Enter a Valid Mobile number");
    }

    if (formData.city.trim() === "Select your City") {
      newErrors.city = "city is required";
    }

    if (formData.zipcode.trim() === "") {
      newErrors.zipcode = "zipcode is required";
    }

    if (formData.store.trim() === "Select your state") {
      newErrors.store = "Store is required";
    }

    if (formData.city.trim() === "Select your City") {
      newErrors.city = "City is required";
    }

    if (formData.checkin.trim() === "") {
      newErrors.checkin = "Check In is required";
    }

    if (formData.checkout.trim() === "") {
      newErrors.checkout = "Check In Out is required";
    }

    if (formData.facility.trim() === "Select a Facility") {
      newErrors.facility = "Facility is required";
    }
    const seats = String(formData.seats);
    if (seats.trim() <= 0) {
      newErrors.seats = "Seats are required";
    }

    setErrors(newErrors);
    //console.log(formData);
    //console.log(errors);

    // If there are no errors, you can submit the form
    if (Object.keys(newErrors).length === 0) {
      setFormData(formData);
      // window.location.href = "/l3";
    }
  };

  return (
    <div className="mr-root">
      {width <= 768 ? (
        <>
          <MNavbar />
          <div className="mv-form-root">
            <div className="mr-form bmr">
              <div className="mv-mr-form-header">Reservation Form</div>
              <form className="bmr-form">
                <div className="mv-br-input-container">
                  <div className="bmr-column1">
                    <div className="mv-label-input-container">
                      <label>Full Name</label>
                      <div className="bmr-input-container">
                        <input
                          type="text"
                          id="fullName"
                          name="fullName"
                          value={formData.fullName}
                          onChange={handleInputChange}
                          className="login-fullName"
                          required
                        />
                        {/* <div className="bmr1-icon-container">
                        <UserIcon />
                      </div> */}
                        <br />
                        {/* <span
                        className={errors.fullName ? "error" : "error-hidden"}
                      >
                        {errors.fullName}
                      </span> */}
                      </div>
                    </div>
                    <div className="mv-label-input-container">
                      <label>Email</label>
                      <div className="bmr-input-container">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="login-email"
                          required
                        />
                      </div>
                    </div>
                    <div className="mv-label-input-container">
                      <label>City</label>
                      <div className="dropdown-container">
                        <div className="selectedDropdowntype" onClick={toggleDropdown}>
                          {formData.city}
                        </div>
                        <div className="dropdown-icon-container" onClick={toggleDropdown}>
                          <RespDropdownForm />
                        </div>
                        {showDropdown && (
                          <div className="dropdown-options">
                            {cities.map((type) => (
                              <div key={type} onClick={() => handleCityChange(type)}>
                                {type}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mv-label-input-container">
                      <label>Check in</label>
                      <div className="bmr-input-container">
                        <input
                          type="datetime-local"
                          id="checkin"
                          name="checkin"
                          value={formData.checkin}
                          onChange={handleInputChange}
                          className="bmr-checkin"
                          required
                        />
                      </div>
                    </div>
                    <div className="mv-label-input-container">
                      <label>Zipcode</label>
                      <div className="bmr-input-container">
                        <input
                          type="number"
                          id="zipcode"
                          name="zipcode"
                          value={formData.zipcode}
                          onChange={handleInputChange}
                          className="login-zipcode"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bmr-column2">
                    <div className="mv-label-input-container"></div>
                    <div className="mv-label-input-container"></div>
                    <div className="mv-label-input-container"></div>
                    <div className="mv-label-input-container"></div>
                    <div className="mv-label-input-container"></div>
                    <label>Mobile Number</label>
                    <div className="bmr-input-container">
                      <input
                        type="number"
                        id="contact"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        name="contact"
                        value={formData.contact}
                        onChange={handleInputChange}
                        className="bmr-contact"
                        required
                      />
                    </div>
                    <label>Store</label>
                    <div className="dropdown-container">
                      <div className="selectedDropdowntype" onClick={toggleStoreDropdown}>
                        {formData.store}
                      </div>
                      <div className="dropdown-icon-container" onClick={toggleStoreDropdown}>
                        <RespDropdownForm />
                      </div>
                      {showStoreDropdown && (
                        <div className="dropdown-options">
                          {stores.map((type) => (
                            <div key={type} onClick={() => handleStoreChange(type)}>
                              {type}
                            </div>
                          ))}
                        </div>
                      )}
                      {/* <span className={errors.store ? "error" : "error-hidden"}>
                        {errors.store}
                      </span> */}
                    </div>
                    <label>Facilites</label>
                    <div className="dropdown-container">
                      <div className="selectedDropdowntype" onClick={toggleFacilityDropdown}>
                        {formData.facility}
                      </div>
                      <div className="dropdown-icon-container" onClick={toggleFacilityDropdown}>
                        <RespDropdownForm />
                      </div>
                      {/* <span
                        className={errors.facility ? "error" : "error-hidden"}
                      >
                        {errors.facility}
                      </span> */}
                      {showFacilityDropdown && (
                        <div className="dropdown-options">
                          {facilities.map((type) => (
                            <div key={type} onClick={() => handleFacilityChange(type)}>
                              {type}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <label>Enter your check out</label>
                    <div className="bmr-input-container">
                      <input
                        type="datetime-local"
                        id="checkout"
                        name="checkout"
                        value={formData.checkout}
                        onChange={handleInputChange}
                        className="bmr-checkinout"
                        required
                      />

                      {/* icon container  */}
                      {/* <div className="bmr8-icon-container">
                        <Calendar />
                      </div> */}
                      {/* <span
                        className={errors.checkout ? "error" : "error-hidden"}
                      >
                        {errors.checkinout}
                      </span> */}
                    </div>
                    <label className="seats-label">Seats</label>
                    <div className="dropdown-container">
                      <div className="selectedDropdowntype" onClick={toggleSeatDropdown}>
                        {formData.seats}
                      </div>
                      <div className="dropdown-icon-container" onClick={toggleSeatDropdown}>
                        <RespDropdownForm />
                      </div>
                      {/* <span className={errors.seats ? "error" : "error-hidden"}>
                        {errors.seats}
                      </span> */}
                      {showSeatDropdown && (
                        <div className="dropdown-options">
                          {seats.map((type) => (
                            <div key={type} onClick={() => handleSeatChange(type)}>
                              {type}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div className="mv-bmr-btn-container">
                <button className="bmr-submit" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Navbar />
          <div className="mr-container">
            <Sidebar
            // homeActive={homeActive}
            // meetingActive={meetingActive}
            // coachesActive={coachesActive}
            />
            <div className="rf-right-section">
              <div className="mr-form bmr">
                <div className="bmr-form-header">Reservation Form</div>
                <form className="bmr-form">
                  <div className="rf1 input-container">
                    <div className="bmr-column1">
                      <div className="bmr label-ip-container">
                        <label>Name</label>
                        <div className="bmr-input-container">
                          <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            className="login-fullName"
                            required
                          />
                          <br />
                          <span className={errors.fullName ? "error" : "error-hidden"}>{errors.fullName}</span>
                        </div>
                      </div>
                      <div className="bmr label-ip-container">
                        <label>Contact</label>
                        <div className="bmr-input-container">
                          <input
                            type="number"
                            id="contact"
                            name="contact"
                            value={formData.contact}
                            onChange={handleInputChange}
                            className="login-fullName"
                            required
                          />

                          <br />
                          <span className={errors.fullName ? "error" : "error-hidden"}>{errors.fullName}</span>
                        </div>
                      </div>
                      <div className="bmr label-ip-container">
                        <label>Organization</label>
                        <div className="bmr-input-container">
                          <input
                            type="organization"
                            id="organization"
                            name="organization"
                            value={formData.organization}
                            onChange={handleInputChange}
                            className="login-email"
                            required
                          />
                        </div>
                      </div>
                      <div className="bmr label-ip-container">
                        <label className="seats-label">No. of Seats</label>
                        <div className="dropdown-container">
                          <div className="selected-room-type" onClick={toggleSeatDropdown}>
                            {formData.seats}
                          </div>
                          <div className="dropdown-icon-container" onClick={toggleSeatDropdown}>
                            <Dropdown />
                          </div>
                          <span className={errors.seats ? "error" : "error-hidden"}>{errors.seats}</span>
                          {showSeatDropdown && (
                            <div className="dropdown-options">
                              {seats.map((type) => (
                                <div key={type} onClick={() => handleSeatChange(type)}>
                                  {type}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="bmr label-ip-container">
                        <label>Enter your check in</label>
                        <div className="bmr-input-container">
                          <input
                            type="datetime-local"
                            id="checkin"
                            name="checkin"
                            value={formData.checkin}
                            onChange={handleInputChange}
                            className="bmr-checkin"
                            required
                          />
                          <div className="bmr8-icon-container">
                            <Calendar />
                          </div>
                          <span className={errors.checkinout ? "error" : "error-hidden"}>{errors.checkinout}</span>
                        </div>
                      </div>
                    </div>
                    <div className="bmr-column2">
                      <div className="bmr label-ip-container">
                        <label>Enter your Email ID</label>
                        <div className="bmr-input-container">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="login-email"
                            required
                          />

                          <span className={errors.email ? "error" : "error-hidden"}>{errors.email}</span>
                        </div>
                      </div>
                      <div className="bmr label-ip-container">
                        <label>Location</label>
                        <div className="dropdown-container">
                          <div className="selected-room-type" onClick={toggleDropdown}>
                            {formData.location}
                          </div>

                          {showDropdown && (
                            <div className="dropdown-options">
                              {cities.map((type) => (
                                <div key={type} onClick={() => handleCityChange(type)}>
                                  {type}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className="bmr label-ip-container">
                      <label>Enter your state</label>
                      <div className="dropdown-container">
                        <div
                          className="selected-room-type"
                          onClick={toggleStoreDropdown}
                        >
                          {formData.store}
                        </div>
                        <div
                          className="dropdown-icon-container"
                          onClick={toggleStoreDropdown}
                        >
                          <Dropdown />
                        </div>
                        {showStoreDropdown && (
                          <div className="dropdown-options">
                            {stores.map((type) => (
                              <div
                                key={type}
                                onClick={() => handleStoreChange(type)}
                              >
                                {type}
                              </div>
                            ))}
                          </div>
                        )}
                        <span
                          className={errors.store ? "error" : "error-hidden"}
                        >
                          {errors.store}
                        </span>
                      </div>
                    </div> */}

                      <div className="bmr label-ip-container">
                        <label>Facilites</label>
                        <div className="dropdown-container">
                          <div className="selected-room-type" onClick={toggleFacilityDropdown}>
                            {formData.facility}
                          </div>

                          {showFacilityDropdown && (
                            <div className="dropdown-options">
                              {facilities.map((type) => (
                                <div key={type} onClick={() => handleFacilityChange(type)}>
                                  {type}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="bmr label-ip-container">
                        <label>Credits</label>
                        <div className="bmr-input-container">
                          <input
                            type="number"
                            id="credits"
                            name="credits"
                            value={formData.credits}
                            onChange={handleInputChange}
                            className="bmr-credits"
                            required
                          />
                        </div>
                      </div>

                      <div className="bmr label-ip-container">
                        <label>Enter your check out</label>
                        <div className="bmr-input-container">
                          <input
                            type="datetime-local"
                            id="checkout"
                            name="checkout"
                            value={formData.checkout}
                            onChange={handleInputChange}
                            className="bmr-checkinout"
                            required
                          />
                          <div className="bmr8-icon-container">
                            <Calendar />
                          </div>
                          <span className={errors.checkout ? "error" : "error-hidden"}>{errors.checkinout}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="seats-ip"></div> */}
                  <button className="bmr-submit" onClick={handleSubmit}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BookMeetingRoom;
