import "./LoaderSpinner.css"

const LoaderSpinner = () => {
return (
    <div className="spinner-container">
        <div className="spinner"></div>
      </div>
)
}

export default LoaderSpinner;