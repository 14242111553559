import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { extractFirstValue, token } from "../../../utils/utilityFunctions";
import CoachBookingRow from "./CoachBookingRow";
import LoaderSpinner from "../../../utils/shimmer/LoaderSpinner";
import "../room/RoomBookingHistory.css";
import "../BookingHistory.css";
import { Paginator } from "primereact/paginator";

const CoachBookingHistory = () => {
  const [bookingData, setBookingData] = useState([]);
  const [coachNames, setCoachNames] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchBookingData();
  }, []);

  //pagination logic
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const paginatedData = bookingData?.slice(first, first + rows);
  const fetchBookingData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/coachbookings/byUser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const bookings = response.data.body[0];
      setBookingData(bookings); // Update with the full array of booking data
      fetchCoachNames(bookings);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchCoachNames = async (bookings) => {
    const coachNamesMap = {};
    try {
      for (const booking of bookings) {
        const coachId = booking.bookedCoachId;
        if (!coachNamesMap[coachId]) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/coaches/${coachId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          coachNamesMap[coachId] =
            extractFirstValue(response.data.body[0]?.title, "|") ||
            "Unknown Coach";
        }
      }
      setCoachNames(coachNamesMap);
    } catch (error) {
      console.error("Failed to fetch coach names:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderSpinner />;
  }

  return (
    <>
      {bookingData.length === 0 ? (
        <h1 className="notFoundText">No Bookings Found!</h1>
      ) : (
        <>
          <section className="mbReqTableContainer">
            <table className="bookingRequestTable" id="bookingDetailsId">
              <thead>
                <tr>
                  <th>Coach Name</th>
                  <th>Check In</th>
                  <th>Check out</th>
                  <th>Duration</th>
                  <th>Skills</th>
                  <th>Status</th>
                  <th>Review Information</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData?.map((item) => (
                  <CoachBookingRow
                    key={item.id}
                    item={item}
                    coachName={coachNames[item.bookedCoachId]}
                  />
                ))}
              </tbody>
            </table>
          </section>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={bookingData?.length}
            // rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
        </>
      )}
    </>
  );
};

export default CoachBookingHistory;
