import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

const fetchAllData = async (endpoint) =>{
  
    try {
      const token = localStorage.getItem("quickBookToken");
      //Fetching data
      // console.log("link",`${apiURL}${endpoint}`)
      const response = await axios.get(`${apiURL}${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const data = response.data.body[0];

      if(endpoint.includes('/filterfacilities')){
        return data;
      }

  
      return data;
  
    } catch (error) {
      console.error('Error fetching All data:', error);
    }
  }

  export {fetchAllData}