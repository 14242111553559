import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./Coaches.css";
import moment from "moment";
import { ReactComponent as Arrow } from "../../assets/svg/Arrow.svg";
import { ReactComponent as FilterWatch } from "../../assets/svg/FilterWatch.svg";
import { ReactComponent as Calendar } from "../../assets/svg/Calendar2.svg";
import { ReactComponent as Timer } from "../../assets/svg/Hourglass.svg";
import { ReactComponent as Slider } from "../../assets/svg/Slider.svg";
import { ReactComponent as Downarrow } from "../../assets/svg/Downarrow.svg";
import { ReactComponent as ToggleSideBar } from "../../assets/svg/ToggleSidebar.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/RespFilterSearch.svg";
import { ReactComponent as RespFilterCalendar } from "../../assets/svg/RespFilterCalendar.svg";
import { ReactComponent as RespFilterClock } from "../../assets/svg/RespFilterClock.svg";
import { ReactComponent as RespFilterTimer } from "../../assets/svg/RespFilterTimer.svg";
import { ReactComponent as RespFilterSlider } from "../../assets/svg/RespFilterSlider.svg";
import { ReactComponent as RespFilterDropdown } from "../../assets/svg/RespFilterDropdown.svg";
import { Link, useAsyncError } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useMeetingFilterContext } from "../../context/context";
import FilterWidget from "../../dialogs/profile-ui/filter-widget/FilterWidget";
import StarRating from "../../widgets/StarRating";
import TuneIcon from "@mui/icons-material/Tune";

import MSideBar from "../../components/sidebar/mobile/MSideBar";
import NotificationWidget from "../../widgets/NotificationWidget";
import MNavbar from "../../components/navbar/mobile/MNavbar";
import CoachCard from "../../cards/CoachCard";
import {
  extractFirstValue,
  groupArray,
  token,
  truncateDescription,
} from "../../utils/utilityFunctions";
import DurationModal from "../../cards/DurationModal";
import TCoachCard from "../../components/TCoachCard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Search } from "@mui/icons-material";
import { toast } from "react-toastify";
import RoomCard from "../../cards/RoomCard";
import ShimmerCard from "../../utils/shimmer/ShimmerCard";
import HistoryIcon from "@mui/icons-material/History";
import CoachBookingHistory from "../BookingHistory/coach/CoachBookingHistory";
import { Button } from "@mui/material";

const Coaches = () => {
  const { credits, ratings, skills } = useMeetingFilterContext();
  const inputRef = useRef(null);
  const coachCardsContainerRef = useRef();
  const [availCoachData, setAvailCoachData] = useState([]);
  const [historyTable, setHistoryTable] = useState(false);
  const handleMouseWheel = (e) => {
    if (coachCardsContainerRef.current) {
      coachCardsContainerRef.current.scrollLeft += e.deltaY;
      e.preventDefault();
    }
  };

  // Attach the event listener
  useEffect(() => {
    const container = coachCardsContainerRef.current;
    if (container) {
      container.addEventListener("wheel", handleMouseWheel, { passive: false });

      // Clean up the event listener when the component unmounts
      return () => {
        container.removeEventListener("wheel", handleMouseWheel);
      };
    }
  }, [coachCardsContainerRef]);

  const getFileUrl = `${window.env_url}/v1/files/`;

  const handleIconClick = () => {
    if (inputRef.current) {
      //console.log(inputRef.current);
      inputRef.current.click();
    }
  };

  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [skillsData, setSkillsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //RESPONSIVE STATE

  const fetchSkills = async () => {
    const apiUrl = `${window.env_url}/v1/filterskills`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setSkillsData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  const [width, setWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const [durationDisplayedInText, setDurationDisplayedInText] =
    useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [hasOpenedModal, setHasOpenedModal] = useState(false);
  const onCloseModal = (duration) => {
    setDurationDisplayedInText(duration);
  };
  const onOpenModal = () => {
    setIsOpen(true);
    setHasOpenedModal(true);
  };

  const { hours, minutes, seconds } = durationDisplayedInText || {};

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // skills
  const [showSkillsOptions, setShowSkillsOptions] = useState(false);
  const [selectedSkillsOption, setSelectedSkillsOption] = useState("");
  // credits
  const [showCreditsOptions, setShowCreditsOptions] = useState(false);
  const [selectedCreditsOption, setSelectedCreditsOption] = useState("");
  //RATINGS
  const [showRatingsOptions, setShowRatingsOptions] = useState(false);
  const [selectedRatingsOption, setSelectedRatingsOption] = useState("");
  //AVAILABILITY
  const [showAvailabilityOptions, setShowAvailabilityOptions] = useState(false);
  const [selectedAvailOptions, setSelectedAvailOptions] = useState("");

  const [placeholder, setPlaceholder] = useState("Date");

  //FILTERS DIALOG
  const [openFilters, setOpenFilters] = useState(false);
  const openFilterDialog = () => {
    setOpenFilters(true);
  };

  const handleFiltersClick = () => {
    setOpenFilters(!openFilters);
  };

  const LAPTOP_SCREEN_SIZE = 6;
  const LARGE_SCREEN_SIZE = 8;

  const SCREEN_SIZE = window.innerWidth;

  const PAGE_SIZE =
    SCREEN_SIZE >= 1024 && SCREEN_SIZE <= 1440
      ? LAPTOP_SCREEN_SIZE
      : LARGE_SCREEN_SIZE;

  //DROPDOWN OPTIONS
  const options = ["1 HOUR", "2 HOURS", "3 HOURS"];
  const skillsOptions = skillsData?.map((data) => data.title);
  const creditsOptions = [
    { max: 200, min: 100 },
    { max: 300, min: 200 },
    { max: 400, min: 300 },
    { max: 500, min: 400 },
  ];
  const ratingsOptions = [
    { max: 1, min: 0 },
    { max: 2, min: 1 },
    { max: 3, min: 2 },
    { max: 4, min: 3 },
    { max: 5, min: 4 },
  ];
  const availabilityOptions = ["Yes", "No"];
  const [paginationData, setPaginationData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  //console.log(paginationData, pageNumber);

  const handlePageClick = (page) => {
    setPageNumber(page);
  };

  const fetchAvailableCoaches = async () => {
    const apiUrl = `${window.env_url}/v1/coaches`;
    try {
      setIsLoading(true);
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching data ${response.status}`);
      }
      const data = await response.json();
      setAvailCoachData(data.body[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //TOGGLE
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleskillsOptions = () => {
    setShowSkillsOptions(!showSkillsOptions);
  };

  const toggleCreditsOptions = () => {
    setShowCreditsOptions(!showCreditsOptions);
  };

  const toggleRatingsOptions = () => {
    setShowRatingsOptions(!showRatingsOptions);
  };

  const toggleAvailOptions = () => {
    setShowAvailabilityOptions(!showAvailabilityOptions);
  };

  //SELECT OPTION
  const selectOption = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  const selectskillsOption = (option) => {
    setSelectedSkillsOption(option);
    setShowSkillsOptions(false);
  };

  const selectcreditsOption = (option) => {
    setSelectedCreditsOption(option);
    setShowCreditsOptions(false);
  };

  const selectRatingsOption = (option) => {
    setSelectedRatingsOption(option);
    setShowRatingsOptions(false);
  };

  const selectAvailOptions = (option) => {
    setSelectedAvailOptions(option);
    setShowAvailabilityOptions(false);
  };

  const [newFilterData, setNewFilterData] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    facilities: "",
    duration: "",
  });

  const convertToUnits = (duration) => {
    if (duration) {
      const { hours, minutes, seconds } = duration;
      const totalHours = hours + minutes / 60 + seconds / 3600;
      const totalMinutes = hours * 60 + minutes + seconds / 60;
      const units = (totalMinutes / 15) * 0.25;
      return units;
    }
  };
  const units = convertToUnits(newFilterData.duration);

  const startTime = moment(newFilterData.startTime, "HH:mm:ss");
  const startTimeMoment = moment(startTime, "HH:mm");
  const endTimeMoment = startTimeMoment.clone().add(units, "hours");
  let startDate = moment(newFilterData.start);
  let endDate = moment(newFilterData.end);
  const filterData = {
    checkInDate: moment(startDate).format("YYYY-MM-DD"),
    checkOutDate: moment(startDate).format("YYYY-MM-DD"),
    checkInTime: startTimeMoment.format("HH:mm:ss"),
    checkOutTime: endTimeMoment.format("HH:mm:ss"),
    duration: convertToUnits(durationDisplayedInText),
  };

  console.log(filterData);

  const calculateDuration = () => {
    if (newFilterData.startTime && newFilterData.endTime) {
      const start = moment(`2024-01-01T${newFilterData.startTime}`);
      const end = moment(`2024-01-01T${newFilterData.endTime}`);
      const durationMinutes = end.diff(start, "minutes");
      const durationUnits = durationMinutes / 15; // 15 minutes is 1 unit
      setNewFilterData({ ...newFilterData, duration: durationUnits });
    }
  };

  const filterCoaches = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${window.env_url}/v1/coachbookings/availability`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(filterData),
        }
      );
      toast.success("Coaches Filtered");
      console.log("filterres", response);
      const data = await response.json();
      console.log("filterdata", data);
      setAvailCoachData(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchAvailableCoaches();
    fetchSkills();
  }, []);

  console.log(availCoachData);

  let availCoachTabletView = groupArray(availCoachData, 4);

  const handleViewClick = (coachId) => {
    window.location.href = `/coach-details/${coachId}`;
  };

  let filteredData = availCoachData;

  if (selectedCreditsOption) {
    filteredData = filteredData.filter((item) => {
      if (
        item.credits >= selectedCreditsOption.min &&
        item.credits <= selectedCreditsOption.max
      ) {
        return true;
      }
      return false;
    });
  }

  if (selectedRatingsOption) {
    filteredData = filteredData.filter((item) => {
      if (
        item.avgratings >= selectedRatingsOption.min &&
        item.avgratings <= selectedRatingsOption.max
      ) {
        return true;
      }
      return false;
    });
  }

  if (selectedSkillsOption) {
    filteredData = filteredData.filter((item) => {
      if (item.skills?.includes(selectedSkillsOption)) {
        return true;
      }
      return false;
    });
  }

  return (
    <>
      {width <= 768 ? (
        <div className="mr-mv-root">
          <div className="coaches nav-filterSection">
            <MNavbar />
            <div className="mr-filter-container mr-Resp">
              <div className="mr-filter-mobileDiv">
                <div className="filter filterSection">
                  <div className="mr-filter-top mr-Resp">
                    <div className="mr-filter1 mr-Resp">
                      <input
                        type="date"
                        name="date"
                        id="filterByDate"
                        className="mv"
                        ref={inputRef}
                        // style={{ display: "none" }}
                        placeholder="Date"
                      />
                      <RespFilterCalendar id="filterCalendarSvg" />
                    </div>
                    <div className="mr-filter1 mr-Resp">
                      <input
                        type="time"
                        name="time"
                        id="filterByTime"
                        placeholder="Time"
                      />
                      <RespFilterClock id="filterClockSvg" />
                    </div>
                    <div className="mr-filter1 mr-Resp final">
                      <RespFilterTimer onClick={toggleOptions} />
                      <input
                        type="text"
                        value={selectedOption}
                        readOnly
                        id="filterByDuration"
                        onClick={toggleOptions}
                        placeholder="Duration"
                      />
                      {showOptions && (
                        <div className="dropdown-options filter">
                          {options.map((option, index) => (
                            <li
                              key={index}
                              onClick={() => selectOption(option)}
                            >
                              {option}
                            </li>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mr-filter-bottom mr-Resp">
                    <div className="sliderIcon container">
                      <RespFilterSlider onClick={openFilterDialog} />
                    </div>
                    {skills && (
                      <div className="mr-filter-skills mr-dropdown">
                        <input
                          type="text"
                          value={selectedSkillsOption}
                          readOnly
                          id="filterByskills"
                          onClick={toggleskillsOptions}
                          placeholder="skills"
                        />
                        {showSkillsOptions && (
                          <div className="dropdown-options cap-filter">
                            {skillsOptions.map((option, index) => (
                              <span
                                key={index}
                                onClick={() => selectskillsOption(option)}
                              >
                                {option}
                              </span>
                            ))}
                          </div>
                        )}
                        <RespFilterDropdown id="skillsFilter" />
                      </div>
                    )}
                    {credits && (
                      <div className="mr-filter-credits mr-dropdown">
                        <div className="ip-svg-section">
                          <input
                            type="text"
                            name="price"
                            readOnly
                            id="filterByPrice"
                            value={
                              selectedCreditsOption
                                ? `${selectedCreditsOption.min} - ${selectedCreditsOption.max}`
                                : ""
                            }
                            onClick={toggleCreditsOptions}
                            placeholder="Credits"
                          />
                          <Downarrow className="price-filter-svg" />
                        </div>
                        {showCreditsOptions && (
                          <div className="dropdown-options price-filter">
                            {creditsOptions.map((option, index) => (
                              <span
                                key={index}
                                onClick={() => {
                                  selectcreditsOption(option);
                                  setPageNumber(1);
                                }}
                              >
                                {option.min} - {option.max}
                                <br />
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                    {ratings && (
                      <div className="mr-filter-ratings mr-dropdown">
                        <div className="ip-svg-section">
                          <input
                            type="text"
                            name="rating"
                            readOnly
                            id="filterByPrice"
                            placeholder="Ratings"
                            value={
                              selectedRatingsOption
                                ? `${selectedRatingsOption.min} - ${selectedRatingsOption.max}`
                                : ""
                            }
                            onClick={toggleRatingsOptions}
                          />
                          <Downarrow className="ratings-filter-svg" />
                        </div>
                        {showRatingsOptions && (
                          <div className="dropdown-options price-filter">
                            {ratingsOptions.map((option, index) => (
                              <span
                                key={index}
                                onClick={() => selectRatingsOption(option)}
                              >
                                {option.min} - {option.max}
                                <br />
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="searchBarDiv" onClick={filterCoaches}>
                  <SearchIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="coach-card-container mv-coaches">
            {availCoachData
              ?.slice((pageNumber - 1) * 8, pageNumber * 8)
              .map((cardData, index) => {
                return (
                  <CoachCard
                    key={cardData?.id}
                    coachId={cardData?.id}
                    imgSrc={getFileUrl + cardData.files}
                    coachName={cardData.title}
                    description={truncateDescription(cardData.description, 90)}
                    onViewClick={() => handleViewClick(cardData.id)}
                  />
                );
              })}
          </div>
          <div className="paginationNumbers" style={{ marginBottom: "10px" }}>
            {Array(Math.ceil(filteredData?.length / 8))
              .fill("")
              .map((_, page) => (
                <button
                  key={page}
                  onClick={() => handlePageClick(page + 1)}
                  className={Number(page) === pageNumber ? "activePage" : ""}
                >
                  {page + 1}
                </button>
              ))}
          </div>
          {openFilters && <FilterWidget onClose={handleFiltersClick} />}
        </div>
      ) : width <= 1023 ? (
        <div className="meeetingrooms-root coachesRoot tablet">
          <Navbar />
          <div className="nav-home-rightsection">
            <Sidebar
            // homeActive={homeActive} meetingActive={meetingActive} coachesActive={coachesActive}
            />
            <div className="home-container">
              <div className="mr-right-section coaches-page home">
                <div className="mr filter-container">
                  <div className="mr-filter-div tablet">
                    <div className="mr-filter-top">
                      <div className="mr-filter1 meetingrooms">
                        <input
                          type="date"
                          name="date"
                          id="filterByDate"
                          ref={inputRef}
                          // style={{ display: "none" }}
                          placeholder={placeholder}
                          onFocus={() => setPlaceholder("")}
                          onBlur={() => setPlaceholder("Date")}
                        />
                        <Calendar onClick={handleIconClick} />
                      </div>
                      <div
                        className="mr-filter1 meetingrooms"
                        style={{ borderRight: "none", borderLeft: "none" }}
                      >
                        <input
                          type="time"
                          name="time"
                          id="filterByTime"
                          placeholder="Time"
                        />
                        <FilterWatch />
                      </div>
                      <div
                        className="mr-filter1 meetingrooms"
                        style={{ borderRight: "none" }}
                      >
                        <input
                          type="text"
                          value={
                            hasOpenedModal && durationDisplayedInText
                              ? ` ${hours} hr${
                                  hours !== 1 ? "s" : ""
                                }, ${minutes} min${minutes !== 1 ? "s" : ""}`
                              : "Duration"
                          }
                          readOnly
                          id="filterByDuration"
                          onClick={onOpenModal}
                          placeholder="Duration"
                        />
                        {showOptions && (
                          <div className="dropdown-options filter">
                            {options.map((option, index) => (
                              <li
                                key={index}
                                onClick={() => selectOption(option)}
                              >
                                {option}
                              </li>
                            ))}
                          </div>
                        )}
                        <Timer onClick={toggleOptions} />
                      </div>
                    </div>
                    <div className="mr-filter-bottom">
                      <div className="mr-filter-icon meetingrooms">
                        <Slider onClick={openFilterDialog} />
                      </div>
                      {credits && (
                        <div className="mr-filter-credits mr-dropdown">
                          <div className="ip-svg-section">
                            <input
                              type="text"
                              name="price"
                              readOnly
                              id="filterByPrice"
                              placeholder="Credits"
                              value={
                                selectedCreditsOption
                                  ? `${selectedCreditsOption.min} - ${selectedCreditsOption.max}`
                                  : ""
                              }
                              onClick={toggleCreditsOptions}
                            />
                            <Downarrow className="price-filter-svg" />
                          </div>
                          {showCreditsOptions && (
                            <div className="dropdown-options price-filter">
                              {creditsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => {
                                    selectcreditsOption(option);
                                    setPageNumber(1);
                                  }}
                                >
                                  {option.min} - {option.max}
                                  <br />
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      )}

                      {ratings && (
                        <div className="mr-filter-ratings mr-dropdown">
                          <div className="ip-svg-section">
                            <input
                              type="text"
                              name="rating"
                              readOnly
                              placeholder="Rating"
                              id="filterByPrice"
                              value={
                                selectedRatingsOption
                                  ? `${selectedRatingsOption.min} - ${selectedRatingsOption.max}`
                                  : ""
                              }
                              onClick={toggleRatingsOptions}
                            />
                            <Downarrow className="ratings-filter-svg" />
                          </div>
                          {showRatingsOptions && (
                            <div className="dropdown-options price-filter">
                              {ratingsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => selectRatingsOption(option)}
                                >
                                  {option.min} - {option.max}
                                  <br />
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      )}

                      {skills && (
                        <div className="mr-filter-skills mr-dropdown">
                          <input
                            type="text"
                            value={selectedSkillsOption}
                            readOnly
                            placeholder="Skills"
                            id="filterByskills"
                            onClick={toggleskillsOptions}
                          />

                          {showSkillsOptions && (
                            <div className="dropdown-options cap-filter">
                              {skillsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => selectskillsOption(option)}
                                >
                                  {option}
                                </span>
                              ))}
                            </div>
                          )}
                          <Downarrow />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mr-filter-btn" onClick={filterCoaches}>
                    Find Coaches
                  </div>
                </div>
                <div className="coach-allcoaches">
                  <div className="coaches coach-card-container">
                    <div id="tabletRoomContainer">
                      {filteredData
                        ?.slice((pageNumber - 1) * 8, pageNumber * 8)
                        .map((cardData, index) => (
                          <CoachCard
                            key={cardData?.id}
                            coachId={cardData?.id}
                            imgSrc={getFileUrl + cardData.files}
                            rating={cardData.avgratings}
                            coachName={truncateDescription(cardData.title, 15)}
                            description={truncateDescription(
                              cardData.description,
                              90
                            )}
                            onViewClick={() => handleViewClick(cardData.id)}
                          />
                        ))}
                    </div>
                    {/* <div className="paginationNumbers">
                        {Array(Math.ceil(filteredData?.length / 8))
                          .fill("")
                          .map((_, page) => (
                            <button
                              key={page}
                              onClick={() => handlePageClick(page + 1)}
                              className={
                                Number(page) === pageNumber ? "activePage" : ""
                              }
                            >
                              {page + 1}
                            </button>
                          ))}
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openFilters && <FilterWidget onClose={handleFiltersClick} />}
          <DurationModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onCloseModal={onCloseModal}
            initialDuration={durationDisplayedInText || undefined}
          />
        </div>
      ) : (
        <div className="coaches-root">
          <Navbar />
          <div className="mr-container">
            <Sidebar />
            <div className="mr-right-section coaches"></div>
            <div className="coach-right-section">
              <div className="mr filter-container">
                <div className="mr-filter-div">
                  <div className="topFilterContainer">
                    <div className="filterField">
                      <div className="input-with-icon">
                        <input
                          type="date"
                          name="date"
                          id="filterByDate"
                          ref={inputRef}
                          placeholder={placeholder}
                          onChange={(event) =>
                            setNewFilterData({
                              ...newFilterData,
                              start: event.target.value,
                            })
                          }
                          onFocus={() => setPlaceholder("")}
                          onBlur={() => setPlaceholder("Date")}
                        />
                        <div
                          className="icon-container"
                          onClick={handleIconClick}
                        >
                          <Calendar />
                        </div>
                      </div>
                    </div>
                    <div
                      className="filterField"
                      style={{ borderRight: "none", borderLeft: "none" }}
                    >
                      <div className="input-with-icon">
                        <input
                          type="time"
                          name="time"
                          id="filterByTime"
                          placeholder="Time"
                          onChange={(event) => {
                            setNewFilterData({
                              ...newFilterData,
                              startTime: event.target.value,
                            });
                            calculateDuration();
                          }}
                        />
                        <div
                          className="icon-container"
                          onClick={() => console.log("FilterWatch clicked")}
                        >
                          <FilterWatch />
                        </div>
                      </div>
                    </div>
                    <div
                      className="filterField"
                      style={{ borderRight: "none" }}
                    >
                      <div className="input-with-icon">
                        <input
                          type="text"
                          value={
                            hasOpenedModal && durationDisplayedInText
                              ? ` ${hours} hr${
                                  hours !== 1 ? "s" : ""
                                }, ${minutes} min${minutes !== 1 ? "s" : ""}`
                              : "Select a duration"
                          }
                          readOnly
                          id="filterByDuration"
                          onClick={onOpenModal}
                          placeholder="Duration"
                        />
                        <div className="icon-container" onClick={toggleOptions}>
                          <Timer />
                        </div>
                      </div>
                    </div>
                    {credits && (
                      <div className="filterField">
                        <div className="input-with-icon">
                          <input
                            type="text"
                            name="price"
                            readOnly
                            id="filterByPrice"
                            value={
                              selectedCreditsOption
                                ? `${selectedCreditsOption.min} - ${selectedCreditsOption.max}`
                                : ""
                            }
                            onClick={toggleCreditsOptions}
                            placeholder="Credits"
                          />
                          <div className="icon-container">
                            <Downarrow className="price-filter-svg" />
                          </div>
                          {showCreditsOptions && (
                            <div className="dropdown-options">
                              {creditsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => {
                                    selectcreditsOption(option);
                                    setPageNumber(1);
                                  }}
                                >
                                  {option.min} - {option.max}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {ratings && (
                      <div className="filterField">
                        <div className="input-with-icon">
                          <input
                            type="text"
                            name="rating"
                            readOnly
                            id="filterByRating"
                            value={
                              selectedRatingsOption
                                ? `${selectedRatingsOption.min} - ${selectedRatingsOption.max}`
                                : ""
                            }
                            onClick={toggleRatingsOptions}
                            placeholder="Ratings"
                          />
                          <div className="icon-container">
                            <Downarrow className="ratings-filter-svg" />
                          </div>
                          {showRatingsOptions && (
                            <div className="dropdown-options">
                              {ratingsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => selectRatingsOption(option)}
                                >
                                  {option.min} - {option.max}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {skills && (
                      <div className="filterField">
                        <div className="input-with-icon filterInputDiv">
                          <input
                            type="text"
                            value={selectedSkillsOption}
                            readOnly
                            id="filterBySkills"
                            onClick={toggleskillsOptions}
                            placeholder="Skills"
                          />
                          <div className="icon-container filterInputDiv">
                            <Downarrow />
                          </div>
                          {showSkillsOptions && (
                            <div className="dropdown-options">
                              {skillsOptions.map((option, index) => (
                                <span
                                  key={index}
                                  onClick={() => selectskillsOption(option)}
                                >
                                  {option}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="action-icon-container">
                  <div className="searchIcon" onClick={openFilterDialog}>
                    <TuneIcon sx={{ fill: "white" }} />
                  </div>
                  <div className="searchIcon" onClick={filterCoaches}>
                    <Search sx={{ fill: "white", fontSize: "2rem" }} />
                  </div>
                  <div className="searchIcon history">
                    <Button
                      sx={{
                        fontFamily: "Nunito",
                        color: "white",
                        textTransform: "Capitalize",
                      }}
                      onClick={
                        historyTable
                          ? () => setHistoryTable(false)
                          : () => setHistoryTable(true)
                      }
                    >
                      {historyTable ? "Coaches" : "History"}
                      <HistoryIcon />
                    </Button>
                  </div>
                </div>
              </div>
              {historyTable ? (
                <CoachBookingHistory />
              ) : (
                <div className="coach-allcoaches">
                  <div className="coach-available">
                    <div className="coaches coach-card-container">
                      {isLoading ? (
                        Array.from({ length: 8 }).map((_, index) => (
                          <ShimmerCard key={index} />
                        ))
                      ) : filteredData.length === 0 ? (
                        <p className="noData">
                          There are currently no coaches to display.
                        </p>
                      ) : (
                        <>
                          {filteredData
                            ?.slice(
                              (pageNumber - 1) * PAGE_SIZE,
                              pageNumber * PAGE_SIZE
                            )
                            .map((cardData, index) => (
                              <CoachCard
                                key={cardData?.id}
                                coachId={cardData?.id}
                                imgSrc={getFileUrl + cardData.files}
                                coachName={cardData.title}
                                description={truncateDescription(
                                  cardData.description,
                                  80
                                )}
                                onViewClick={() => handleViewClick(cardData.id)}
                              />
                              // <RoomCard
                              //   key={cardData?.id}
                              //   roomTitle={extractFirstValue(cardData.title, "|")}
                              //   imgSrc={
                              //     getFileUrl +
                              //     extractFirstValue(cardData?.files, "|")
                              //   }
                              //   roomId={cardData?.id}
                              //   totalStars={5}
                              //   description={truncateDescription(
                              //     cardData.description,
                              //     100
                              //   )}
                              //   onViewClick={() => handleViewClick(cardData.id)}
                              // />
                            ))}
                        </>
                      )}
                    </div>
                    <div className="paginationNumbers">
                      {Array(Math.ceil(filteredData.length / PAGE_SIZE))
                        .fill("")
                        .map((_, page) => (
                          <button
                            key={page}
                            onClick={() => handlePageClick(page + 1)}
                            className={
                              page + 1 === pageNumber ? "activePage" : ""
                            }
                          >
                            {page + 1}
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {openFilters && <FilterWidget onClose={handleFiltersClick} />}
          <DurationModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onCloseModal={onCloseModal}
            initialDuration={durationDisplayedInText || undefined}
          />
        </div>
      )}
    </>
  );
};

export default Coaches;
