import InputField from "../inputField";
import Underline from "../../../assets/png/Line.png";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { fetchAllData } from "../../../utils/fetchData";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "react-query";
import { ReactComponent as UserIcon } from "../../../assets/svg/UserIcon.svg";
import { ReactComponent as Mail } from "../../../assets/svg/Mail.svg";
import { ReactComponent as Phone } from "../../../assets/svg/Phone.svg";
import "./setupDetails.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function SetupDetailsSection({ formState, setFormState }) {
  let screenWidth = window.innerWidth;
  const [setupDetailsForm, setsetupDetailsForm] = useState({
    ...formState.setupDetails,
  });
  const [facilities, setFacilities] = useState([
    ...formState.roomData.facilities.split("|"),
  ]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [duration, setDuration] = useState([15, "15 minutes"]);
  const [trigger, setTrigger] = useState(true);
  console.log("formState", formState);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const makeDoubleDigit = (num) => {
    return num.toString().padStart(2, "0");
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFacilities(typeof value === "string" ? value.split(",") : value);
    setFormState((prevState) => {
      return {
        ...prevState,
        setupDetails: {
          ...prevState.setupDetails,
          facilities: value.join("|"),
        },
      };
    });
  };

  const handleOnTimeChange = (time) => {
    // const {value} = event.target;
    console.log(`${time["$H"]}:${time["$m"]}`);
    setFormState((prevState) => {
      return {
        ...prevState,
        setupDetails: {
          ...prevState.setupDetails,
          time: `${makeDoubleDigit(time["$H"])}:${makeDoubleDigit(time["$m"])}`,
        },
      };
    });
  };

  const handleDateChange = (date) => {
    console.log("date here", date);
    if (!date) {
      return;
    }
    console.log(date);
    setFormState((prevState) => {
      return {
        ...prevState,
        setupDetails: {
          ...prevState.setupDetails,
          date: `${date["$y"]}-${makeDoubleDigit(
            date["$M"] + 1 > 12 ? 1 : date["$M"] + 1
          )}-${makeDoubleDigit(date["$D"])}`,
        },
      };
    });
    console.log(date);
  };
  console.log("facilities", facilities);
  //date["$D"]}/${date["$M"].toString().padStart(2, '0')}/${date["$y"]}
  //${date["$y"]}-${date["$M"].toString().padStart(2, '0')}-date["$D"]}

  const buttonsForDuration = () => {
    return (
      <div>
        <Stack direction="column" spacing={-2}>
          <IconButton aria-label="up" onClick={durationIncrement}>
            <ArrowDropUpIcon sx={{ transform: "scale(1.8)" }} />
          </IconButton>
          <IconButton aria-label="down" onClick={durationDecrement}>
            <ArrowDropDownIcon sx={{ transform: "scale(1.8)" }} />
          </IconButton>
        </Stack>
      </div>
    );
  };

  function durationIncrement(event) {
    event.preventDefault();
    if (duration[0] + 15 > 1440) {
      toast.error("cannot book beyond 24 hours");
      return;
    }

    if (duration[0] > 59) {
      if (!((duration[0] + 15) / 60).toString().includes(".")) {
        setDuration((prevState) => [
          prevState[0] + 15,
          `${(prevState[0] + 15) / 60} hours`,
        ]);
      } else {
        const [hours, minutes] = ((duration[0] + 15) / 60)
          .toString()
          .split(".");
        // const [hour, minutes] = hours.toString().split(".");

        const minArray = { 25: 15, 5: 30, 75: 45 };
        setDuration((prevState) => [
          prevState[0] + 15,
          `${hours} hours ${minArray[minutes]} minutes`,
        ]);
      }
    } else {
      if (duration[0] + 15 > 59) {
        setDuration((prevState) => [prevState[0] + 15, `1 hour`]);
      } else {
        setDuration((prevState) => [
          prevState[0] + 15,
          `${prevState[0] + 15} minutes`,
        ]);
      }
    }
  }

  function durationDecrement(event) {
    event.preventDefault();
    if (duration[0] - 15 < 15) {
      toast.error("cannot book below 15 minutes");
      return;
    }

    if (duration[0] > 59) {
      console.log((duration[0] / 60).toString().includes("."));
      if (!((duration[0] - 15) / 60).toString().includes(".")) {
        setDuration((prevState) => [
          prevState[0] - 15,
          `${(prevState[0] - 15) / 60} hours`,
        ]);
      } else {
        const [hours, minutes] = ((duration[0] - 15) / 60)
          .toString()
          .split(".");
        // const [hour, minutes] = hours.toString().split(".");
        console.log(((duration[0] - 15) / 60).toString().split("."));
        const minArray = { 25: 15, 5: 30, 75: 45 };
        setDuration((prevState) => [
          prevState[0] - 15,
          hours == 0
            ? `${minArray[minutes]} minutes`
            : hours + " hours " + `${minArray[minutes]} minutes`,
        ]);
      }
    } else {
      if (duration[0] - 15 > 59) {
        console.log("it comes in here");
        setDuration((prevState) => [prevState[0] - 15, `1 hour`]);
      } else {
        setDuration((prevState) => [
          prevState[0] - 15,
          `${prevState[0] - 15} minutes`,
        ]);
      }
    }
  }

  useEffect(() => {
    if (formState.setupDetails.time) {
      const [hours, minutes] = formState.setupDetails.time.split(":");
      const [dHours, dMinutes] =
        duration[1].split(" ").length === 2
          ? [0, duration[1].split(" ")]
          : [duration[1].split(" ")[0], duration[1].split(" ")[2]];
      const [combinedHours, combinedMinutes] = [
        Number(hours) + Number(dHours),
        Number(minutes) + Number(dMinutes),
      ];
      console.log("combinedHours", duration, combinedHours);
      if (combinedMinutes) {
        const [extraHours, extraMinutes] = [
          Math.floor(combinedHours / 60),
          combinedMinutes % 60,
        ];
        console.log("extraHours", extraHours, extraMinutes);

        if (combinedHours > 23) {
          setFormState({
            ...formState,
            setupDetails: {
              ...formState.setupDetails,
              checkoutTime: `${makeDoubleDigit(
                combinedHours - 24 + Number(extraHours)
              )}:${makeDoubleDigit(extraMinutes)}:00`,
              duration: duration[1],
              combinedDuration: duration[0],
            },
          });
        } else {
          setFormState({
            ...formState,
            setupDetails: {
              ...formState.setupDetails,
              checkoutTime: `${makeDoubleDigit(
                combinedHours
              )}:${makeDoubleDigit(extraMinutes)}:00`,
              duration: duration[1],
              combinedDuration: duration[0],
            },
          });
        }
      } else {
        const [extraHours, extraMinutes] = [
          Math.floor(combinedHours / 60),
          (Number(dMinutes[0]) + Number(minutes)) % 60,
        ];
        console.log("combined minutes", Number(dMinutes[0]), Number(minutes));
        console.log("dHours == 0", dHours);

        if (dHours == 0) {
          console.log("it comes here 1");
          setFormState({
            ...formState,
            setupDetails: {
              ...formState.setupDetails,
              checkoutTime: `${makeDoubleDigit(
                combinedHours + Number(extraHours)
              )}:${makeDoubleDigit(extraMinutes)}:00`,
              duration: duration[1],
              combinedDuration: duration[0],
            },
          });
          return;
        }

        if (combinedHours > 23) {
          setFormState({
            ...formState,
            setupDetails: {
              ...formState.setupDetails,
              checkoutTime: `${makeDoubleDigit(
                combinedHours - 24 + Number(extraHours)
              )}:${makeDoubleDigit(minutes)}:00`,
              duration: duration[1],
              combinedDuration: duration[0],
            },
          });
        } else {
          setFormState({
            ...formState,
            setupDetails: {
              ...formState.setupDetails,
              checkoutTime: `${makeDoubleDigit(
                combinedHours + Number(extraHours)
              )}:${makeDoubleDigit(minutes)}:00`,
              duration: duration[1],
              combinedDuration: duration[0],
            },
          });
        }
      }
    }

    console.log("duration", duration);
  }, [duration, trigger]);

  const content = (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="basicDeatilsForm setupDetailsForm">
          <div className="datePicker">
            <label className="setupLabel">Date</label>
            <DatePicker
              sx={{
                width: "100%",
                margin: "0px",
                height: "10%",
                ".MuiInputLabel-root": {
                  textOverflow: "clip",
                  width: "100%",
                },
              }}
              onChange={handleDateChange}
              label={formState.setupDetails.date ? "" : "Date"}
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>

          <div className="timePicker">
            <label className="setupLabel">Time</label>
            <TimePicker
              ampm={false}
              onChange={handleOnTimeChange}
              sx={{
                width: "100%",
                margin: "0px",
                ".MuiInputLabel-root": { textOverflow: "clip", width: "100%" },
              }}
              label={formState.setupDetails.time ? "" : "Basic time picker"}
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>

          <div className="durationField">
            <label className="setupLabel">Duration</label>
            <TextField
              id="outlined-basic"
              name="duration"
              variant="outlined"
              value={formState.setupDetails.duration}
              sx={{ width: "100%", margin: "0px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {buttonsForDuration()}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="facilitiesPicker">
            <label className="setupLabel">Facilities</label>
            <FormControl
              sx={{
                m: 0,
                width: "100%",
                marginRight: 0,
                ".MuiInputLabel-root": { textOverflow: "clip", width: "100%" },
              }}
            >
              <InputLabel>Facilities</InputLabel>
              <Select
                id="demo-multiple-name"
                multiple
                value={selectedFacilities}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    label={selectedFacilities ? "" : "Facilities"}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    style={{ color: "black" }}
                  />
                }
                MenuProps={MenuProps}
              >
                <MenuItem value="" disabled>
                  Facilities
                </MenuItem>
                {facilities.map((facility) => (
                  <MenuItem key={facility} value={facility}>
                    {facility}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </LocalizationProvider>
      <div className="descriptionField">
        <InputField
          fieldLabel={"Description"}
          name={"notes"}
          isMultiline={true}
          rows={7}
          style={{ width: "96%" }}
          formState={formState}
          setFormState={setFormState}
        />
      </div>
    </>
  );

  return screenWidth < 900 ? (
    <div className="tablet-setupContent">{content}</div>
  ) : (
    content
  );
}
