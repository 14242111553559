import React from "react";
import { ReactComponent as SurveyIcon } from "../../assets/svg/Survey.svg";
import "./SurveyForm.css";
import axios from "axios";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import SurveyCard from "./surveycard/SurveyCard";
import { useState } from "react";
import { useEffect } from "react";

const SurveyForm = () => {
  const [surveyForms, setSurveyForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSurveyForms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/form`,
        {
          headers: {
            "Content-Type": "application/json",
            // 'Authorization': `Bearer ${token}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const forms = response.data.body[0]; // Assuming the data structure based on your provided JSON
      setSurveyForms(forms);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveyForms();
  }, []);

  return (
    <div className="survey-form-container">
      <Navbar />
      <div className="content-wrapper">
        <Sidebar />
        <div className="survey-list-container">
          <h2>Your Survey Forms</h2>
          {surveyForms.length > 0 ? (
            surveyForms.map((form) => (
              <SurveyCard
                key={form.id}
                formName={form.formName}
                createdAt={form.createdAt}
                inputs={form.formLayoutComponents}
              />
            ))
          ) : (
            <p>No survey forms available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SurveyForm;
