import { useEffect, useState } from "react";
import ReviewCard from "./review-card/ReviewCard";
import "./ReviewSection.css";
import EditReviewDialog from "../EditReviewDialog";
import NullReview from "../../assets/png/NullReview.png";
import { toast } from "react-toastify";

const CoachReviewSection = ({ onClose, activeForm }) => {
  const [reviews, setReviews] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const getFileUrl = `${window.env_url}/v1/files/`;

  useEffect(() => {
    fetchMyReviews();
  }, [updateTrigger]);

  const fetchMyReviews = async () => {
    const apiUrl = `${window.env_url}/v1/coachreviews/myreviews`;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
      }
      const data = await response.json();
      const coachesResponse = await fetch(`${window.env_url}/v1/coaches`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const coachesData = await coachesResponse.json();


      for(let i=0; i<coachesData.body[0].length; i++) {
        const coachId = coachesData.body[0][i].id;
        const coachName = coachesData.body[0][i].title;
        for(let k=0; k<data.body[0].length;k++){
          if(data.body[0][k].coachId === coachId){
            data.body[0][k].name = coachName;
          }
        }
      }
      console.log("coachesData",data.body[0])
      const sortedReviews = data.body[0].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setReviews(sortedReviews);
      //console.log(sortedReviews);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleReviewUpdated = () => {
    setUpdateTrigger((prevTrigger) => prevTrigger + 1);
  };

  const handleEdit = (review) => {
    setSelectedReview(review);
    setOpenEditDialog(true);
    //console.log("Edit clicked");
  };

  const handleDelete = async (review) => {
    const apiUrl = `${window.env_url}/v1/coachreviews/${review}`;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();

        toast.success(`Review has been ${data.body}`);
        setUpdateTrigger((prevTrigger) => prevTrigger + 1);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      {reviews.length > 0 ? (
        <div className="rev-root-container">
          {reviews.map((review, index) => {
            const { user } = review;
  
            return (
              <ReviewCard
                key={index}
                reviewerName={user?.firstname}
                reviewerDate={review.createdAt}
                reviewerImage={getFileUrl + user?.image}
                rating={review.rating}
                reviewText={review.description}
                onEdit={() => handleEdit(review)}
                onDelete={() => handleDelete(review.id)} // Pass review.id to handleDelete
                onClick={() => {}}
                name={review.name}
                type={'Coach'}
              />
            );
          })}
        </div>
      ) : (
        <div className="nullReviewContainer">
          <img src={NullReview} id="nullReviewImg" alt="Null Review" />
          <p className="nullReviewMsg">You haven't reviewed anything yet!</p>
        </div>
      )}
  
      {openEditDialog && (
        <EditReviewDialog
          open={openEditDialog}
          close={() => setOpenEditDialog(false)}
          review={selectedReview}
          onReviewUpdated={handleReviewUpdated}
          activeForm={activeForm}
        />
      )}
    </>
  );
};

export default CoachReviewSection;
