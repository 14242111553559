import React, { useState } from "react";
import { createPortal } from "react-dom";
import "./NotificationWidget.css";
import BellIcon from "../assets/svg/bell.svg";
import NotificationBell from "../assets/svg/notification.svg";
import { ReactComponent as NotificationDots } from "../assets/svg/NotificationDots.svg";
import { ReactComponent as NotificationWatch } from "../assets/svg/NotificationWatch.svg";
import { ReactComponent as NavigateBack } from "../../src/assets/svg/BackArrow.svg";

import { db } from "./firebase";
import { collection, getDocs, doc } from "firebase/firestore";
import { useEffect } from "react";
import { useQuery } from "react-query";
import notificationProfile from "../../src/assets/png/notificationProfile.png";
import { toast } from "react-toastify";

function CoachNotificationWidget({coachId}) {
  const [width, setWidth] = useState(window.innerWidth);
  const [notification, setNotification] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [notificationNumber, setNotificationNumber] = useState(0);
  const notificationData = collection(db, "coachuserreq");
  const [filteredNotifications,setfilteredNotifications] = useState([])

  console.log("Emtered coach notifications")
  useEffect(() => {
    fetchNotificationData();
    const handleClickOutside = (event) => {
      if (!event.target.closest(".bellIconWrapper")) {
        setIsClicked(false);
      }
    };
    if (isClicked) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isClicked]);

  useEffect(() => {

  }, [])

  const fetchNotificationData = async () => {
    try {
      const data = await getDocs(notificationData);
      console.log("Data", data.docs)
      const notificationsData = data.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => {
          const timeA = getNotificationTime(a.createdAt);
          const timeB = getNotificationTime(b.createdAt);
          return timeA.localeCompare(timeB);
        });
      console.log("notificationsData", notificationsData);
      setNotification(notificationsData);
      setNotificationNumber(notificationsData.length);
    } catch {}
  };

  const userId = sessionStorage.getItem("userId");
  const idToFilter = Number(userId);


    useEffect(()=>{
      const filteredNotifications =  notification?.filter(
        (item) => item.id == coachId
      );
      setfilteredNotifications(filteredNotifications)

    },[notification])
  

  console.log("notif", notification);
  console.log("filteredNotif", filteredNotifications);

  const handleNotificationBar = async () => {
    if (filteredNotifications.length) {
      setIsClicked(!isClicked);
      return;
    } else {
      toast.warn("You have no new notifications!");
    }
  };

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const navigateBack = () => {
    setIsClicked(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const getNotificationTime = (createdAt) => {
    const date = new Date(createdAt);
    const currentTime = new Date();
    const timeDifference = currentTime - date;
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    if (hoursDifference < 24) {
      return `${hoursDifference}hrs`;
    } else {
      const daysDifference = Math.floor(hoursDifference / 24);
      return `${daysDifference}d`;
    }
  };

  const NotificationPortal = () => {
    return createPortal(
      isClicked && (
        <div className="notificationTextWrapper show">
          <div className="notificationBar">
            <h3>Notifications</h3>
          </div>
          <div className="notifi-container">
            {filteredNotifications
              .slice() // Create a copy of the array to avoid mutating the original
              .sort((a, b) => b.createdAt - a.createdAt) // Sort by date in descending order
              .map((note) => (
                <div className="notificationClass">
                  <div className="notificationProfileImg">
                    <img
                      src={note.profileImage}
                      alt=""
                      className="notification-img"
                    />
                  </div>
                  <div className="notificationDetails showDrawer">
                    <div className="usernameDiv">
                      <h2>{note.username}</h2>
                    </div>
                    <div className="messageDiv">
                      <div>
                        <p className="message-para">{note.message}</p>
                        {note?.reason ? (
                          <span className="reason">Reason: {note?.reason}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <p>{getNotificationTime(note.createdAt)}</p>
                    </div>
                    <div className="notificationTimeClass">
                      <div className="notifWatch">
                        <NotificationWatch id="watch-svg" />
                      </div>
                      <p>{formatDate(note.createdAt)} </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ),
      document.body
    );
  };

  let svg;
  let notifClassName =
    filteredNotifications.length > 0 ? "reqclass" : "nonreqclass";
  svg = filteredNotifications.length > 0 ? NotificationBell : BellIcon;

  return (
    <div className="notif-container">
      <div className="bellIconWrapperWrapper">
        <div className="bellIconWrapper" onClick={handleNotificationBar}>
          <div className="notification-icon-wrapper">
            <img
              src={svg}
              alt=""
              style={{
                width: filteredNotifications.length > 0 ? "2.5rem" : "initial",
              }}
              id="notifImg"
              className={notifClassName}
            />
          </div>
          {filteredNotifications.length > 0 && (
            <div className="notificationNumberDiv">
              <span
                style={{
                  color: "#66a6a6",
                  fontFamily: "Nunito",
                  fontWeight: "bold",
                }}
              >
                {filteredNotifications.length}
              </span>
            </div>
          )}
        </div>
      </div>
      <NotificationPortal />
    </div>
  );
}

export default CoachNotificationWidget;
