import "./RatingWidget.css";
import Fiveby5 from "../assets/png/rating5.png";
import Fiveby4 from "../assets/png/rating4.png";
import Fiveby3 from "../assets/png/rating3.png";
import Fiveby2 from "../assets/png/rating2.png";
import Fiveby1 from "../assets/png/rating1.png";
import Stepper1 from "../assets/png/Stepper1.png";
import Stepper2 from "../assets/png/Stepper2.png";
import Stepper3 from "../assets/png/Stepper3.png";
import Stepper4 from "../assets/png/Stepper4.png";
import Stepper5 from "../assets/png/Stepper5.png";
import { useState } from "react";

const RatingWidget = ({ ratingsArray }) => {
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  //console.log("Rating FI", ratingsArray);

  return (
    <>
      {width <= 768 ? (
        <>
          <div className="mv review content">
            <div className="scales">
              <p className="awesome">Awesome</p>
              <p className="great">Great</p>
              <p className="good">good</p>
              <p className="okay">Okay</p>
              <p className="not-bad">Not Bad</p>
            </div>
            <div className="stepperImages">
              <img id="stepperImg" src={Stepper5} />
              <img id="stepperImg" src={Stepper4} />
              <img id="stepperImg" src={Stepper3} />
              <img id="stepperImg" src={Stepper2} />
              <img id="stepperImg" src={Stepper1} />
            </div>
            <div className="numbers">
              <p className="awesomeCount">{ratingsArray[4]}</p>
              <p className="greatCount">{ratingsArray[3]}</p>
              <p className="goodCount">{ratingsArray[2]}</p>
              <p className="okayCount">{ratingsArray[1]}</p>
              <p className="notBadCount">{ratingsArray[0]}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="review-content">
            <div className="stepperManager">
              <div className="rating-single-container">
                <div className="ratingDigit five">
                  <div className="awesome">Awesome 5</div>
                </div>
                <div className="rating-img">
                  <img src={Fiveby5} />
                </div>
                <div className="rating-figure">
                  <div className="rating">{ratingsArray[4]}</div>
                </div>
              </div>
              <div className="rating-single-container">
                <div className="ratingDigit four">
                  <div className="great">Great 4</div>
                </div>
                <div className="rating-img">
                  <img src={Fiveby4} />
                </div>
                <div className="rating-figure">
                  <div className="rating">{ratingsArray[3]}</div>
                </div>
              </div>
              <div className="rating-single-container">
                <div className="ratingDigit three">
                  <div className="good good3">Good 3</div>
                </div>
                <div className="rating-img">
                  <img src={Fiveby3} id="threeStar" />
                </div>
                <div className="rating-figure">
                  <div className="rating">{ratingsArray[2]}</div>
                </div>
              </div>
              <div className="rating-single-container">
                <div className="ratingDigit two">
                  <div className="okay">Okay 2</div>
                </div>
                <div className="rating-img">
                  <img src={Fiveby2} />
                </div>
                <div className="rating-figure">
                  <div className="rating">{ratingsArray[1]}</div>
                </div>
              </div>
              <div className="rating-single-container">
                <div className="rating ratingDigit one">
                  <div className="not-bad">Not bad 1</div>
                </div>
                <div className="rating-img">
                  <img src={Fiveby1} />
                </div>
                <div className="rating-figure">
                  <div className="rating">{ratingsArray[0]}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RatingWidget;
