// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  // apiKey: "AIzaSyCzL1MYzlKh8Gaf9VKd3tA_fl_owGbLTJU",
  // authDomain: "spark-inno-user-ui.firebaseapp.com",
  // projectId: "spark-inno-user-ui",
  // storageBucket: "spark-inno-user-ui.appspot.com",
  // messagingSenderId: "507540514760",
  // appId: "1:507540514760:web:29e955238a124c144f82fd"
  
  apiKey: "AIzaSyBrC5_SIB3DISs8jrs_5yVnuqDggunoZN0",
  authDomain: "incuhub-fd693.firebaseapp.com",
  projectId: "incuhub-fd693",
  storageBucket: "incuhub-fd693.appspot.com",
  messagingSenderId: "590557081765",
  appId: "1:590557081765:web:bf1d3dcec7cf39bcef5d4c",
  measurementId: "G-L0X6Y2R05W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)