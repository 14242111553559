import  "./ShimmerCard.css"

const ShimmerCard = ({className}) => {
    return (
      <div className={`shimmer-card ${className}`}>
        <div className="shimmer-img shimmer"></div>
        <div className="shimmer-content">
          <div className="shimmer-title shimmer"></div>
          <div className="shimmer-description shimmer"></div>
          <div className="shimmer-description shimmer"></div>
          <div className="shimmer-description shimmer"></div>
        </div>
      </div>
    );
  };

  
export default ShimmerCard;