import { useEffect, useState } from "react";
import "./MakeBookingDialog.css";
import moment from "moment";
import MultiSelect from "../../components/MultiSelect";
import MultiSelectDropdown from "../../components/MultiSelect";
import { db } from "../../widgets/firebase";
import { addDoc, collection } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { token } from "../../utils/utilityFunctions";
import { toast } from "react-toastify";

const MakeCoachBookingDialog = ({ closeBookMenu }) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const updateSkills = (e) => {
    // setSelectedSkills(e.value);
    const {
      target: { value },
    } = e;
    setSelectedSkills(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ref = collection(db, "userrequest");

  const { id } = useParams();

  const fetchSkills = async () => {
    const apiUrl = `${window.env_url}/v1/filterskills`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setSkillsData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  const skills = skillsData.map((data, index) => {
    return {
      name: data.title,
      code: data.id,
    };
  });

  const [events, setEvents] = useState([
    {
      title: "Event 1",
      start: new Date(),
      end: new Date(2024, 2, 1),
    },
  ]);

  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    skills: "",
    duration: "",
    notes: "",
  });
  const [eventss, setEventss] = useState(null);

  const [allEvents, setAllEvents] = useState(events);

  const getAllEvents = async () => {
    try {
      const response = await fetch(`${window.env_url}/v1/coachbookings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setEventss(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  const formattedEvents = eventss?.map((event) => {
    const startDateTime = moment(
      `${event.checkInDate} ${event.checkInTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    const endDateTime = moment(
      `${event.checkOutDate} ${event.checkOutTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );

    return {
      id: event.id,
      title: "Event", // You can customize this if you have specific titles
      start: startDateTime.toDate(), // Format the start datetime
      end: endDateTime.toDate(), // Format the end datetime
    };
  });

  useEffect(() => {
    fetchSkills();
    getAllEvents();
  }, []);

  const getFileUrl = `${window.env_url}/v1/files/`;

  const profileImage = sessionStorage.getItem("profPicture");
  let imgPath;
  if (profileImage?.includes("avataaars.io")) {
    imgPath = profileImage;
  } else {
    imgPath = getFileUrl + profileImage;
  }

  const handleAddEvent = () => {
    //console.log(newEvent);
    const startTime = moment(newEvent.startTime, "HH:mm:ss");
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = startTimeMoment
      .clone()
      .add(newEvent.duration, "hours");

    let startDate = moment(newEvent.start);
    let endDate = moment(newEvent.end);
    const roomEvent = {
      // title: "Rando0m",
      checkInDate: moment(startDate).format("YYYY-MM-DD"),
      checkOutDate: moment(startDate).format("YYYY-MM-DD"),
      checkInTime: startTimeMoment.format("HH:mm:ss"),
      checkOutTime: endTimeMoment.format("HH:mm:ss"),
      skills: selectedSkills.map((item, i) => item),
      duration: newEvent.duration,
      notes: newEvent.notes,
    };
    const event = roomEvent;
    //console.log(event);

    setAllEvents([...allEvents, event]);
    const postRoomBooking = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await fetch(
          `${window.env_url}/v1/coachbookings/book/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(event),
          }
        );
        const data = await response.json();
        if (response.ok) {
          toast.success("Coach Booking request made");
          closeBookMenu();
        } else {
          toast.error("Coach Booking failed !");
        }
        let dataToSend = {
          username: sessionStorage.getItem("fullName"),
          message: "Booked a Coach",
          duration: data.body[0].duration,
          profileImage: imgPath,
        };
        getAllEvents();
        console.log(dataToSend);
        try {
          await addDoc(ref, dataToSend);
          console.log("Data sent to Firebase successfully", dataToSend);
        } catch (firebaseError) {
          console.log("Error adding data to Firebase:", firebaseError);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    postRoomBooking();
  };
  return (
    <>
      {/* <div className="bookFormWrapper">
        <div className="bmrf">
          <div className="bmrf-heading">
            <p>MAKE A BOOKING</p>
          </div>
          <div className="bmrf-form">
            <div className="row1">
              <input
                type="date"
                name="startDate"
                onChange={(event) => setNewEvent({ ...newEvent, start: event.target.value })}
              />
            </div>
            <div className="row2">
              <input
                type="time"
                name="startTime"
                onChange={(event) => setNewEvent({ ...newEvent, startTime: event.target.value })}
              />
            </div>
            <div className="row3">
              <input
                type="number"
                name="duration"
                value={newEvent.duration}
                placeholder="duration"
                onChange={(event) => setNewEvent({ ...newEvent, duration: event.target.value })}
              />
            </div>
            <div className="row4">
              <MultiSelectDropdown
                value={selectedSkills}
                onChangeEvent={updateSkills}
                options={skills}
                placeholder="Select skills"
              />
            </div>
            <div className="row5">
              <textarea
                type="text"
                name="notes"
                value={newEvent.notes}
                placeholder="Description"
                onChange={(event) => setNewEvent({ ...newEvent, notes: event.target.value })}
              />
            </div>
            <div className="form-sbt-btn" onClick={handleAddEvent}>
              <button>Submit</button>
            </div>
          </div>
        </div>
      </div> */}
      <div className="bookFormWrapper">
        <div className="bmrf">
          <div className="bmrf-heading">
            <p>MAKE A BOOKING</p>
          </div>
          <div className="bmrf-form">
            <div className="bmrfFormFields">
              <div className="row1">
                <div>
                  <label className="makebold" htmlFor="startDate">
                    Date
                  </label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, start: event.target.value })
                    }
                  />
                </div>
              </div>         
              <div className="row2">
                <div>
                  <label className="makebold" htmlFor="startTime">
                    Time
                  </label>
                  <input
                    type="time"
                    id="startTime"
                    name="startTime"
                    onChange={(event) =>
                      setNewEvent({
                        ...newEvent,
                        startTime: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row3" style={{ border: "none" }}>
                <div>
                  <label className="makebold" htmlFor="duration">
                    Duration
                  </label>
                  <input
                    type="number"
                    id="duration"
                    name="duration"
                    placeholder="Duration"
                    value={newEvent.duration}
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, duration: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row4">
                <MultiSelectDropdown
                  value={selectedSkills}
                  onChangeEvent={updateSkills}
                  options={skills}
                  placeholder="Select Skills"
                />
              </div>
              <div className="row5">
                <div style={{ display: "block" }}>
                  <label className="makebold" htmlFor="notes">
                    Description
                  </label>
                  <textarea
                    id="notes"
                    name="notes"
                    className="mcb-textarea"
                    value={newEvent.notes}
                    placeholder="Description"
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, notes: event.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-sbt-btn">
              <button onClick={handleAddEvent}>Submit</button>
              <button onClick={closeBookMenu}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeCoachBookingDialog;
