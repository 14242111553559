import React, { useEffect, useState } from "react";
import styles from "./CoachAvailability.module.css";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, DialogContent, TextField } from "@mui/material";
import axios from "axios";
import { _userId, token } from "../../../utils/utilityFunctions";
import { toast } from "react-toastify";
import CustomCloseIcon from "../../../assets/svg/CustomCloseIcon";

const CoachAvailability = ({ coachId, close }) => {
  const [avData, setAvData] = useState({
    startDateTime: null,
    endDateTime: null,
    reason: "",
  });

  const handleChange = (field, value) => {
    setAvData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  console.log("AvDta", avData);

  const addAvailability = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/coaches/unavailability/${coachId}`,
        avData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Unavailability has been set!");
        close();
      } else {
        toast.error("Error setting unavailability");
      }
      console.log(response);
    } catch (error) {
      console.error(error?.message);
    }
  };

  return (
    <>
      <div className={styles.profileFormWrapper}>
        <section className={styles.avContainer}>
        <h2 className={styles.updateUserHeading}>Set Unavailability</h2>
        <CustomCloseIcon fill={"white"} onClick={close} />
        <DialogContent>
          <div className={styles.fields}>
            <div className={styles.dateTimeInputs}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={styles.inputField}>
                  <label>Start Date Time</label>
                  <DateTimePicker
                    value={avData?.startDateTime}
                    onChange={(newValue) =>
                      handleChange("startDateTime", newValue)
                    }
                    InputLabelProps={{
                      shrink: false,
                    }}
                    InputProps={{
                      style: {
                        paddingTop: "16px", // Adds space at the top for the text
                      },
                    }}
                    sx={{
                      width: "100%",
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
                <div className={styles.inputFields}>
                  <label>End Date Time</label>
                  <DateTimePicker
                    sx={{
                      width: "100%",
                    }}
                    value={avData?.endDateTime}
                    onChange={(newValue) =>
                      handleChange("endDateTime", newValue)
                    }
                    InputLabelProps={{
                      shrink: false,
                    }}
                    InputProps={{
                      style: {
                        paddingTop: "16px", // Adds space at the top for the text
                      },
                    }}
                    // onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <div className="inputField">
              <label>Reason (Optional!)</label>
              <TextField
                multiline
                placeholder="Enter the reason for unavailability"
                value={avData.reason}
                onChange={(e) =>
                  setAvData({ ...avData, reason: e.target.value })
                }
                fullWidth
                InputLabelProps={{
                  shrink: false, // Keep the label above the input when it's not focused
                }}
                InputProps={{
                  style: {
                    paddingTop: "16px", // Adjust padding inside the field
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    minHeight: "150px", // Set the height of the text area
                    paddingTop: "24px", // Add padding to prevent overlap
                  },
                  "& .MuiFormLabel-root": {
                    position: "absolute",
                    top: "-10px", // Adjust label position
                  },
                  textTransform: "uppercase",
                }}
              />
            </div>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                mb: 2,
                backgroundColor: "#66a3a6",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#87bad3",
                },
                fontWeight: "bold",
                padding: "10px 0",
                borderRadius: "8px",
                width: "24%",
                mx: "auto",
                display: "block",
                border: "none",
                fontFamily: "Nunito",
                gap: 2,
              }}
              onClick={addAvailability}
            >
              Confirm
            </Button>
          </div>
        </DialogContent>
        </section>
      </div>
    </>
  );
};

export default CoachAvailability;
